import React, { Component } from 'react';
import { Col, Badge, ListGroupItem } from 'reactstrap';
import identities from '../solutions/Identities';

class StorySummaryCard extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.title !== this.props.title; 
  }

  constructor(props) {
    super(props);

    this.state = { labels : [] };
    this.topicLabels = [];

    this.topicLabels = identities.getFilterLabelsForTopics(this.props.topics);
  }

  componentDidMount() {
    this.setState({ labels : this.topicLabels });
  }

  render() {
    const topicClasses = this.props.topics.reduce((acc, t) => acc + ' topic-' + t, 'story__summary_card');
    return (
      <ListGroupItem className={topicClasses} {...this.props}>
        {/*<img className="story__icon" src="/icons/home/icon_covid_wt.png" alt="COVID-19" />*/}
        <Col xs="12" className="search__list_item_title_container">
          <h2 className="search__list_item_title">{this.props.title}</h2>
        </Col>
        <div className="story__summary_badges">
          {this.topicLabels && this.topicLabels.map((badgeitem, index) => <Badge className="topicBadge" key={index} pill>{badgeitem}</Badge>)}
        </div>
      </ListGroupItem>
    );
  }
}

export default StorySummaryCard;

