import React, { Component } from 'react';
import HeroListContainer from './containers/HeroListContainer';
import CustomResultsList from './containers/CustomResultsList';
import AllOutcomes from './containers/AllOutcomes';
import './CustomResultsHub.css';

class CustomResultsHUB extends Component {

  render() {
    return <React.Fragment>
      <HeroListContainer {...this.props}
        contentCssClass="short__background_list custom_results__top"
        title={this.props.title} 
        subtitle={this.props.subtitle} 
        currentsection="outcomes">
        <CustomResultsList />
      </HeroListContainer>
      
      <div id="CustomResultsAllOutcomes">
        <h1>Explore More Outcomes</h1>
        <AllOutcomes {...this.props} />
      </div>
      
    </React.Fragment>
  }
}

export default CustomResultsHUB;