import React, { Component } from 'react';


class OutcomesIcon extends Component {

    constructor(props) {
		super(props);
		
	}

    render(){
        return(
            <svg width="40" height="40" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>icon_outcomes</title>
                <desc>Created using Figma</desc>
                <g id="Canvas" transform="translate(4913 5258)">
                <g id="icon_outcomes">
                <g id="spacer_iconsmall">
                </g>
                <g id="basic_signs">
                <g id="Vector">
                <use href="#path0_stroke" transform="translate(-4897.33 -5248.72)" fill="#64757B"/>
                </g>
                <g id="Vector">
                <use href="#path1_stroke" transform="translate(-4906 -5241.42)" fill="#64757B"/>
                </g>
                <g id="Vector">
                <use href="#path2_stroke" transform="translate(-4893 -5235.95)" fill="#64757B"/>
                </g>
                <g id="Vector">
                <use href="#path3_stroke" transform="translate(-4893 -5243.25)" fill="#64757B"/>
                </g>
                <g id="Vector">
                <use href="#path4_stroke" transform="translate(-4893 -5251)" fill="#64757B"/>
                </g>
                <g id="Vector">
                <use href="#path5_stroke" transform="translate(-4896.47 -5225.46)" fill="#64757B"/>
                </g>
                </g>
                </g>
                </g>
                <defs>
                <path className={this.props.class} id="path0_stroke" d="M 14.3 5.47368L 14.3 6.47368L 14.6844 6.47368L 14.9699 6.21615L 14.3 5.47368ZM 0 5.47368L -1 5.47368L -1 6.47368L 0 6.47368L 0 5.47368ZM 0 0L 0 -1L -1 -1L -1 0L 0 0ZM 14.3 0L 14.9699 -0.742461L 14.6844 -1L 14.3 -1L 14.3 0ZM 17.3333 2.73684L 18.0032 3.4793L 18.8261 2.73684L 18.0032 1.99438L 17.3333 2.73684ZM 14.3 4.47368L 0 4.47368L 0 6.47368L 14.3 6.47368L 14.3 4.47368ZM 1 5.47368L 1 0L -1 0L -1 5.47368L 1 5.47368ZM 0 1L 14.3 1L 14.3 -1L 0 -1L 0 1ZM 13.6301 0.742461L 16.6634 3.4793L 18.0032 1.99438L 14.9699 -0.742461L 13.6301 0.742461ZM 16.6634 1.99438L 13.6301 4.73122L 14.9699 6.21615L 18.0032 3.4793L 16.6634 1.99438Z"/>
                <path className={this.props.class} id="path1_stroke" d="M 3.03333 0L 3.03333 -1L 2.64888 -1L 2.36344 -0.742461L 3.03333 0ZM 17.3333 0L 18.3333 0L 18.3333 -1L 17.3333 -1L 17.3333 0ZM 17.3333 5.47369L 17.3333 6.47369L 18.3333 6.47369L 18.3333 5.47369L 17.3333 5.47369ZM 3.03333 5.47369L 2.36344 6.21615L 2.64888 6.47369L 3.03333 6.47369L 3.03333 5.47369ZM 0 2.73684L -0.66989 1.99438L -1.49278 2.73684L -0.66989 3.4793L 0 2.73684ZM 3.03333 1L 17.3333 1L 17.3333 -1L 3.03333 -1L 3.03333 1ZM 16.3333 0L 16.3333 5.47369L 18.3333 5.47369L 18.3333 0L 16.3333 0ZM 17.3333 4.47369L 3.03333 4.47369L 3.03333 6.47369L 17.3333 6.47369L 17.3333 4.47369ZM 3.70322 4.73122L 0.66989 1.99438L -0.66989 3.4793L 2.36344 6.21615L 3.70322 4.73122ZM 0.66989 3.4793L 3.70322 0.742461L 2.36344 -0.742461L -0.66989 1.99438L 0.66989 3.4793Z"/>
                <path className={this.props.class} id="path2_stroke" d="M -1 0L -1 10.9474L 1 10.9474L 1 0L -1 0Z"/>
                <path className={this.props.class} id="path3_stroke" d="M 1 1.82456L 1 0L -1 0L -1 1.82456L 1 1.82456Z"/>
                <path className={this.props.class} id="path4_stroke" d="M 1 2.2807L 1 0L -1 0L -1 2.2807L 1 2.2807Z"/>
                <path className={this.props.class} id="path5_stroke" d="M 0 1L 6.93333 1L 6.93333 -1L 0 -1L 0 1Z"/>
                </defs>
            </svg>
        )

    }

}

export default OutcomesIcon;