import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Jumbotron, Container, Row, Col, Form, FormGroup, Button } from 'reactstrap';
import analyticsTracker from '../../analytics/AnalyticsTracker';
//reusing maintenance css for basic layout to try and lessen css duplication
import "./MaintenanceMode.css";
import "./EmailAuth.css";

class EmailAuthError extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.tryAgain = this.tryAgain.bind(this);

    let message = props.location.pathname.search("unauthorized") > 0 ? 
                        "The one-time link was not valid. It either expired, has already been used once, or was sent to a non-work email." : 
                       <p>We are working on fixing the issue.<br></br> Please try again in a few minutes.</p>;
    this.state = {
      errorMessage : message
    };
  }

  componentDidMount() {
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Auth0 error', 
      error: this.props.match
    });
  }

  tryAgain(e) {
    const { history } = this.props;
    history.push({
      pathname: '/auth'
    });
  }

  render () {
    return (
      <div className="maintenance__outer_container">
      <Jumbotron className="maintenance__background">
          <Form className="maintenance__container">
            <FormGroup>
              <Container>
              <Row>
              <Col xs={{size:12}} md={{size:10, offset:2}} lg={{size:8, offset:2}} style={{ textAlign: 'center' }}>
                  <h1 className="email_auth_headline_text"><b>Sorry!</b></h1>
                  <h4 className="email_auth_headline_text" style={{ marginBottom: '14px' }}>An error has occurred.</h4>
                  <p className="email_auth_body_text">{this.state.errorMessage}</p>
                  <Button className="button__primary button__primary__long" style={{ "margin-right": '0px' }} onClick={this.tryAgain} >REFRESH</Button>                
                </Col>
              </Row>
              </Container>
            </FormGroup>
          </Form>
      </Jumbotron>
      </div>
    );
  }
}

export default EmailAuthError;
