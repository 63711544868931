
class LearningModules{
    constructor(){

this.moduleFilters = [
    {label : "", filterLabel : "All", filterOrder : 0, value : '' },
    {label : "communication", filterLabel : "Communication",filterOrder : 2, value : "communication"},
    {label : "equity", filterLabel : "Equity",filterOrder : 1, value : "equity"},
    {label : "management", filterLabel : "Leadership",filterOrder : 3, value : "management"}
]


    }

getLearningModulesFilters(){
   return this.moduleFilters;
}


}

const learningModules = new LearningModules();
export default learningModules;