import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './views/App';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// We will no longer use serviceWorkers as they've become particularly
// problematic and overly sticky in Chrome such that we can't reliably
// get repeat users on the latest version of the app 
unregister();
