import auth0 from 'auth0-js';

let tenants = 
[
  {
    name: 'tequitable',
    auth0Domain: 'auth.tequitable.com',
    auth0ClientId:'SqQC8LDY8jRBgSHeEWhQ9yhPzx0ejd6u',
    auth0Audience: 'https://api.tequitable.com/',
    scope: 'openid email',
    responseType: 'token id_token',
  },
  {
    name: 'tequitable-staging',
    auth0Domain: 'auth.tequitable-staging.com',
    auth0ClientId:'l0mqapEicHJIVCbbB1Rg8mkFUyq8v1zp',
    auth0Audience: 'https://api.tequitable.com/',
    scope: 'openid email',
    responseType: 'token id_token',
  }
];

class Auth0Tenants {

  

  constructor() {
    this.index = -1;
    
    if (window.location.href.search('tequitable.com') > 0 || window.location.href.search('thesoundingboard.io') > 0) {
      this.index = 0;
    } else {
      this.index = 1;
    }
  }

  getDomain() {
    return tenants[this.index].auth0Domain;
  }

  getClientId() {
    return tenants[this.index].auth0ClientId;
  }

  getAudience() {
    return tenants[this.index].auth0Audience;
  }

  getScope() {
    return tenants[this.index].scope;
  }

  getResponseType() {
    return tenants[this.index].responseType;
  }
};

const auth0Tenants = new Auth0Tenants();

export const AUTH0_CLIENT_ID=auth0Tenants.getClientId();
export const AUTH0_DOMAIN=auth0Tenants.getDomain();
export const AUTH0_AUDIENCE=auth0Tenants.getAudience();
export const AUTH0_SCOPE=auth0Tenants.getScope();
export const AUTH0_RESPONSETYPE=auth0Tenants.getResponseType();
export const REDIRECT_URL=window.location.href;

var webAuth = new auth0.WebAuth({
    domain:       AUTH0_DOMAIN,
    clientID:     AUTH0_CLIENT_ID,
    redirectUri:  REDIRECT_URL,
    scope: AUTH0_SCOPE,
    responseType: AUTH0_RESPONSETYPE,
    audience: AUTH0_AUDIENCE
  });

export default webAuth;