/**
 * DoMoreButton.js
 * Button for learning modules to Explore More Options
 * @param {string} label - Label for the button. Default value is "Explore More Options".
 * @param {string} route - Route to take when button is clicked. Default is to go back to the last page.
 */ 

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

class DoMoreButton extends Component {

  constructor(props) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
  }

  render() {  
    return (
      <div className="learning__module_domore_container" >
        <Button className="button__secondary" onClick={this.clickHandler}>{this.props.label}</Button>
      </div>
    );
  }

  clickHandler(e) {
    e.preventDefault(); 

    const { history } = this.props;

    switch(true) {
      case !!this.props.route:
        // Navigate to the page defined by `this.props.route`
        history.push(this.props.route);
        break;
      default:
        history.goBack();
        break;
    }

  }

}

DoMoreButton.defaultProps = {
  label: 'Explore More Options',
  route: '',
  id: '',
};

DoMoreButton.propTypes = {
  label: PropTypes.string,
  route: PropTypes.string,
  id: PropTypes.string,
};

export default DoMoreButton;