import React, { Component } from 'react';


class MenuIcon extends Component {
    constructor(props) {
		super(props);
		
	}

    render(){
        return(
            <svg width="40" height="40" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>icon_menu</title>
            <desc>Created using Figma</desc>
            <g id="Canvas" transform="translate(4959 5214)">
            <g id="icon_menu">
            <g id="icon_hamburger">
            <g id="Vector">
            <use href="#path17_stroke" transform="translate(-4954 -5203)" fill="#64757B"/>
            </g>
            <g id="Vector">
            <use href="#path17_stroke" transform="translate(-4954 -5194)" fill="#64757B"/>
            </g>
            <g id="Vector">
            <use href="#path17_stroke" transform="translate(-4954 -5185)" fill="#64757B"/>
            </g>
            </g>
            </g>
            </g>
            <defs>
            <path id="path17_stroke" class={this.props.class} d="M 0 1L 30 1L 30 -1L 0 -1L 0 1Z"/>
            </defs>
            </svg>

        )

    }

}

export default MenuIcon;