import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import analyticsTracker from '../../analytics/AnalyticsTracker';
import './css/MiniIconCard.css';

class MiniIconCard extends Component {
  constructor(props) {
    super(props);
    this.sendToGA = this.sendToGA.bind(this);

    this.state = {
      currentLocation : '',
      componentLabel : this.props.label
    }
  }

  componentDidMount() {
    this.setState({currentLocation: window.location.hash})
  }

  sendToGA(e) {
    e.preventDefault();
    analyticsTracker.trackEvent({
      category: 'User',
      action: `Clicks ${this.state.componentLabel} on MenuBar`,
      label: `Clicked from ${this.state.currentLocation}`
    });
    if (this.props.toggle === "true") {
      this.props.toggleModal(this.props.url);
    }    
  }

  // href, icon (children), label

  render() {
    return (
      <div {...this.props} onClick={this.sendToGA}>
        {this.props.href && 
          <Link className={"minicard__link " + (this.props.selected ? 'minicard__selected ' : '') + (this.props.label === 'More' ? 'minicard__no_hover' : '')} to={this.props.href}>
            <span className="minicard__icon">{this.props.children}</span>
            <span className={this.props.selected ? "minicard__text minicard_selected_text" : "minicard__text"}>{this.props.label}</span>
          </Link>
        }
        {!this.props.href && 
          <span  className="minicard__link">
          <span className="minicard__icon">{this.props.children}</span>
          <span className="minicard__text">{this.props.label}</span>
          </span>
        }
      </div>
    );
  }
}

export default MiniIconCard;

