import { Component } from 'react';
import visitor_profile from '../VisitorProfile';
import analyticsTracker from '../../analytics/AnalyticsTracker';

class Solution extends Component {

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

	componentDidMount() {
    	// save the path to the database and the id to the visitor profile
        visitor_profile.recordPath(this.props.path_id);

        analyticsTracker.trackEvent({
          category: 'User',
          action: this.props.isALandingPage ? 'Reached Landing Page' : 'Reached Leaf Node',
          pathId: this.props.path_id
        });
  	}


  goBack(e) {
    e.preventDefault(); 

    const { history } = this.props;

    // Go back one page 
    history.go(-1);
  }
}

export default Solution; 