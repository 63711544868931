import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import IconCard from '../components/IconCard';
import solutionsList from '../solutions/SolutionsList';
import analyticsTracker from '../../analytics/AnalyticsTracker';


class RelatedActionsGroup extends Component {

  constructor(props) {
    super(props);

    this.makeCards = this.makeCards.bind(this);
  }

  trackActionClick(item) {
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Clicked Take Action Card',
      actionItem: item
    });
  }

  makeCards() {
    return this.props.links.filter(item => item == "solution_stories_search" || item.slug !== null).map((item, index) => {
      return (
        <Col className="learning__module_related_actions_col" key={index.toString()} xs="12" lg="6">
          <IconCard
            onClick={() => { this.trackActionClick(item == "solution_policies" || item == "solution_stories_search"? solutionsList[item].path_id: `solution_${item.slug}`) }}
            className="learning__module_related_actions_card"
            tag="a"
            href={item == "solution_policies" || item == "solution_stories_search" ? solutionsList[item].to: item.slug == "advice"? `#/outcome/schedule`:`#/solutions/${item.slug}`}
            heading={item == "solution_policies" || item == "solution_stories_search"? solutionsList[item].solution_title:item.title}
            key={index.toString()}
            text={item == "solution_policies" || item == "solution_stories_search"? solutionsList[item].solution_text:item.subtitle}
            pathid={item == "solution_policies" || item == "solution_stories_search"? solutionsList[item].path_id:`solution_${item.slug}`}>
            <img src={item == "solution_policies" || item == "solution_stories_search"? solutionsList[item].icon_src:item.icon_url} className="outcome-icon" alt={item == "solution_policies" || item == "solution_stories_search"? solutionsList[item].solution_title:item.title} />
          </IconCard>
        </Col>
      );
    });

  }

  render() {
    return (
      <Row className="learning__module_related_actions_section">
        <Col xs="12">
          <h1 className="learning__module_related_actions_title">{this.props.title}</h1>
        </Col>
        {this.makeCards()}
      </Row>
    );
  }

}

RelatedActionsGroup.defaultProps = {
  title: '',
  links : []
};

  

RelatedActionsGroup.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RelatedActionsGroup; 

