class Cookies {

  setCookie(key, value) {
    document.cookie = `${key}=${value}`;
  }

  _parseCookies() {
    const cookies = document.cookie.split('; ');
    const cookieMap = new Map();
    cookies.forEach(cookie => {
      const [name, value] = cookie.split('=');
      cookieMap.set(name, value);
    });
    return cookieMap;
  }

  getCookie(key) {
    return this._parseCookies().get(key);
  }
}

const cookies = new Cookies();

export default cookies;