import IconCardsContainer from '../containers/IconCardsContainer';
import solutionsList from '../solutions/SolutionsList';
import visitor_profile from '../VisitorProfile';
import handleExpiredTokens from '../../utils/jwtErrorHandler';
class CovidResourceList extends IconCardsContainer {
   
    componentDidMount() {

    this.fetchLearningModules()
    }

    fetchLearningModules(){
          fetch(`/learningModules`,
          {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + visitor_profile.getJwt(),
            'tEQ-preauthorized': visitor_profile.getPreauthorized()
            }
          })
          .then(res => {
            handleExpiredTokens(res);
            if (!res.ok){
              throw Error(res.statusText);
            } else{

              return res;
            }
          })
          .then(res => res.json())
          .then(data=>{
            const requiredModules = [
              "show_compassion",
              "destress",
              "toughconvos",
              "bystander",
              "empathy"
            ];
         
              let sortedList=[];
              var necessaryModules = data.filter(module => requiredModules.includes(module.slug));
              for(let i = 0; i<requiredModules.length; i++){
                for(let j = 0; j<necessaryModules.length; j++){
                  if(requiredModules[i] == necessaryModules[j].slug){
                    sortedList.push(necessaryModules[j]);
                    
                  }
                }
              }

              sortedList.splice(1, 0, solutionsList.story_parenting_while_wfh);
              this.setState({ 'links': sortedList });
          })
    }
  
    

}

export default CovidResourceList;