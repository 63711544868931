import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductFeedback from "./components/ProductFeedback";
import "./Footer.css";
import moment from "moment";
import analyticsTracker from "../analytics/AnalyticsTracker";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFeedback: false
    };

    this.toggleFeedback = this.toggleFeedback.bind(this);
  }

  toggleFeedback(e) {
    if (e) e.preventDefault();
    this.setState({ openFeedback: !this.state.openFeedback });

    if (this.state.openFeedback == true) {
      this.trackActionClick("Close Feedback");
    } else {
      this.trackActionClick("Open Feedback");
    }
  }

  trackActionClick(link) {
    analyticsTracker.trackEvent({
      category: "User",
      action: `Clicks ${link} on Footer`,
    });
  }

  render() {
    return (
      <div className="Footer">
        <div className="footer__links">
          <div className="footer__linkcontainer">
            <Link
              className="footer__link"
              to="/legal/terms_of_use"
              onClick={() => {
                this.trackActionClick("Terms");
              }}
            >Terms</Link>
          </div>
          <div className="footer__linkcontainer">
            <Link
              className="footer__link"
              to="/legal/privacy_policy"
              onClick={() => {
                this.trackActionClick("Privacy");
              }}
            >Privacy</Link>
          </div>
          <div className="footer__linkcontainer">
            <Link
              className="footer__link"
              to="/faq"
              onClick={() => {
                this.trackActionClick("FAQ");
              }}
            >FAQ</Link>
          </div>
          <div className="footer__linkcontainer">
            <a
              className="footer__link"
              href="https://www.tequitable.com"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => {
                this.trackActionClick("tequitable.com");
              }}
            >tequitable.com</a>
          </div>
          <div className="footer__linkcontainer">
            <button
              className="button__tertiary footer__link"
              onClick={this.toggleFeedback}
            >Feedback?</button>
            <ProductFeedback
              open={this.state.openFeedback}
              onClose={this.toggleFeedback}
            />
          </div>
        </div>
        <div className="footer__copyright">
          Copyright &copy; {moment().format("YYYY")} tEQuitable, Inc. All rights
          reserved. <br />
          Icons from the Noun Project &amp; CC Images courtesy of #WOCinTech
          Chat
        </div>
      </div>
    );
  }
}

export default Footer;
