/**
OutcomeManagers.js
Container element for links on the OutcomeManagers screen
*/

import IconCardsContainer from '../containers/IconCardsContainer';

class OutcomeManagers extends IconCardsContainer {

  componentDidMount() {
    const items = [
      {
        path_id: "solution_distribute_work",
        to:"#/solutions/distribute_work",
        solution_title: "Assign Work Equitably",
        solution_text:"Improve employee engagement and retention",
        icon_src: "/icons/large/icon_large_searchstories.svg",
        featureEnabled: true
      },
      {
        path_id: "solution_advice",
        to:"#/outcome/schedule",
        solution_title: "Get Advice",
        solution_text:"Talk confidentially to an independent Ombuds",
        icon_src: "/icons/large/icon_large_talk.svg",
        featureEnabled: true
      }
    ];  

    this.setState({ 'links' : items });
  }
  
}

export default OutcomeManagers;

