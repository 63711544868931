import React, { Component } from "react";
import { Card, CardBody, CardFooter, CardGroup, CardSubtitle, CardText, CardTitle, } from "reactstrap";

import './OutsideOmbuds.css';

class OutsideOmbuds extends Component {
  constructor(props) {
    super(props);
    this.Ombuds_1 = {
      name: "Lillian Rivera",
      title: "Ombuds to the Entertainment Industry",
      affiliation: "Office of the MyConnext Ombuds",
      link: "https://myconnext.org/meet-lilly",
      headshot: "/biopic/lillian-rivera.png"
    };
    this.Ombuds_2 = {
      name: "Dr. Hector Escalante Meza",
      title: "Consulting Ombuds",
      affiliation: "Office of the MyConnext Ombuds",
      link: "https://myconnext.org/meet-hector",
      headshot: "/biopic/hector-escalante-meza.png",
    };
  }

  render() {
    return (
      <>
        <div className="ombuds__section-header">Lillian is the full-time Ombuds. For more flexible availability, please schedule time with her.</div>
        <CardGroup className="ombuds__profiles text-center mx-auto">
          <Card ombuds="1">
            <img className="card-img-top mx-auto" src={this.Ombuds_1.headshot} alt={this.Ombuds_1.name}/>
            <CardBody>
              <CardTitle tag="h4">{this.Ombuds_1.name}</CardTitle>
              <CardSubtitle tag="h5">{this.Ombuds_1.title}</CardSubtitle>
              <CardText>{this.Ombuds_1.affiliation}</CardText>
            </CardBody>
            <CardFooter>
              <a className="btn" href={this.Ombuds_1.link} target="_blank">schedule an appointment</a>
            </CardFooter>
          </Card>
          <Card ombuds="2">
            <img className="card-img-top mx-auto" src={this.Ombuds_2.headshot}  alt={this.Ombuds_2.name}/>
            <CardBody>
              <CardTitle tag="h4">{this.Ombuds_2.name}</CardTitle>
              <CardSubtitle tag="h5">{this.Ombuds_2.title}</CardSubtitle>
              <CardText>{this.Ombuds_2.affiliation}</CardText>
            </CardBody>
            <CardFooter>
            <a className="btn" href={this.Ombuds_2.link} target="_blank">schedule an appointment</a>
            </CardFooter>
          </Card>
          </CardGroup>
        </>
    );
  }
}

export default OutsideOmbuds;
