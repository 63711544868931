import React, { Component } from 'react';
import { Switch } from "react-router-dom";
import PrivateRoute from '../routing/PrivateRoute';

import './OutcomeCategories.css';
import respondCategories from './outcomes';
import OutcomeResponse from './containers/OutcomeResponse';
import HeroListContainer from './containers/HeroListContainer';

const respondArry = Object.keys(respondCategories).map(k => respondCategories[k]);

class RespondCategories extends Component {
	
	renderOutcome(subcategory, props) {
		return (
			<HeroListContainer {...props} contentCssClass="short__background_list" title={subcategory.solution_title} subtitle={subcategory.solution_text} currentsection="outcomes">
				<OutcomeResponse {...props} path_id={subcategory.path_id} items={subcategory.solutions} />
			</HeroListContainer>);
	}
	
	render() {
			return (
				<Switch>
					{respondArry.map(c => <PrivateRoute exact key={c.path_id} path={`/respond/${c.path}`} render={ (props) => this.renderOutcome(c, props) } /> )}
				</Switch>
		);
	}
}

export default RespondCategories;

