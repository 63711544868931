import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './css/SchedulerDayNav.css';

class SchedulerDayNav extends Component {
  render() {
    let previous;
    let next;

    previous = this.props.previousActive ?  <Button className="button__primary" value="-7" onClick={this.props.previousHandler}>Previous 7 days</Button> : null;
    next = this.props.nextActive ?  <Button className="button__primary" value="+7" onClick={this.props.nextHandler}>Next 7 days</Button> : null;

    return (
      <div className="scheduler__daynav">
        {previous}
        {next}
      </div>
    );
  }
}

SchedulerDayNav.propTypes = {
  previousActive: PropTypes.bool,
  nextActive: PropTypes.bool,
  previousHandler: PropTypes.func,
  nextHandler: PropTypes.func
};

export default SchedulerDayNav;

