import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Jumbotron } from 'reactstrap';
import MenuNavBar from '../MenuNavBar';
import BackButton from '../components/BackButton';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

class HeroListContainer extends Component {

  render() {
    return (
      <div className="page__container" >
        <ScrollToTopOnMount/>
        <MenuNavBar className="page__navbar" selected={this.props.currentsection} />
        <Jumbotron className="short__background" fluid>
          <Container className={ this.props.heroClass ? this.props.heroClass : 'short__background_hero'} fluid>
            <BackButton {...this.props}/>
            <Col xs="11">
              <h1 className="hero__title">
                {this.props.title}
              </h1>
            </Col>
            <Col xs="12" lg="11">
              <h4 className="hero__subtitle">{this.props.subtitle}</h4>
            </Col>
          </Container>
          <Container className={this.props.contentCssClass} fluid>
                {this.props.children}
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

HeroListContainer.defaultProps = {
  contentCssClass: null,
  title: '',
  subtitle: '',
  currentsection : ''
};

  

HeroListContainer.propTypes = {
  contentCssClass: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  currentsection: PropTypes.string
};

export default HeroListContainer; 

