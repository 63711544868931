import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Jumbotron, Container, Row, Col, Form, FormGroup } from 'reactstrap';
import "./EmailAuth.css";
import webAuth from './tenants';

class CrossOriginFailover extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  render () {
    webAuth.crossOriginVerification();
    return (
      <div className="page__container">
      <Jumbotron className="big__background_image">
          <Form className="authentication__container">
            <FormGroup>
              <Container>
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:11, offset:1}} lg={{size:8, offset:1}}>
                  Failover
                </Col>
              </Row>
              </Container>
            </FormGroup>
          </Form>
      </Jumbotron>
      </div>
    );
  }
}

export default CrossOriginFailover;