
/* 
  This is the centralized list of all learning modules. Any outcome category
  such as Feel Validated or Respond should reference these to populate the 
  learning modules in their category. Elements are referenced by name. 
  This list is also used for populating RelatedActionsGroup. 
  The names of the elements are also referenced in the database and retrieved 
  by /stories/:id and passed to RelatedActionsGroup.
*/

import visitor_profile from '../VisitorProfile';

const solutionsList = {
  solution_stories_search : {
    path_id: "solution_stories_search",
    to:"#/solutions/storiesSearch?topics=&subtopics=",
    solution_title: "Read Stories",
    solution_text:"See similar stories, remedies tried, and resulting outcomes",
    icon_src: "/icons/large/icon_large_searchstories.svg",
    featureEnabled: true
  },
  solution_learning_modules_search : {
    path_id: "solution_learning_modules_search",
    to:"#/solutions/learningModulesSearch?category=",
    solution_title: "Respond",
    solution_text:"Find out how to respond to the situation",
    icon_src: "/icons/large/icon_large_respond.svg",
    featureEnabled: true
  },
  solution_toughconvos : { 
    path_id: "solution_toughconvos",
    to:"#/solutions/toughconvos",
    solution_title: "Strategize",
    solution_text:"Learn how to have tough conversations",
    icon_src: "/icons/large/icon_large_strategies.svg",
    // topics: "covid-19",
    featureEnabled: true
  },
  solution_bystander : {
    path_id: "solution_bystander",
    to:"#/solutions/bystander",
    solution_title: "Be an Active Bystander",
    solution_text: "Learn how to respond in the moment",
    icon_src: "/icons/large/icon_large_bystander.svg",
    // topics: "covid-19",
    featureEnabled: true
  },
  solution_empathy : {
    path_id: "solution_empathy",
    to:"#/solutions/empathy",
    solution_title: "Speak with Empathy",
    solution_text: "Learn tips for better communication at work",
    // topics: "covid-19",
    icon_src: "/icons/large/icon_large_empathy.svg",
    featureEnabled: true
  },
  solution_letter : {
    path_id: "solution_letter",
    to:"#/solutions/letter",
    solution_title: "Draft a Letter",
    solution_text: "Opportunity to process your thoughts",
    icon_src: "/icons/large/icon_large_letter.svg",
    featureEnabled: true
  },
  solution_advice : {
    path_id: "solution_advice",
    to:"#/outcome/schedule",
    solution_title: "Get Advice",
    solution_text:"Talk confidentially to an independent Ombuds",
    icon_src: "/icons/large/icon_large_talk.svg",
    featureEnabled: true
  },
  solution_policies : {
    path_id: "solution_policies",
    to:"#/solutions/policies",
    solution_title:`Review ${visitor_profile.getOrgType().charAt(0).toUpperCase() + visitor_profile.getOrgType().slice(1) } Resources`,
    icon_src: "/icons/large/icon_large_reviewpolicies.svg",
    solution_text:`Review my ${visitor_profile.getOrgType()}'s policies and documents`,
    featureEnabled: true
  },
  solution_hr : {
    path_id: "solution_hr",
    to:"#/solutions/hr",
    solution_title: "Make a Report",
    icon_src: "/icons/large/icon_large_filecomplaint.svg",
    solution_text:"Understand the formal reporting process",
    featureEnabled: true
  },
  solution_distribute_work : {
    path_id: "solution_distribute_work",
    to:"#/solutions/distribute_work",
    solution_title: "Assign Work Equitably",
    solution_text:"Improve employee engagement and retention",
    icon_src: "/icons/large/icon_large_assignwork.svg",
    featureEnabled: true
  },
  solution_offensive_comment : {
    path_id: "solution_offensive_comment",
    to:"#/solutions/offensive_comment",
    solution_title: "Backhanded Compliments",
    solution_text:"Recommendations for problematic compliments",
    icon_src: "/icons/large/icon_large_backhanded_compliment.svg",
    featureEnabled: true
  },
  solution_inclusive_meeting : {
    path_id: "solution_inclusive_meeting",
    to:"#/solutions/inclusive_meeting",
    solution_title: "Run an Inclusive Meeting",
    solution_text:"Facilitate Collaboration",
    icon_src: "/icons/large/icon_large_meeting.svg",
    featureEnabled: true
  },
  solution_find_allies : {
    path_id: "solution_find_allies",
    to:"#/solutions/find_allies",
    solution_title: "Engage an Ally",
    icon_src: "/icons/large/icon_large_find_allies.svg",
    solution_text:"Find someone who will have your back",
    featureEnabled: true
  },
  solution_show_compassion : {
    path_id: "solution_show_compassion",
    to: "#/solutions/show_compassion",
    icon_src: "/icons/large/icon_lg_empathy.svg",
    solution_title: "Show Compassion",
    solution_text: "Empathize with your coworkers when times are hard",
    // topics: "covid-19",
    featureEnabled: true
  },
  solution_destress : {
    path_id: "solution_destress",
    to: "#/solutions/destress",
    icon_src: "/icons/large/icon_lg_destress2.svg",
    solution_title: "Take Time to De-Stress",
    solution_text: "Get in the right headspace for work",
    // topics: "covid-19",
    featureEnabled: true
  },
  solution_better_ally : {
    path_id: "solution_better_ally",
    to: "#/solutions/better_ally",
    icon_src: "/icons/large/icon_large_better_allies2.svg",
    solution_title: "Be a Better Ally",
    solution_text: "Create a more inclusive workplace for your under-represented colleagues",
    featureEnabled: true
  },
  story_parenting_while_wfh : {
    path_id: "solution_stories_search_story",
    to:"#/solutions/storiesSearch/78",
    solution_title: "Parenting While WFH Makes me Feel Like I'm Drowning",
    topics: "story", // story class allows for different styling
    featureEnabled: true
  },
  'solution_build-equitable-hybrid-teams' : {
    to: '#/solutions/build-equitable-hybrid-teams',
    path_id: "solution_build-equitable-hybrid-teams",
    icon_src: '/icons/large/icon_large_hybrid.svg',
    solution_title: 'Build Equitable Hybrid Teams',
    solution_text: 'Ensure everyone can be an active participant',
    featureEnabled: true
  }
};

function replaceOrgType() {
  let orgTypeLower = visitor_profile.getOrgType();
  let orgTypeUpper = visitor_profile.getOrgType().charAt(0).toUpperCase() + visitor_profile.getOrgType().slice(1);
  solutionsList.solution_policies.solution_text = solutionsList.solution_policies.solution_text.replace('company', orgTypeLower);
  solutionsList.solution_policies.solution_title = solutionsList.solution_policies.solution_title.replace('Company', orgTypeUpper);  
}
  
export default solutionsList; 
export { replaceOrgType }; 

