/**
OutcomeValidation.js

Container element for links on the OutcomeValidation screen
*/

import IconCardsContainer from '../containers/IconCardsContainer';
import solutionsList, { replaceOrgType } from '../solutions/SolutionsList';

class OutcomeValidation extends IconCardsContainer {

  componentDidMount() {
    const items = [
      solutionsList.solution_stories_search,
      solutionsList.solution_policies,
      solutionsList.solution_destress,
      solutionsList.solution_advice,
    ];

    replaceOrgType();

    this.setState({ 'links' : items });
  }

}

export default OutcomeValidation;
