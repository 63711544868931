/*
	AppointmentFormatter: 
	Format the date, start time, and end time so that it's nicely user readable. Should
	look something like: Friday April 20, 2018 10:00 AM to 11:00 AM
*/

import moment from 'moment-timezone';

function AppointmentFormatter(appointment) {
	if (appointment)
	{			
		var date = "";
		var startTime = "";
		var endTime = "";

		// Looks like 2018-03-10T15:00.000Z 
		var fullDate = new Date(appointment.start);
		startTime = fullDate.toLocaleTimeString('en-US');
		startTime = startTime.slice(0, -6) + startTime.slice(-3);

		date = moment(fullDate).format('dddd') + ", " + moment(fullDate).format('MMMM D, YYYY');

		fullDate = new Date(appointment.end);
		endTime = fullDate.toLocaleTimeString('en-US');
		endTime = endTime.slice(0, -6) + endTime.slice(-3);
		
		// Use the visitor's current timezone 
		const browserTZ = moment.tz.guess();
		var showTZ = browserTZ.replace(/_/g, " ");
		var timezone = moment(fullDate).tz(browserTZ).format('z');

		var whichTwelve = "";
		const mHour = new Date(appointment.start).getHours();
    	if (mHour == 0 || mHour == 12) {
      		if (mHour == 0) {
        		whichTwelve = "Midnight";
      		} else if (mHour == 12) {
        		whichTwelve = "Noon";
      		}
    	}	   

		return {
			'date': date, 'startTime' : startTime, 'endTime' : endTime, 'timezone' : timezone, 'whichTwelve' : whichTwelve, 'showTZ' : showTZ
		};
	}
	else
		return {
			'date' : "", 'startTime' : "", 'endTime' : "", 'timezone' : "", 'whichTwelve' : "", 'showTZ' : ""
		};
}

export default AppointmentFormatter; 