import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import HeroListContainer from '../../containers/HeroListContainer';
import FilterControl from '../../components/FilterControl';
import { Row, Col } from 'reactstrap';
import visitor_profile from '../../VisitorProfile';
import analyticsTracker from '../../../analytics/AnalyticsTracker';
import handleExpiredTokens from '../../../utils/jwtErrorHandler';
import learningModules from './learningModules';
import IconCard from '../../components/IconCard';
import solutions from '../SolutionsList';
import '../../../views/OutcomeCategories.css'

import '../stories/StoryList.css';

class LearningModulesList extends Component {

    constructor(props) {
        super(props);
    
      this.state = { loadedItems : [],
        hasMoreLearningModules : true,
        availableCategories : [],
        selectedCategoryFilters : `${props.location.search.slice(1)}`,
        errorMessage : "",
      };

      this.searchResults = [];
      this.increment = 4; 
      this.endIndex = this.increment; 
      this.learningModulesLength = 0;

      visitor_profile.setLastLearningModuleFilterRoute(props.location.search)
    }


    render(){
      var filters = learningModules.getLearningModulesFilters(this.searchResults);
      var items = [];
      var solutionCardData = [solutions.solution_stories_search, solutions.solution_advice];
      var fullCardList = [...this.state.loadedItems,...solutionCardData.map((card)=> {return { itemID : card.path_id, title : card.solution_title, subtitle:card.solution_text, icon_url: card.icon_src, to:card.to, path_id: card.path_id }})];
      for (var i=0; i<fullCardList.length; i+=1){
        const moduleData = fullCardList[i];
        items.push(
          <Col xs="12" lg="6" key={i}>
            <IconCard
              tag="a"
              href={moduleData.to? moduleData.to :`#/solutions/${moduleData.itemID}`}
              key={i}
              onClick={(e) => this.saveFiltersAndGoToModuleRoute(e, moduleData)}
              heading={moduleData.title}
              text={moduleData.subtitle}
              pathid={moduleData.path_id? moduleData.path_id:`solution_${moduleData.itemID}`}
              category={moduleData.category}
              >
              { moduleData.icon_url ? <img src={moduleData.icon_url} className="img-fluid" alt={moduleData.title} /> : '' }
            </IconCard>
          </Col>
        );
      }

      return(
        <HeroListContainer {...this.props} contentCssClass="short__background_list_page" title={this.props.title} subtitle={this.props.subtitle} currentsection="outcomes">
          <Row className="short__background_content_resources">
            <Col sm="12" md={{size: 10, offset: 1}}>
              <FilterControl id="categoryFilters" title="Filter" filters={filters} selectedFilters={this.state.selectedCategoryFilters} filtersChangedAction={this.categoryFiltersChanged.bind(this)} />
              <InfiniteScroll pageStart={0}
                  loadMore={this.loadMoreLearningModules.bind(this)}
                  hasMore={this.state.hasMoreLearningModules}
                  loader={<div className="loader" key={0}>Loading ...</div>}>
                <div className="story__summary_card_list">
                  <Row>
                    {items}
                  </Row>
                </div>
              </InfiniteScroll>
              <div>{this.state.errorMessage}</div>
            </Col>
          </Row>
        </HeroListContainer>
      );

    };

    componentDidMount(){
    
      let queryString = this.props.location.search;

      this.fetchLearningModules(queryString)
        .then(res => { 
            if (!res.ok) 
              throw Error(res.statusText); 
            else
              return res;
          });
  
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Reached Landing Page',
        pathId: this.props.path_id
      });

    }

    fetchLearningModules(queryString){
      return new Promise((resolve, reject)=> {

      fetch(this.props.endpoint.replace('learningModules', 'categories'),
      {
        method: 'GET',
        headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + visitor_profile.getJwt(),
         'tEQ-preauthorized': visitor_profile.getPreauthorized()
        }
      })
      .then(res => {
        handleExpiredTokens(res);
        if (!res.ok){
          throw Error(res.statusText);
        } else
          return res.json();
      })
      .then(res => this.setState({availableCategories : res})
    );

        // Fetch learningmodules
        fetch(this.props.endpoint + queryString + "&visitorId=" + visitor_profile.getId(), 
        {
          method: 'GET',
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + visitor_profile.getJwt(),
           'tEQ-preauthorized': visitor_profile.getPreauthorized()
          }
        })
        .then(res => { 
          handleExpiredTokens(res);
          if (!res.ok){
              throw Error(res.statusText);
        } else
            return res;
        })
        .then(res => res.json())
        .then(data => {
          this.learningModulesLength = data.length;
          for (var i=0; i < data.length; i++) {   
            this.searchResults.push({ itemID : data[i].slug, title : data[i].title, category : data[i].category, subtitle : data[i].subtitle, icon_url: data[i].icon_url });
          }
          let items = this.searchResults.slice(0,this.endIndex);
          this.setState({loadedItems : items, hasMoreLearningModules : this.endIndex < this.learningModulesLength});
          this.endIndex += this.increment;
          if (data.length === 0)
            this.setState({errorMessage : "No learning modules found"});
          else
            this.setState({errorMessage : ""});

          visitor_profile.recordPath(this.props.path_id, queryString);
        })
      .catch(err => this.setState({errorMessage : "Oops! We were unable to find any learning modules, please try again or explore other learning modules."}));

      })
  }

  loadMoreLearningModules(page){
    const item = this.searchResults.slice(0, this.endIndex)
    this.setState({loadedItems : item, hasMoreLearningModules : this.endIndex < this.learningModulesLength });
    this.endIndex += this.increment;
  }

  categoryFiltersChanged(domEvent, selectedValues){
    // Reset our searchResults to an empty array
    this.searchResults = [];
    this.endIndex = this.increment;

    // Fetch learning modules with the given filters 
    if (selectedValues) {
      var categoryQueryString = this.createQueryString(selectedValues);
      
      this.setState({selectedCategoryFilters : "category=" + categoryQueryString});

      this.fetchLearningModules('?category=' + categoryQueryString);
    }
  }

  saveFiltersAndGoToModuleRoute(e, item){
    e.preventDefault();

    const { history } = this.props;

    history.replace({
      pathname: this.props.location.pathname,
      search: this.state.selectedCategoryFilters
    });

    visitor_profile.setLastLearningModuleFilterRoute(history.location);

    history.push({
      pathname:  item.to? item.to.replace("#","") :`/solutions/${item.itemID}`
    });

    if(item.to){
      history.replace(item.to.replace("#",""))
    }
  }

  createQueryString(selectedValues){
    var queryString = '';
    for (const value of selectedValues) {
      if (value !== '')
        queryString += value + ',';
    }

    return queryString;
  }

}

export default LearningModulesList;