import React, { Component } from 'react';
import visitor_profile from '../VisitorProfile';
import { ContentPreview } from 'box-ui-elements';
import messages from 'box-ui-elements/i18n/en-US';
import 'box-ui-elements/dist/preview.css';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import "./css/BoxDocumentViewer.css";

// Takes file_id (Box file id), title (document title)
// Fetches the document from Box and renders it 
class BoxDocumentViewer extends Component {

  state = { file_id : '', token : '', errorMessage : '' };

  constructor(props) {
    super(props);

    addLocaleData(enLocaleData);

    this.getTokenForDocument = this.getTokenForDocument.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.file_id && this.props.file_id !== prevProps.file_id) {
      this.setState({token : '', errorMessage: ''});
      this.getTokenForDocument(this.props.file_id);
    }
  }

  componentDidMount() {
    if (this.props.file_id) {
      this.getTokenForDocument(this.props.file_id);
    }
  }

  getTokenForDocument(doc_id) {
    if (doc_id) {
      fetch('/tokens/' + doc_id, {
          headers: {
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + visitor_profile.getJwt(),
            'tEQ-preauthorized': visitor_profile.getPreauthorized() }
          })
        .then(res => {
          if (!res.ok) 
            throw Error(res.statusText); 
          else
            return res;
        })
        .then(res => res.json())
        .then((data) => { 
          this.setState({file_id : doc_id});
          this.setState({token : data.accessToken, errorMessage: ''}); 
        })
        .catch(err => {
          console.log(err); 
          this.setState({errorMessage : '- Oops! Unable to load document, please go back and try again'}); 
        });
    }    
  }

  render() {
    return (
      <div className="doc__viewer" {...this.props}>
        <h2>{this.props.title} {this.state.errorMessage}</h2>
          {this.state.token && 
            <ContentPreview 
                className="doc__contentPreview"
                fileId={this.state.file_id.toString()}
                token={this.state.token}
                language='en-US'
                messages={messages}
                showDownload={false}
                header='none' />
          }          
      </div>
    );
  }
}

export default BoxDocumentViewer; 

