import React from 'react';
import PropTypes from 'prop-types';
import Solution from './Solution';
import { Row, Col } from 'reactstrap';
import SchedulerDayList from '../containers/SchedulerDayList';
import OutsideOmbuds from '../OutsideOmbuds';
import HeroListContainer from '../containers/HeroListContainer';
import ContentContainer from '../containers/ContentContainer';
import visitor_profile from '../VisitorProfile';
import handleExpiredTokens from '../../utils/jwtErrorHandler';
import moment from 'moment-timezone';


class SolutionAdvice extends Solution {


  constructor(props) {
    super(props);
    this.state = {
      days: [],
      page: 0,
      isOutsideOmbuds: false
    };

    this.navigationClickHandler = this.navigationClickHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.findSelectedSlots = this.findSelectedSlots.bind(this);
    this.getDateRange = this.getDateRange.bind(this);
    this.updateDateRange = this.updateDateRange.bind(this);
    this.setIsOutsideOmbuds = this.setIsOutsideOmbuds.bind(this);
  }

  componentDidMount() {

    super.componentDidMount();

    // start with two days from now
    var today = new Date();
    today.setUTCDate(today.getUTCDate()+2);

    this.getDateRange(today);

    visitor_profile.fetchCompanyInfo().then((data) => { this.setIsOutsideOmbuds(data.is_alt_talk) } );
  } 

  changeHandler(event) {
    const { history } = this.props;
    const slots = this.findSelectedSlots(event.target.value);

    history.push({
      pathname: '/outcome/schedule/time',
      state : {data: slots} 
    });
  }

  findSelectedSlots(selectedDate) {
    const selectedDayIndex = this.state.days.findIndex((day)=> {
      return day.date === selectedDate;
    });
    return this.state.days[selectedDayIndex].slots;
  }

  /**
   * getDateRange(): Gets list of dates
   * @param {mixed} start - A date string, or timestamp.
   * @param {integer} range - A positive integer. How many days to retrieve
   */

  getDateRange(start, range = 7) {
    return new Promise((resolve, reject)=> {
      const longTimezone = moment.tz.guess();
      fetch('/calendar/days/', {
          method: 'POST',
          body: JSON.stringify({ 
            start: new Date(start),
            range: range,
            timezone: { 
              short: moment().tz(longTimezone).format('z'),
              long: longTimezone
            }
          }),
          headers: {
            'Cache-Control': 'no-cache',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + visitor_profile.getJwt(),
            'tEQ-preauthorized': visitor_profile.getPreauthorized() }
          })
      .then(res => { 
        if (!res.ok) {
          handleExpiredTokens(res)
          throw Error(res.statusText); 
        } else
          return res;
      })
      .then(res => res.json())
      .then(calResults => {
          this.setState({days: calResults});
          resolve();
      })
      .catch(err => {
          console.log(err); 
          this.setState({days: []}); 
          reject(err);
      });
    });
  }

  updateDateRange(differential){
    /*
      If differential value is positive, grab the last value in this.state.days
      If it's negative, grab the first value in this.state.days
    */
    let bound = (differential > 0) ? this.state.days.slice(-1)[0] : this.state.days[0];

    bound = new Date(bound.date);

    /*
      If differential is positive, start the new range with the last date in the list.
      If it's negative, start the range at the first date - 5 days.
    */
    let startOfRange;

    if(differential > 0) {
      startOfRange = bound.setDate(bound.getDate() + 1);
    } else {
      startOfRange = bound.setDate(bound.getDate() + differential);
    }

    this.getDateRange(startOfRange);
  }

  setIsOutsideOmbuds(enabled) {
    this.setState({isOutsideOmbuds: enabled});
  }

  navigationClickHandler(event) {
    // + converts string to a number
    const diff = +event.target.value;
    this.updateDateRange(diff);
    const direction = (diff > 0) ? this.state.page + 1 : this.state.page - 1;
    this.setState({page: direction});
  }


  render () {
    return (
      <HeroListContainer {...this.props} contentCssClass="short__background_content" title="Talk" subtitle="Confidentially speak with an independent, impartial Ombuds" currentsection="talk">
        <Row className="short__background_content_resources">
          <Col xs="12" sm={{ size: 10, offset: 1 }} >
            <ContentContainer>
              <div className="empty__header">&nbsp;</div>
             {this.state.isOutsideOmbuds ? 
                <OutsideOmbuds/> :             
                <SchedulerDayList
                data={this.state.days}
                changeHandler={this.changeHandler}
                navigationClickHandler={this.navigationClickHandler}
                showNext={!!this.state.days.length}
                showPrevious={!!this.state.page} />}
            </ContentContainer>
          </Col>
        </Row>
      </HeroListContainer>
    );
  }
}


export default SolutionAdvice;

