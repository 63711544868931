import React, { Component } from 'react';
import HeroListContainer from './containers/HeroListContainer';
import AllOutcomes from './containers/AllOutcomes';
import OutcomeResponse from './containers/OutcomeResponse';
import OutcomeValidation from './containers/OutcomeValidation';
import OutcomeManagers from './containers/OutcomeManagers';
import visitor_profile from './VisitorProfile';
import analyticsTracker from '../analytics/AnalyticsTracker';

import './Options.css';

class Options extends Component {

  state = { title : "Take Action", subtitle : "What would you like to do?" };

  constructor(props) {
    super(props);

    this.trackPage = this.trackPage.bind(this);
    this.makeLinks = this.makeLinks.bind(this);

  }

  trackPage(page, pathId) {
    analyticsTracker.trackPage(page, visitor_profile.getCurrentPathId());
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Reached Landing Page',
      pathId
    });
  }

  makeLinks(pathId) {
    let childComponent;

    switch(pathId) {
      case 'outcome_categories':
        childComponent = <AllOutcomes {...this.props} />; 
        break; 
      case 'options_validation':
        childComponent = <OutcomeValidation />;
        break;
      case 'options_response':
        childComponent = <OutcomeResponse {...this.props} />;
        break;
      case 'options_managers':
        childComponent = <OutcomeManagers />;
        break;
      default:
        childComponent = this.props.children
    }

    return childComponent;
  }
  render() {
    return (
    <HeroListContainer  {...this.props} contentCssClass="short__background_list" title={this.state.title} subtitle={this.state.subtitle} currentsection="outcomes">
      {this.makeLinks(this.props.path_id)}
    </HeroListContainer>
    );
  }
  
  componentDidMount() {
    const page = this.props.location.pathname;
    this.trackPage(page, this.props.path_id);

    visitor_profile.recordPath(this.props.path_id);

    switch(this.props.path_id) {
      case 'outcome_categories':
        this.setState({title : "What Would You Like to Happen Next?"});
        this.setState({subtitle : "Identify what’s important to you, explore outcomes, and develop an action plan that provides the best resolution for you."});
        break;
      case 'options_validation':
        this.setState({title : "Feel Validated"});
        this.setState({subtitle : "Know that what I'm feeling is legitimate"});
        break;
      case 'options_response':
        this.setState({title : "Respond"});
        this.setState({subtitle : "Find out how to respond to the situation"});
        break;
      case 'options_managers':
        this.setState({title : "Manage Inclusively"});
        this.setState({subtitle : "Learn skills for building effective teams"});
        break;
      default: 
        break;
    }
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname;
    const nextPage = this.props.location.pathname;

    if (currentPage !== nextPage) {
      this.trackPage(nextPage);
    }
  }

  componentWillUnmount() {
    const currentPage = this.props.path_id;
    sessionStorage.setItem('prevPath', currentPage);
    analyticsTracker.trackEvent({
      action: '$pageleave',
      '$current_url': window.location.origin + this.props.location.pathname + this.props.location.search,
      pathName: this.props.location.pathname,
      pathId: currentPage,
      search: this.props.location.search
    });
  }
}

export default Options;
