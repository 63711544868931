/**
OutcomeResponse.js
Container element for links on the OutcomeResponse screen
*/

import IconCardsContainer from '../containers/IconCardsContainer';
import responseList from '../outcomes';
import visitor_profile from '../VisitorProfile';
import analyticsTracker from '../../analytics/AnalyticsTracker';

class OutcomeResponse extends IconCardsContainer {

  trackPage(page, pathId) {
    analyticsTracker.trackPage(page, visitor_profile.getCurrentPathId());
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Reached Landing Page',
      pathId
    });
  }

  componentDidMount() {

    if (!this.props.items) {
      const items = [
        responseList.outcomes_respond_communication,
        responseList.outcomes_respond_equity,
        responseList.outcomes_respond_management
      ];
      this.setState({ 'links' : items });
    } else {
      this.setState({ 'links' : this.props.items });
      this.trackPage(this.props.location.pathname, this.props.path_id);
      visitor_profile.recordPath(this.props.path_id);
    }
  }

  componentWillUnmount() {
    const currentPage = this.props.path_id;
    if (this.props.location) { // sometimes isn't set !?
      sessionStorage.setItem('prevPath', currentPage);
      analyticsTracker.trackEvent({
        action: '$pageleave',
        '$current_url': window.location.origin + this.props.location.pathname + this.props.location.search,
        pathName: this.props.location.pathname,
        pathId: currentPage,
        search: this.props.location.search
      });
    }
  }
  
}

export default OutcomeResponse;

