import React, { Component } from 'react';
import HeroListContainer from './containers/HeroListContainer';
import CovidResourceList from './containers/CovidResourcesList';

import './COVIDhub.css';

class COVIDhub extends Component {

  render() {
    return <HeroListContainer {...this.props} 
      contentCssClass="COVIDhub short__background_list" 
      title={this.props.title} 
      subtitle={this.props.subtitle} 
      currentsection="covid-19"
      heroClass="covid__background_hero">
      <CovidResourceList />
    </HeroListContainer>
  }
}

export default COVIDhub;