import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';

import SchedulerConfirmButton from './SchedulerConfirmButton';

import './css/SchedulerCard.css';

class SchedulerTimeCard extends Component {

  constructor(props) {
    super(props);

    this.MINUTE = (1000 * 60);
    this.HOUR = (this.MINUTE * 60);

    this.makeHour = this.makeHour.bind(this);
    this.makeDuration = this.makeDuration.bind(this);
  }

  makeHour(timeStamp){
    const inputDate = new Date(timeStamp); 
    const browserTZ = moment.tz.guess();
    return moment(inputDate).tz(browserTZ).format('h:mm a z');
  }  

  makeDuration(start, end){
    const startTS = new Date(start).getTime();
    const endTS   = new Date(end).getTime();   
    const duration = (endTS - startTS) / this.HOUR;
    const plural = (duration !== 1) ? 's' : '';

    return `${duration} hour${plural}`;
  }

  render() {
    const isSelected = classNames({
      'scheduler__timecard': true,
      'scheduler__timecard--selected': this.props.isSelected
    });

    let whichTwelve;
    const mHour = new Date(this.props.start).getHours();
    if (mHour == 0 || mHour == 12) {
      let amPm;
      if (mHour == 0) {
        amPm = "(Midnight)";
      } else if (mHour == 12) {
        amPm = "(Noon)";
      }
      whichTwelve = (
        <>
          <span className="scheduler__timecard__which_twelve">{amPm}</span>
          <br />
        </>
      );
    }   

    return (
      <div className={isSelected}>
        <div className="scheduler__card scheduler__timecard__face">
          <input type="radio" name="schedulerTime" id={this.props.start} value={this.props.start} className="scheduler__card__input" />
          <label htmlFor={this.props.start} className="scheduler__card__label">
            <span className="scheduler__timecard__hour">{this.makeHour(this.props.start)}{this.props.espanol ? '*' : ''}</span>
            {whichTwelve}
            <span className="scheduler__timecard__duration">
              {this.makeDuration(this.props.start, this.props.end)}
            </span>
          </label>
        </div>
        <SchedulerConfirmButton disabled={!this.props.isSelected} /> 
      </div>
    );
  }
}

SchedulerTimeCard.defaultProps = {
  end: new Date(Date.now() + (60 * 60 * 1000)).toString(),
  isSelected: false,
  start: new Date(Date.now()).toString(),
};

SchedulerTimeCard.propTypes = {
  end: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  start: PropTypes.string.isRequired,
};

export default SchedulerTimeCard;
