import React, {Component} from 'react';
import MenuNavBar from '../MenuNavBar';
import visitor_profile from '../VisitorProfile';
import "./css/StaticDocViewer.css";

class StaticDocViewer extends Component {

  constructor(props) {
    super(props);
    this.state = { __html : "<p></p>" };

  }

  componentDidMount() {

    if (sessionStorage.getItem('v')) {
      visitor_profile.recordPath(this.props.path_id);
    }

    
    fetch(`/static/${this.props.name}`, {
        headers: {
          'Cache-Control': 'no-cache' }
        })
      .then(res => {
        if (!res.ok) 
          throw Error(res.statusText); 
        else
          return res;
      })
      .then(resp => {
        // fetch returns a readable stream, so translate it into stringified HTML
        return resp.text();
      })
      .then(content => {
              // dangerouslySetInnerHTML requires using an object with an `__html` key
              this.setState({
                __html: content
              });
            })
      .catch(err => {
        console.log(err); 
        this.setState({__html : '<h2>Oops! Unable to load document, please go back and try again</h2>'}); 
      });
  }

  render() {
    return (
      <div className="page__container">
        <MenuNavBar className="page__navbar" selected={this.props.selected} />
        <div className="static__doc_viewer" dangerouslySetInnerHTML={this.state}></div>
      </div>
    );
  }
}

export default StaticDocViewer; 

