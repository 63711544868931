import { Component } from 'react';
import visitor_profile from '../VisitorProfile';
import PropTypes from 'prop-types';
import analyticsTracker from '../../analytics/AnalyticsTracker';
import webAuth, { AUTH0_CLIENT_ID, REDIRECT_URL, AUTH0_SCOPE, 
    AUTH0_RESPONSETYPE, AUTH0_AUDIENCE } from './tenants';


class AuthLogic extends Component {

    constructor(props) {
        super(props);
    
        this.propTypes = {
          history: PropTypes.object.isRequired,
          location: PropTypes.object.isRequired
        }
    
        this.termsAccepted = this.termsAccepted.bind(this);
    
        this.usePassword = this.usePassword.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);

        this.goToAlternateAuth = this.goToAlternateAuth.bind(this);
    }

    termsAccepted(e) {
        if (e.target.checked) {
          this.setState({acceptedTerms : true});
          visitor_profile.recordVisitorConsent(true);
        }
        else{
          this.setState({acceptedTerms : false});
          visitor_profile.recordVisitorConsent(false);
        }
      }

    getQueryVariable(variable) {
        var query = this.props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }

    handleCodeChange(e) {
        e.preventDefault();
        this.setState({verificationCode: e.target.value});
      }

    usePassword(e) {
        e.preventDefault();
    
        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Auths with password'
        });
    
        webAuth.authorize({
          scope: AUTH0_SCOPE,
          responseType: AUTH0_RESPONSETYPE,
          audience: AUTH0_AUDIENCE,
          clientID:     AUTH0_CLIENT_ID,
          redirectUri:  REDIRECT_URL,
        }, (err,res) => {
          if (err) 
            console.log(err);
        });      
      }
    
      goToAlternateAuth() {
        const { history } = this.props;
        let method = this.props.history.location.pathname === '/auth_phone'? 'email':'sms';

        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Changed authentication method',
          method: method,
          defaultMethod: this.props.mobile_auth ? 'sms':'email',
          pathname: this.props.mobile_auth ? '/auth':'/auth_phone',
        });
    
        history.push({pathname: method === 'email'? '/auth':'/auth_phone' });
    
      }

}

export default AuthLogic;