import React from 'react';
import analyticsTracker from '../../analytics/AnalyticsTracker';
import moment from 'moment-timezone';
import Solution from './Solution';
import { Row, Col } from 'reactstrap';
import SchedulerTimeList from '../containers/SchedulerTimeList';
import HeroListContainer from '../containers/HeroListContainer';
import ContentContainer from '../containers/ContentContainer';
import visitor_profile from '../VisitorProfile';
import AppointmentFormatter from './calendar/AppointmentFormatter';

class SolutionAdviceTime extends Solution {

  constructor(props) {
    super(props);

    this.state = {
      times: [],
      slotSelectedInfo: {},
      date: "No date selected"
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.confirmAppointment = this.confirmAppointment.bind(this);
  }

  componentDidMount() {
    
    super.componentDidMount(); 

    if (this.props.location.state)
    {
      const withIsSelected = this.props.location.state.data.map((item) => {
        item.isSelected = false;
        return item;
      });
      this.setState({times: withIsSelected});
      if (withIsSelected && withIsSelected[0])
      {
        const inputDate = new Date(withIsSelected[0].start);
        this.setState({date: moment(inputDate).format('MMMM D') });
      }
    }
  }
  
  changeHandler(event) {
    const selectedValue = event.target.value;
    const selectedIndex = this.state.times.findIndex((item) => {
      return item.start === selectedValue;
    });

    this.setState((previousState) => {
      const newState = previousState.times.map((item, index) => {
        if(index === selectedIndex) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
        return item;
      });

      newState.slotSelectedInfo = previousState.times[selectedIndex];

      return newState;
    });
  }

  render () {
    return (
      <HeroListContainer {...this.props} contentCssClass="short__background_content" title={this.state.date} subtitle="All calls are 60 minutes long." currentsection="talk">
        <Row className="short__background_content_resources">
          <Col xs="12" sm={{ size: 10, offset: 1 }} >
            <ContentContainer>
              <div className="empty__header">&nbsp;</div>
              <SchedulerTimeList data={this.state.times} changeHandler={this.changeHandler} submitHandler={this.confirmAppointment} />
            </ContentContainer>
          </Col>
        </Row>
      </HeroListContainer>
    );
  }

 /**
 * An appointment has been selected, so let's book this appointment and make it 
 * official. Navigates to the Confirmation Page. 
 *
 * @param {Event} e 
 */
  confirmAppointment(e)
  {
    e.preventDefault();
    const { history } = this.props;

    let bodyObject = { company: visitor_profile.getCompanyName(), dateTime: this.state.slotSelectedInfo };
    // Officially book the appointment
    fetch('/calendar', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + visitor_profile.getJwt(),
        'tEQ-preauthorized': visitor_profile.getPreauthorized()
      },
      body: JSON.stringify(bodyObject)
    })
    .then(res => { 
      if (!res.ok) 
        throw Error(res.statusText); 
      else {
        visitor_profile.recordAppointmentScheduled();
        var appointment = AppointmentFormatter(this.state.slotSelectedInfo);
        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Appointment Scheduled',
          appointmentInfo : this.state.slotSelectedInfo,
          appointment
        });

        // Navigate to the confirmation page and send the currently selected slot 
        // as the date/time confirmed
        history.push({
          pathname: '/outcome/schedule/confirmation',
          state : {date : this.state.slotSelectedInfo} 
        });
        return res;
      }
    })
    .catch(err => {
        console.log(err); 
        alert("Oops! For some reason, the appointment was not booked properly. Please select another time slot and try again.");
    });
  }
}


export default SolutionAdviceTime;

