import React, { Component } from 'react';
import visitor_profile from './VisitorProfile';
import identities from './solutions/Identities';
import { Button, Row, Col, Input } from 'reactstrap';
import TransparentSelectMenu from './components/TransparentSelectMenu';
import './StoryInput.css';
import analyticsTracker from '../analytics/AnalyticsTracker';

class StoryInputStepOne extends Component {

  constructor(props) {
    super(props);
    this.state = {
      departments : [],
      selectedDepartment : this.props.storyData.department ? this.props.storyData.department : '',
      offices : [],
      selectedOffice : this.props.storyData.office ? this.props.storyData.office : '',
      levels : [],
      softer_language : false,
      selectedLevel : this.props.storyData.level ? this.props.storyData.level : '' , 
      selectedRelationship : this.props.storyData.relationship ? this.props.storyData.relationship : '' , 
      selectedBehavior : this.props.storyData.behavior ? this.props.storyData.behavior : '' , 
      selectedPerspective : this.props.storyData.perspective ? this.props.storyData.perspective : '' ,
      selectedIdentities : this.props.storyData.identities ? this.props.storyData.identities : null ,
      hideidentities : this.props.storyData.identities ? false : true,
      hideOtherBehavior : this.props.storyData.other_behavior ? false : true,
      other_behavior : this.props.storyData.other_behavior ? this.props.storyData.other_behavior : '',
      behaviors : [
        {label: 'Exclusion from team activities or social groups', value: 'exclusion'},
        {label: 'Unwanted or stereotypical questions about identity/background', value: 'stereotyping'},
        {label: 'Mistaken identity for someone else of the same race', value: 'racial_bias'},
        {label: "Others taking or receiving credit for someone's work/ideas", value: 'not_getting_credit'},
        {label: 'Assumptions about skills and ability based on identity/background', value: 'identity_bias'},
        {label: 'Comparison to a terrorist in a joking or serious manner', value: 'terrorist_comments'},
        {label: 'Reproaching a person for their family obligations', value: 'family_bias'},
        {label: 'Inappropriate or offensive materials (e.g., photos, websites, email)', value: 'offensive_materials'},
        {label: 'Public humiliation or embarrassment', value: 'humiliation'},
        {label: 'Offensive joke (racist, ethnic, homophobic, sexist, religious, …)', value: 'offensive_jokes'},
        {label: 'Bullying (abusive, threatening and/or coercive behaviors)', value: 'bullying'},
        {label: 'Unwanted sexual attention (pressure for dates, teasing, jokes, remarks or questions, ...)', value: 'sexual_harassment'},
        {label: 'Unwanted physical behavior (touching, leaning over, cornering, or pinching, ...)', value: 'physical_discomfort'},
        {label: 'Physical violence (actual or threats of bodily harm to another)', value: 'physical_violence'},
        {label: 'Other (please specify)',  value: 'other'},
      ],
      relationships : [],
      identities : identities.selectableIdentities()
    };
  
    this.perspectives = [
      {label: 'The situation happened to me', value: 'first_party'},
      {label: 'The situation happened to someone else', value: 'third_party'}
    ];

    this.identityTextHeader = "my";

    this.handleOtherBehaviorChange = this.handleOtherBehaviorChange.bind(this);
    this.selectDeptChange = this.selectDeptChange.bind(this);
    this.selectOfficeChange = this.selectOfficeChange.bind(this);
    this.selectLevelChange = this.selectLevelChange.bind(this);
    this.selectRelationshipChange = this.selectRelationshipChange.bind(this);
    this.selectPerspectiveChange = this.selectPerspectiveChange.bind(this);
    this.selectIdentities = this.selectIdentities.bind(this);
    this.selectBehaviorChange = this.selectBehaviorChange.bind(this);
    this.handleOtherBehaviorChange = this.handleOtherBehaviorChange.bind(this);
    this.jumpToggle = this.jumpToggle.bind(this);
  }

  jumpToggle(e) {
    e.preventDefault();
    if (!this.props.hasVisitorStoryData) {
      this.props.toggleModal('/solutions/learningModulesSearch?category=');
    } else {
      location.href="/#/solutions/learningModulesSearch?category="
    }    
  }
  
  componentDidMount() {
    let selectedDropdowns = visitor_profile.getStoryDropDowns();
    if(selectedDropdowns){
      this.props.setStoryData(true)
      this.setState({ selectedDepartment : selectedDropdowns.department,
        selectedOffice : selectedDropdowns.office,
        selectedLevel : selectedDropdowns.level,
        selectedRelationship : selectedDropdowns.relationship,
        selectedBehavior: selectedDropdowns.behavior,
        selectedPerspective : selectedDropdowns.perspective,
        selectedIdentities : selectedDropdowns.identity,
        other_behavior : selectedDropdowns.other,
        hideOtherBehavior: selectedDropdowns.hideBehaviorType,
        hideidentities: selectedDropdowns.hideIdentities
       });

    }


    // populate the company id and name
    visitor_profile.fetchCompanyInfo("?fields=orginfo")
      .then(() => { 
        const departmentLabels = visitor_profile.getDepartmentLabels();
        const officeLabels = visitor_profile.getOfficeLabels();
        this.setState({
          departments : this.makeOptionsFromStrings(
            visitor_profile.getDepartments(), true, "department", false, 
            departmentLabels.prefix, 
            departmentLabels.suffix),
          offices : this.makeOptionsFromStrings(visitor_profile.getOffices(), false, '', false, officeLabels.prefix, officeLabels.suffix), 
          levels : this.makeOptionsFromStrings(visitor_profile.getLevels(), true, "level", false, 'who is a/an'),
          relationships: this.makeOptionsFromStrings(visitor_profile.getRelationships(), true, "label", false, "and"),
          softer_language : visitor_profile.getSofterLanguage() 
        });

        // Replace the Bullying line item in the list of behaviors with just the
        // description instead of calling it Bullying. Special request from a customer
        // since they define Bullying more broadly. 

        if (visitor_profile.getSofterLanguage() === true) {
          let index = this.state.behaviors.findIndex((item) => { return item.label.search("Bullying") >= 0; });
          const behaviors = this.state.behaviors;
          behaviors[index].label = 'Abusive, threatening and/or coercive behaviors';
        }
      })
      .catch((err) => console.log("Error fetching company info", err));

  }

  componentWillUnmount() {
    if(this.state.selectedOffice ||
      this.state.selectedDepartment || 
      this.state.selectedLevel ||
      this.state.selectedRelationship ||
      this.state.selectedPerspective ||
      this.state.selectedBehavior){

      visitor_profile.setStoryDropDowns({
        perspective: this.state.selectedPerspective,
        department: this.state.selectedDepartment,
        office: this.state.selectedOffice,
        level: this.state.selectedLevel,
        relationship: this.state.selectedRelationship,
        behavior:this.state.selectedBehavior,
        identity: this.state.selectedIdentities,
        other: this.state.other_behavior,
        hideBehaviorType: this.state.hideOtherBehavior,
        hideIdentities: this.state.hideidentities
  
      });

    }

  }

  selectDeptChange(selectedOption) {
    this.setState({ selectedDepartment : selectedOption });
    this.props.updateProps({ selectedDepartment : selectedOption });
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'User Story - Selected department dropdown',
      value: selectedOption.value
    });
  }

  selectOfficeChange(selectedOption) {
    this.setState({ selectedOffice : selectedOption });
    this.props.updateProps({ selectedOffice : selectedOption });
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'User Story - Selected office dropdown',
    });
  }

  selectLevelChange(selectedOption) {
    this.setState({ selectedLevel : selectedOption });
    this.props.updateProps({ selectedLevel : selectedOption });
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'User Story - Selected seniority dropdown',
      value: selectedOption.value
    });
  }

  selectRelationshipChange(selectedOption) {
    this.setState({ selectedRelationship : selectedOption });
    this.props.updateProps({ selectedRelationship : selectedOption });
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'User Story - Selected relationship dropdown',
      value: selectedOption.value
    });
  }

  selectPerspectiveChange(selectedOption) {
    this.setState({ selectedPerspective : selectedOption });
    this.props.updateProps({ selectedPerspective : selectedOption });
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'User Story - Selected perspective dropdown',
      value: selectedOption.value
    });

    const relationships = this.state.relationships; 
    for (var i = 0; i < relationships.length - 1; i++) {
      if (selectedOption.value === 'third_party') {
        relationships[i].label = relationships[i].label.replace('my', 'their');
        this.identityTextHeader = "their"
      } else {
        relationships[i].label = relationships[i].label.replace('their', 'my');
        this.identityTextHeader = "my"
      }
    }
  }

  selectIdentities(selectedOption) {
    this.setState({ selectedIdentities : selectedOption });
    this.props.updateProps({ selectedIdentities : selectedOption });
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'User Story - Selected identities dropdown',
      value: selectedOption.map(i => i.value).join(',')
    });
  }

  selectBehaviorChange(selectedOption) {
    this.setState({ selectedBehavior : selectedOption });
    this.props.updateProps({ selectedBehavior : selectedOption });
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'User Story - Selected behavior dropdown',
      value: selectedOption.value
    });
    if ((selectedOption && selectedOption.value === 'other')) 
    {
      this.setState({ hideOtherBehavior : false });
      this.setState({ hideidentities : true });
      
      // This removes everthing from the selectedIdentities field incase someone entered something and changed their mind.
      this.setState({ selectedIdentities : null });
      this.props.updateProps({ selectedIdentities : null });
    } 
    else if (selectedOption && (selectedOption.value === 'exclusion' || selectedOption.value === 'stereotyping' || selectedOption.value === 'identity_bias' || selectedOption.value === 'offensive_jokes'))
    {
      this.setState({ hideOtherBehavior : true });
      this.setState({ hideidentities : false });
      
      // This removes everthing from the other_behavior field incase someone entered something and changed their mind.
      this.setState({ other_behavior : '' });
      this.props.updateProps({ other_behavior : '' });
    }
    else 
    {
      this.setState({ hideOtherBehavior : true });
      this.setState({ hideidentities : true });

      // This removes everthing from other_behavior and selectedIdentities fields incase someone entered something and changed their mind.
      this.setState({ other_behavior : '' });
      this.props.updateProps({ other_behavior : '' });
      this.setState({ selectedIdentities : null });
      this.props.updateProps({ selectedIdentities : null });
    }
  }

  handleOtherBehaviorChange(e) {
    this.setState({other_behavior : e.target.value});
    this.props.updateProps({other_behavior : e.target.value});
  }

   /*
    Takes the list of strings and creates an array of
    {label: '', value: ''} objects where the string is 
    both label and value
  */
  makeOptionsFromStrings(arrayOfOptions, hasMapping = false, labelName = "", 
    sort = false, prefix="", suffix="") {
    const options = arrayOfOptions.map((item) => {
      let pfix = typeof item === "object" && "prefix" in item ? `${item["prefix"]} ` : `${prefix} `;
      let sfix = typeof item === "object" && "suffix" in item ? ` ${item["suffix"]}` : ` ${suffix}`;
      if (hasMapping) {
        let optionPair = {
          label: pfix + item[labelName] + sfix,
          plainLabel: item[labelName],
          value: item["mapping"]
        };
        return optionPair;
      } else {
        return {
          label: pfix + item + sfix,
          plainLabel: item[labelName],
          value: item
        };
      }
    });

    // sort in alphabetical order
    if (sort === true) 
      options.sort(function(a, b){return a.label > b.label});

    return options;
  }

  trackDropdownClicked(id) {
    analyticsTracker.trackEvent({
      category: "User",
      action: "Story Input Dropdown Clicked",
      id
    });
  }

  render() {
    let identitesText;
    const departmentLabels = visitor_profile.getDepartmentLabels();
    const officeLabels = visitor_profile.getOfficeLabels();
    if (!this.state.hideidentities) {
      identitesText = (
        <Row>
        <Col xs="12" md={{ size: 10, offset: 1}}><p className="story__input_letter_text">{`Based on ${this.identityTextHeader}`}:</p></Col>
        <Col xs="12" md={{ size: 10, offset: 1}} className="story__input_border">
          <TransparentSelectMenu changeHandler={this.selectIdentities}
            id="identities"
            name="identities"
            options={this.state.identities}
            value={this.state.selectedIdentities}
            isMulti={true}
            hidden={this.state.hideidentities}
            ariaLabel="Based on my"
            placeholder="Select all that apply"
          />
        </Col>
        </Row>
      );
    } else {
      identitesText = "";
    }
    return (
            <Row>
                <Col xs="12">
                  <Row className="d-none d-md-flex">
                  <Col xs={{size:10, offset:1}} sm={{size: 10, offset: 1}}>
                    <p style={{fontWeight: 700}}>If there was a specific interaction, please share more context with us.</p>
                    <br/>
                  </Col>
                  <Col xs="12" sm={{size:10, offset:1}} md="5">
                    <TransparentSelectMenu changeHandler={this.selectPerspectiveChange}
                        id="perspective"
                        name="perspective"
                        options={this.perspectives}
                        value={this.state.selectedPerspective}
                        clearable={true}
                        ariaLabel="The situation happened to... "
                        placeholder="The situation happened to... "
                        onClick={() => this.trackDropdownClicked("perspective")}
                      />
                    <br/>
                    <TransparentSelectMenu changeHandler={this.selectLevelChange}
                        id="levels"
                        name="levels"
                        options={this.state.levels}
                        value={this.state.selectedLevel}
                        clearable={true}
                        ariaLabel="What is their role?"
                        placeholder="What is their role?"
                        onClick={() => this.trackDropdownClicked("levels")}
                      />
                    <br/>
                      <TransparentSelectMenu changeHandler={this.selectOfficeChange}
                        id="offices"
                        name="offices"
                        options={this.state.offices}
                        value={this.state.selectedOffice}
                        clearable={true}
                        ariaLabel={officeLabels.question}
                        placeholder={officeLabels.question}
                        onClick={() => this.trackDropdownClicked("offices")}
                      />
                      <br/>
                  </Col>
                  <Col xs="12" sm="10" md="5">
                    <TransparentSelectMenu changeHandler={this.selectRelationshipChange}
                        id="relationships"
                        name="relationships"
                        options={this.state.relationships}
                        value={this.state.selectedRelationship}
                        clearable={true}
                        ariaLabel="Who was the other party involved?"
                        placeholder="Who was the other party involved?"
                        onClick={() => this.trackDropdownClicked("relationships")}
                      />
                      <br/>
                      <TransparentSelectMenu changeHandler={this.selectDeptChange}
                        id="departments"
                        name="departments"
                        options={this.state.departments}
                        value={this.state.selectedDepartment}
                        clearable={true}
                        ariaLabel={departmentLabels.question}
                        placeholder={departmentLabels.question}
                        onClick={() => this.trackDropdownClicked("departments")}
                      />
                      <br/>
                      <TransparentSelectMenu changeHandler={this.selectBehaviorChange}
                        id="behaviors"
                        name="behaviors"
                        options={this.state.behaviors}
                        value={this.state.selectedBehavior}
                        clearable={true}
                        ariaLabel="I considered the interaction to be:"
                        placeholder="I considered the interaction to be:"
                        onClick={() => this.trackDropdownClicked("behaviors")}
                      />
                  </Col>
                  {this.state.hideOtherBehavior ? '' : <Col xs={{size:10, offset:1}} className="story__input_border">
                        <Input 
                          className="storyinput__otherbehavior"
                          name="otherBehaviorInput"
                          hidden={this.state.hideOtherBehavior}
                          onChange={this.handleOtherBehaviorChange} 
                          value={this.state.other_behavior} 
                          placeholder="Please specify behavior"
                        />
                    </Col>}
                  </Row>
                  <Row className="d-flex d-md-none">
                  <Col xs={{size:10, offset:0}}>
                    <p style={{ fontWeight: 700}}>If there was a specific interaction, please share more context with us.</p>
                    <br/>
                  </Col>
                  <Col xs="12" sm={{size:10, offset:1}} md="5">
                    <TransparentSelectMenu changeHandler={this.selectPerspectiveChange}
                        id="perspective"
                        name="perspective"
                        options={this.perspectives}
                        value={this.state.selectedPerspective}
                        clearable={true}
                        ariaLabel="The situation happened to... "
                        placeholder="The situation happened to... "
                        onClick={() => this.trackDropdownClicked("perspective")}
                      />
                    <br/>
                    <TransparentSelectMenu changeHandler={this.selectRelationshipChange}
                        id="relationships"
                        name="relationships"
                        options={this.state.relationships}
                        value={this.state.selectedRelationship}
                        clearable={true}
                        ariaLabel="Who was the other party involved?"
                        placeholder="Who was the other party involved?"
                        onClick={() => this.trackDropdownClicked("relationships")}
                      />
                    <br/>
                    <TransparentSelectMenu changeHandler={this.selectLevelChange}
                        id="levels"
                        name="levels"
                        options={this.state.levels}
                        value={this.state.selectedLevel}
                        clearable={true}
                        ariaLabel="What is their role?"
                        placeholder="What is their role?"
                        onClick={() => this.trackDropdownClicked("levels")}
                      />
                    <br/>
                    <TransparentSelectMenu changeHandler={this.selectDeptChange}
                        id="departments"
                        name="departments"
                        options={this.state.departments}
                        value={this.state.selectedDepartment}
                        clearable={true}
                        ariaLabel={departmentLabels.question}
                        placeholder={departmentLabels.question}
                        onClick={() => this.trackDropdownClicked("departments")}
                      />
                    <br/>
                      <TransparentSelectMenu changeHandler={this.selectOfficeChange}
                        id="offices"
                        name="offices"
                        options={this.state.offices}
                        value={this.state.selectedOffice}
                        clearable={true}
                        ariaLabel={officeLabels.question}
                        placeholder={officeLabels.question}
                        onClick={() => this.trackDropdownClicked("offices")}
                      />
                      <br/>
                      <TransparentSelectMenu changeHandler={this.selectBehaviorChange}
                        id="behaviors"
                        name="behaviors"
                        options={this.state.behaviors}
                        value={this.state.selectedBehavior}
                        clearable={true}
                        ariaLabel="I considered the interaction to be:"
                        placeholder="I considered the interaction to be:"
                        onClick={() => this.trackDropdownClicked("behaviors")}
                      />
                  </Col>
                  { this.state.hideOtherBehavior ? '' : <Col xs="12" md={{size:10, offset:1}} className="story__input_border">
                        <Input 
                          className="storyinput__otherbehavior"
                          name="otherBehaviorInput"
                          hidden={this.state.hideOtherBehavior}
                          onChange={this.handleOtherBehaviorChange} 
                          value={this.state.other_behavior} 
                          placeholder="Please specify behavior"
                        />
                        </Col>
                  }
                  </Row>
                  {identitesText}
                  <Row className="storyinput__buttons"> 
                    <Col className="d-none d-md-block" md={{size: 7, offset:1}} lg={{size: 6, offset: 1}} xl={{size: 5}}>
                      <Button className="button__tertiary" onClick={this.jumpToggle}>Nothing happened? Jump to trainings</Button> 
                    </Col>
                    <Col xs="12" sm={{size:6, offset:3}}  md={{size: 3, offset:0}} lg={{size: 2, offset:2}} xl={{size: 3, offset:2}}>
                      <Button id="storyinput__step_submit" className="button__primary" onClick={this.props.saveStory}>CONTINUE</Button>
                    </Col>
                    <Col className="d-md-none" xs="12" sm={{size:8, offset:2}} md={{size: 6, offset:3}}>
                      <Button className="button__tertiary" onClick={this.jumpToggle}>Nothing happened?<br/>Jump to trainings</Button>
                    </Col>
                  </Row>
                </Col>
                
              </Row>
      );
  }
}

export default StoryInputStepOne;