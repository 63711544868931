import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import './css/SchedulerCard.css';

class SchedulerDateCard extends Component {
  constructor(props) {
    super(props);
    this.makeDate = this.makeDate.bind(this);
  }
  
  makeDate(timeStamp){
    const inputDate = new Date(timeStamp);
    return {
      dayName: moment(inputDate).format('dddd'),
      dateString: moment(inputDate).format('MMMM D, YYYY'),
    };
  }
  
	render() {
	  const dateData = this.makeDate(this.props.date);
	  const slotsAvailable = !!this.props.availableSlots ? '' : 'un';
		const espanol = this.props.slots ? this.props.slots.find(s => s.espanol) : false;

	  const availClass = classNames({
	    'scheduler__datecard__available': true,
	    'scheduler__datecard__unavailable': !this.props.availableSlots
	  })

    const dayAvailClass = classNames({
      'scheduler__datecard__dayname': true,
      'scheduler__datecard__dayname__unavailable': !this.props.availableSlots
    })
	  
	  return (
			<div className="scheduler__card scheduler__datecard">
			  <input type="radio" name="schedulerDate" id={this.props.date} value={this.props.date} disabled={!this.props.availableSlots} className="scheduler__card__input" />
			  <label htmlFor={this.props.date} className="scheduler__card__label scheduler__datecard__label">
			    <span className="scheduler__datecard__day">
			      <b className={dayAvailClass}>{dateData.dayName}</b>
			      <i className="scheduler__datecard__fullday">{dateData.dateString}</i>
			    </span>
			    <span className={availClass}>
			      {slotsAvailable}available {espanol ? '*' : ''}
			    </span>
			  </label>
			</div>
		);
	}
}

SchedulerDateCard.defaultProps = {
  date: new Date().getTime(), // DateTime string
  availableSlots: 0,
};

SchedulerDateCard.propTypes = {
  date: PropTypes.string, // DateTime string
  availableSlots: PropTypes.number.isRequired,
};

export default SchedulerDateCard;
