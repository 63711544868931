import React, { Component } from 'react';
import visitor_profile from '../VisitorProfile';
import PropTypes from 'prop-types';
import analyticsTracker from '../../analytics/AnalyticsTracker';
import { Jumbotron, Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import "./EmailAuth.css";

import webAuth, { AUTH0_CLIENT_ID, REDIRECT_URL, AUTH0_SCOPE, 
  AUTH0_RESPONSETYPE, AUTH0_AUDIENCE } from './tenants';
import AuthLogic from './AuthLogic';

class PhoneAuth extends AuthLogic {

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber : "", 
      notice : "", 
      validPhone : false, 
      prePhone : true, 
      acceptedTerms : false,
      verificationCode: '',
      invalidCode: false
    };

    this.propTypes = {
      history: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired
    }

    this.login = this.login.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);

    this.verifyCode = this.verifyCode.bind(this);
    this.reset = this.reset.bind(this);
    this.goToAlternateAuth = this.goToAlternateAuth.bind(this);
  }

  

  componentDidMount() {
    visitor_profile.recordPath(this.props.path_id);

  }

  login(e) { 
    e.preventDefault();

    if (this.state.phoneNumber !== "") {
    
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Sends one-time auth code',
        method: 'sms'
      });

      webAuth.passwordlessStart({
          connection: 'sms',
          send: 'code',
          phoneNumber: this.state.phoneNumber
        }, (err,res) => {
          if (err) {
            this.setState({
              notice: REDIRECT_URL + " Authentication failed: " + JSON.stringify(res),
              phoneNumber: ''
            })
          } else {
            visitor_profile.rememberVisitor("sms|" + res.Id, 
              this.getQueryVariable('redirect_to'));
            this.setState({ 
              prePhone : false, 
              invalidCode: false
            });
          }
        }
      );
    }
  }

  handlePhoneChange(
    isValid,
    _newNumber,
    _selectedCountryData,
    fullNumber,
    _extension) {
    this.setState({
      phoneNumber: fullNumber.replace(/\s/g, '').replace(/-/g, ''),
      validPhone : isValid
    });

    if (!isValid) {
      this.setState({ notice : "This does not appear to be a valid phone number"});
    } else {
      this.setState({ notice : "" });
    }
  }

  verifyCode(e) {
    e.preventDefault();
    const { history } = this.props;
    const self = this;

    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Auths with code',
      method: 'sms'
    });

    webAuth.passwordlessLogin({
      connection: 'sms',
      phoneNumber: this.state.phoneNumber,
      verificationCode: this.state.verificationCode
    }, function (err,authResult) {
        // NOTE: This code never runs as far as I can tell
        // See EmailVerify.js for the real handler
        if (!err) {
          
          visitor_profile.setIsAuthenticated(authResult.accessToken);
          visitor_profile.setJwt(authResult.accessToken);

          visitor_profile.linkVisitor(authResult.idTokenPayload.sub)
            .then( success => {
              // If we are able to match the hash we will send them to 
              // the first step of data entry. This is a replace instead 
              // of a push so that a visitor can never go "back" to auth verify
              analyticsTracker.trackEvent({
                category: 'User',
                action: 'Successful Auth',
                success
              });
              history.replace({ pathname: success ? success : '/storyinput'});
            }, error => {
              // If we are not able to match the hash we need to push 
              // the user back to the home page because we won't have 
              // properly recorded consent 
              analyticsTracker.trackEvent({
                category: 'User',
                action: 'Auth Failed',
                error
              });
              history.push({ pathname: '/'});
            });
        } else {
          analyticsTracker.trackEvent({
            category: 'User',
            action: 'Auth Error - Invalid Code',
            err
          });
          self.setState({verificationCode: '', invalidCode: true});
        }
      }
    );
  }

  reset(e) {
    e.preventDefault();
    this.setState({
      prePhone: true,
      verificationCode: '',
      notice: ''
    });
  }

  render () {
    return (
      <div className="page__container">
      <Jumbotron className="big__background_image">
          <Form className="authentication__container">
            <FormGroup>
              <Container>
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:11, offset:1}} lg={{size:8, offset:1}}>
                {window.location.hostname.includes('-demo') ? <h1>This is a Demo</h1> : ''}
                <h1>{this.state.invalidCode ? "Oops! Your verification code didn’t work" : this.state.prePhone && !(this.props.location.search === "?expired=true") ? "We protect your identity" : this.props.location.search === "?expired=true" ? "Your session has expired due to inactivity" : "Please enter your verification code" } </h1>
                {this.state.prePhone &&
                  <ul className="authentication__info">
                    { this.props.location.search === "?expired=true" && <li>Please generate a new verification code to access the platform</li>}
                    <li>Please provide your mobile phone number</li>
                    <li>We don’t keep your phone number or create a user account for you</li>
                    <li>We send you a verification code via SMS text message</li>
                  </ul>
                }
                </Col>
              </Row>
              {!this.state.prePhone && !this.state.invalidCode && 
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:8, offset:1}}>
                  <p className="authentication__info">Use the code sent to your mobile phone number.</p>
                  <Input autoFocus type="text" name="code" onChange={this.handleCodeChange} value={this.state.verificationCode} placeholder="Enter verification code" />
                  <div className="authentication__error"> 
                      {this.state.notice}
                      <img src="/icons/warning.svg" hidden={!this.state.notice} alt="invalid verification code" align="right" />
                  </div>
                  <div className="terms__link">
                    <Input type="checkbox" id="terms" onChange={this.termsAccepted} checked={this.state.acceptedTerms} /><label htmlFor="terms">I agree to the <a href="/#/legal/terms_of_use" target="_blank">Terms</a> and <a href="/#/legal/privacy_policy" target="_blank">Privacy Policy</a></label>
                  </div>
                  <Button type="submit" className="button__primary" onClick={this.verifyCode} disabled={this.state.verificationCode.length < 6 || !this.state.acceptedTerms}>CONTINUE</Button>
                  <p className="authentication__info"><a href="/#/auth" onClick={this.reset}>Click here</a> if you need a new code.</p>
                </Col>
              </Row>
              }
              {this.state.prePhone && 
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:8, offset:1}}>
                  <IntlTelInput data-1p-ignore
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    onPhoneNumberChange={this.handlePhoneChange} />
                  <div className="authentication__error"> 
                      {this.state.notice}
                      <img src="/icons/warning.svg"  hidden={this.state.notice == ""} alt="invalid phone number" align="right" />
                  </div>
                  <Button type="submit" className="button__primary" onClick={this.login}  disabled={!this.state.validPhone}>SEND MY VERIFICATION CODE</Button>
                  { (visitor_profile.getActive() === false) && 
                      <Button id="passwordBtn" className="button__tertiary" onClick={this.usePassword}>...</Button>
                  }
                  <Row><Button type="submit" className="button__link d-none d-lg-block" onClick={this.goToAlternateAuth}>I prefer to verify via email. Email me the code instead.</Button>
                  <Button type="submit" className="button__link d-block d-lg-none" onClick={this.goToAlternateAuth}>I prefer to verify via email. <br/> Email me the code instead.</Button>
                  </Row>
                  

                  <p className="authentication__confidence">
                    Note: Message and data rates may apply.<br/>
                    We don’t retain your phone number or any other personal information.
                  </p>
                </Col>
              </Row>
              }
              {this.state.invalidCode && 
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:8, offset:1}}>
                  <p className="authentication__info">The verification code you entered is either incorrect, expired or has already been used once.</p>
                  <p className="authentication__info">Please provide your mobile phone number and we’ll send you a new verification code via SMS text message.</p>
                  <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    onPhoneNumberChange={this.handlePhoneChange} />
                  <div className="authentication__error"> 
                      {this.state.notice}
                      <img src="/icons/warning.svg"  hidden={this.state.validPhone} alt="invalid phone number" align="right" />
                  </div>
                  <Button type="submit" className="button__primary" onClick={this.login} disabled={!this.state.validPhone}>SEND MY NEW VERIFICATION CODE</Button>
                  <Row><Button type="submit" className="button__link d-none d-lg-block" onClick={this.goToAlternateAuth}>I prefer to verify via email. Email me the code instead.</Button>
                       <Button type="submit" className="button__link d-block d-lg-none" onClick={this.goToAlternateAuth}>I prefer to verify via email. <br/> Email me the code instead.</Button>
                  </Row>
                  <p className="authentication__confidence">
                    Note: Message and data rates may apply.<br/>
                    We don’t retain your phone number or any other personal information.
                  </p>
                </Col>
              </Row>
              }
              </Container>
            </FormGroup>
          </Form>
      </Jumbotron>
      </div>
    );
  }
}

export default PhoneAuth;