import React from 'react';
import {
	Link
} from 'react-router-dom';
import Solution from './Solution';
import BoxDocumentViewer from '../components/BoxDocumentViewer';
import CheckIn from '../components/CheckIn';
import HeroListContainer from '../containers/HeroListContainer';
import ContentContainer from '../containers/ContentContainer';
import DoMoreButton from '../components/DoMoreButton';
import { Row, Col } from 'reactstrap';
import visitor_profile from '../VisitorProfile';
import "./Solutions.css";
import analyticsTracker from '../../analytics/AnalyticsTracker';

class SolutionPolicies extends Solution {

	state = { policies : [], selected : { file_id : 0, title : "", url : "" }, clickedALink : false };

  constructor(props) {
    super(props);

    this.makePolicies = this.makePolicies.bind(this);
    this.renderDoc = this.renderDoc.bind(this);
  }

	componentDidMount() {
		super.componentDidMount();

    visitor_profile.fetchCompanyInfo("?fields=policies")
      .then((data) => { this.setState({policies : data.policies}); });
	}

  renderDoc(e) {
    e.preventDefault(); 

    var item = this.state.policies.filter(item => {return (item.title === e.target.title) });

    analyticsTracker.trackEvent({
      category: "User",
      action: "Viewed Document",
      item
    });
    
    // If we have a Box file id, open it in the Box renderer
    if (item[0].file_id > 0)
      this.setState({ selected : { file_id : item[0].file_id, title : item[0].title } });
    // Otherwise, if it's a URL, then open it in a new tab
    else if (item[0].url !== "")
      window.open(item[0].url, "_blank"); 
    this.setState({ clickedALink : true });
  }

  makePolicies() {
    if (this.state.policies)
      return this.state.policies.map((doc, index)=> { 
        return  <div key={index}>
                  <li><Link onClick={this.renderDoc} to="/" title={doc.title} key={index}>{doc.title}</Link></li>
                </div> });
    else
      return "";
  }

	render() {
		return (
      <HeroListContainer {...this.props} contentCssClass="short__background_content learning__module_border" title={`Review ${visitor_profile.getOrgType().charAt(0).toUpperCase() + visitor_profile.getOrgType().slice(1)} Resources`} subtitle={`Explore your ${visitor_profile.getOrgType()}'s policies and documents`} currentsection="outcomes">
        <Row className="short__background_content_resources">
          <Col xs="12" sm={{ size: 10, offset: 1 }} >
            <ContentContainer cssClass="learning__module">
              <Row>
                <Col xs="12" sm={{size:10, offset: 1}}>
                  <div className="learning__module_section">
                    <h2>{visitor_profile.getCompanyName()} policies and documents</h2>
                    {this.makePolicies()}
                    <BoxDocumentViewer file_id={this.state.selected.file_id} title={this.state.selected.title} />
                  </div>
                </Col>
              </Row>
              { this.state.clickedALink && 
              <Row>
                <Col xs="12" sm={{size:10, offset: 1}} lg={{size:9, offset: 1}} xl={{size:7, offset: 1}}>
                  <CheckIn
                  questions={[{question:'Was this helpful for you?', topic:'relevance'}, {question:'How confident are you in your next step to resolve the incident (including taking no action)?', topic:'confidence'}]}
                  moreLinkText="" /> 
                </Col> 
              </Row>
              }
            </ContentContainer>

            <DoMoreButton route="/outcome/options_validation" {...this.props} />

          </Col>
        </Row>
      </HeroListContainer>
		);
	}

}

export default SolutionPolicies; 