import React, { Component } from 'react';
import visitor_profile from '../../VisitorProfile';
import { Container, Row, Col, Jumbotron, Badge } from 'reactstrap';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import ContentContainer from '../../containers/ContentContainer';
import CheckIn from './../../components/CheckIn';
import BackButton from './../../components/BackButton';
import RelatedActionsGroup from './../../containers/RelatedActionsGroup';
import MenuNavBar from '../../MenuNavBar';
import identities from '../Identities';
import './StoryList.css';

import analyticsTracker from '../../../analytics/AnalyticsTracker';
import handleExpiredTokens from '../../../utils/jwtErrorHandler'

class StoryPage extends Component {


  constructor(props) {
    super(props);

    this.state = { title: "", fulltext : "", topics : [], links : [], 
    storyLoaded : false };

    this.topicLabels = [];

    this.searchAgain = this.searchAgain.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  render() {
    // Add classes for each topic which may change color scheme
    const containerClassNames = this.state.topics ? this.state.topics.reduce((acc, t) => {
      return acc + ' topic-' + t.toLowerCase()
    }, 'story__item_background') : 'story__item_background';

    return(
    <div className="page__container">
      <ScrollToTopOnMount/>
      <MenuNavBar className="page__navbar" selected="outcomes" />
      <Jumbotron className="story__container" fluid>
        <Container fluid>
          <Row>
            <Col xs="0" sm={{ size: 1, offset: 0 }} >
              <BackButton {...this.props}/>
            </Col>
            <Col xs="12" sm={{ size: 10, offset: 0 }} >
            <ContentContainer cssClass={containerClassNames}>
              <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} className="content_loading_filler">
                  <div className="story__item_title">
                    <h1>{this.state.title}</h1>
                    <div className="story__summary_badges story__item_badges">
                      {this.state.topics && 
                        this.state.topics.map((badgeitem, index) => 
                          <a href={`#/solutions/storiesSearch?topics=${badgeitem.toLowerCase()}&subtopics=`}>
                            <Badge className="topicBadge" key={index} pill>{badgeitem}</Badge>
                          </a>)}
                    </div>
                  </div>
                  <div className="story__item_text" dangerouslySetInnerHTML={{__html: this.state.fulltext}}></div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm={{size:10, offset: 1}} lg={{size:9, offset: 1}} xl={{size:7, offset: 1}}>
                  { this.state.storyLoaded && 
                  <CheckIn
                    questions={[{question:'Did this story resonate with you?', topic:'relevance'}, {question:'How confident are you in your next step to resolve the incident (including taking no action)?', topic:'confidence'}]}
                    moreLinkText="" />
                  }
                </Col>
              </Row>
            </ContentContainer>

            {this.state.links && this.state.links.length > 0 && 
              <RelatedActionsGroup title="Ready to take action?" links={this.state.links} />
            }
            {this.state.storyLoaded && this.state.topics &&
              <Row className="story__topic_links">
                <Col xs="12">
                  <span>Read more stories with these topics</span>
                  <br className="d-md-none"/>
                  {this.state.topics.map((badgeitem, index) => <a href={`#/solutions/storiesSearch?topics=${badgeitem.toLowerCase()}&subtopics=`}><Badge className="topicBadge" key={index} pill>{badgeitem}</Badge></a>)}
                </Col>
              </Row>}
            </Col> 
          </Row>
          <Row className="story__visit_faq">
                <Col xs="12">
                  <div>Visit our <a href="#/faq">FAQ</a> to learn more about tEQuitable</div>
                  <br/>
            <div>If there is a particular incident you’d like to work through, visit us anytime at <a href={window.location.origin}>{window.location.hostname}</a></div>
                </Col>
              </Row>
        </Container>
      </Jumbotron>
    </div>
    );
  }

  goBack(e) {
    e.preventDefault(); 

    window.history.go(-1);
  }

  searchAgain(e) {
    e.preventDefault(); 

    const { history } = this.props;

    // Go back one to the list of stories 
    history.go(-1);
  }

  loadContent(){
    let storyID = window.location.href.slice(window.location.href.lastIndexOf('/')+1);
   return fetch(this.props.endpoint + '/' + storyID, {
      method: 'GET',
      headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + visitor_profile.getJwt(),
       'tEQ-preauthorized': visitor_profile.getPreauthorized()
     }
    })
    .then(res => { 
      if (!res.ok) {
        handleExpiredTokens(res)
        throw Error(res.statusText); 
      } else
        return res;
    })
    .then(res => res.json())
    .then(data => { 
      this.setState({ title : data.title, 
                      fulltext : data.story_text, 
                      topics : identities.getFilterLabelsForTopics(data.topic),
                      links : data.actions ? ['solution_stories_search', ...data.actions] : ['solution_stories_search'],
                      storyLoaded : true });
      visitor_profile.recordPath(this.props.path_id, storyID);
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Viewed a Story',
        pathId: this.props.path_id,
        storyID,
        topics: this.state.topics,
        title : this.state.title
      });
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Reached Leaf Node',
        pathId: this.props.path_id,
        storyID,
        topics: this.state.topics,
        title : this.state.title
      });
    })
    .catch(err => {
      this.setState({title: "Oops! We were unable to load this story", fulltext : "<p>Please try again or explore other stories</p>", storyLoaded : false });
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Story failed to load',
        pathId: this.props.path_id,
        storyID,
        err
      });
    });
  }

 	componentDidMount() {
     this.loadContent();
   }   
}

export default StoryPage; 
