import React, { Component } from "react";
import visitor_profile from "../VisitorProfile";
import analyticsTracker from "../../analytics/AnalyticsTracker";
import {
  Row,
  Col,
  Jumbotron,
  Button,
  FormGroup,
  Input,
  FormText,
} from "reactstrap";
import $ from "jquery";
import classnames from "classnames";
import { Animated } from "react-animated-css";
import Clipboard from "react-clipboard.js";
import MultiSelectControl from "../components/MultiSelectControl";

class InteractiveConvoStarter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      activeQuestion: 0,
      convoDetails: {
        situation_details: "",
        people_around: "",
        initiator_behavior: "I saw/heard/observed... ",
        feelings: "",
        selectedFeelingsFilters: "",
        hideFeelingsOther: true,
        otherFeelings: "",
        explain_feelings: "because I ",
        desired_outcomes: "I would like ",
        convo_output: "",
      },
      isCopied: false,
    };

    this.confirmCopiedText = this.confirmCopiedText.bind(this);
    this.moveToNextSection = this.moveToNextSection.bind(this);
    this.moveToNextQuestion = this.moveToNextQuestion.bind(this);
    this.goBackToStartPage = this.goBackToStartPage.bind(this);
    this.scrollElementIntoView = this.scrollElementIntoView.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.feelingsOptionsChanged = this.feelingsOptionsChanged.bind(this);
    this.buildOutput = this.buildOutput.bind(this);
    this.sendEnteredDataToDB = this.sendEnteredDataToDB.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  confirmCopiedText() {
    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  }

  moveToNextSection() {
    this.setState({ activeTab: this.state.activeTab + 1 });

    if (this.state.activeQuestion === 0) {
      this.moveToNextQuestion();

      // This will track when a user begins the exercise
      analyticsTracker.trackEvent({
        category: "InteractiveConvoStarter",
        action: "Get started clicked",
      });
    }

    if (this.state.activeTab === 2) {
      this.buildOutput();

      // This will track when the user generates their output
      analyticsTracker.trackEvent({
        category: "InteractiveConvoStarter",
        action: "Get Your Conversation clicked",
      });
    }
  }

  moveToNextQuestion() {
    this.setState({ activeQuestion: this.state.activeQuestion + 1 });

    if (this.state.activeQuestion > 0) {
      // This will track when the user completes a question
      analyticsTracker.trackEvent({
        category: "InteractiveConvoStarter",
        action: `Question ${this.state.activeQuestion} completed`,
      });
    }

    setTimeout(() => {
      this.scrollElementIntoView();
    }, 200);
  }

  goBackToStartPage() {
    this.setState({ activeTab: 1 });

    // This will track when the user needs to go back to the start page
    analyticsTracker.trackEvent({
      category: "InteractiveConvoStarter",
      action: "Back To Start Page",
    });
  }

  scrollElementIntoView() {
    const myElement = document.getElementById(
      `question-${this.state.activeQuestion}`
    );
    let topPos;
    if (this.state.activeQuestion === 4) {
      topPos = myElement.offsetTop - 190;
    } else {
      topPos = myElement.offsetTop - myElement.offsetHeight;
    }
    const $target = $("#interactive-wrapper");
    $target.animate({ scrollTop: topPos }, 500);
  }

  handleInputChange(e) {
    const convoState = { ...this.state.convoDetails };
    convoState[e.target.name] = e.target.value;
    this.setState({ convoDetails: convoState });
  }

  feelingsOptionsChanged(e, selectedValues) {
    if (selectedValues) {
      const convoState = { ...this.state.convoDetails };
      convoState.feelings = selectedValues;
      this.setState({ convoDetails: convoState });
    }

    if (selectedValues.includes("other")) {
      const convoState = { ...this.state.convoDetails };
      convoState.hideFeelingsOther = !this.state.convoDetails.hideFeelingsOther;
      this.setState({ convoDetails: convoState });
    }
  }

  buildOutput() {
    const userOutput = `Hey there, I’d like to chat about something that affected me. ${this.state.convoDetails.situation_details}. The people who were there were ${this.state.convoDetails.people_around}. ${this.state.convoDetails.initiator_behavior}. I felt ${this.state.convoDetails.feelings}. I felt this way ${this.state.convoDetails.explain_feelings}. In the future, ${this.state.convoDetails.desired_outcomes}.`;
    const convoState = { ...this.state.convoDetails };
    convoState.convo_output = userOutput;
    this.setState({ convoDetails: convoState });

    this.sendEnteredDataToDB();
  }

  sendEnteredDataToDB() {
    visitor_profile.recordInteractiveData("convo_starter", {
      situation_details: this.state.convoDetails.situation_details,
      people_around: this.state.convoDetails.people_around,
      initiator_behavior: this.state.convoDetails.initiator_behavior,
      feelings: this.state.convoDetails.feelings,
      explain_feelings: this.state.convoDetails.explain_feelings,
      desired_outcomes: this.state.convoDetails.desired_outcomes,
    });
  }

  onSuccess() {
    // This will track when the user copies the output to their clipboard
    analyticsTracker.trackEvent({
      category: "InteractiveConvoStarter",
      action: "Convo starter successfully copied",
    });
    this.confirmCopiedText();
  }

  render() {
    const feelingsMap = [
      { label: "Angry", value: " angry" },
      { label: "Frustrated", value: " frustrated" },
      { label: "Violated", value: " violated" },
      { label: "Miserable", value: " miserable" },
      { label: "Insecure", value: " insecure" },
      { label: "Anxious", value: " anxious" },
      { label: "Ignored", value: " ignored" },
      { label: "Shocked", value: " shocked" },
      { label: "Uncomfortable", value: " uncomfortable" },
      { label: "Threatened", value: " threatened" },
      { label: "Embarrassed", value: " embarrassed" },
      { label: "Afraid", value: " afraid" },
      { label: "Inferior", value: " inferior" },
      { label: "Discouraged", value: " discouraged" },
      { label: "Add your own", value: " other" },
    ];

    let ProgressButton;

    if (
      this.state.activeTab === 1 ||
      (this.state.activeTab === 2 && this.state.activeQuestion === 0)
    ) {
      ProgressButton = (
        <div className="text-right button-container">
          <Row className="storyinput__buttons">
            <Col
              xs="12"
              sm={{ size: 5, offset: 6 }}
              md={{ size: 4, offset: 4 }}
              lg={{ size: 3, offset: 9 }}
            >
              <Button
                id="btn-start"
                className="button__primary"
                onClick={this.moveToNextSection}
              >
                Get Started
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
    // If we are on the second page, but not all of the questions are completed, we should see a button that progresses through the questions.
    else if (this.state.activeTab !== 3 && this.state.activeQuestion < 6) {
      ProgressButton = (
        <div className="text-right button-container">
          <Row className="storyinput__buttons">
            <Col className="d-none d-lg-block" lg={{ size: 3, offset: 0 }}>
              <Button
                id="btn-back-lg"
                className="button__tertiary"
                onClick={this.goBackToStartPage}
              >
                Back to Instructions
              </Button>
            </Col>
            <Col
              xs="12"
              sm={{ size: 5, offset: 6 }}
              md={{ size: 4, offset: 4 }}
              lg={{ size: 3, offset: 6 }}
            >
              <Button
                id="btn-next"
                className="button__primary"
                onClick={this.moveToNextQuestion}
              >
                Next Question
              </Button>
            </Col>
            <Col
              className="d-lg-none"
              xs="12"
              sm={{ size: 8, offset: 3 }}
              md={{ size: 6, offset: 3 }}
            >
              <Button
                id="btn-back-mobile"
                className="button__tertiary"
                onClick={this.goBackToStartPage}
              >
                Back to Instructions
              </Button>
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.activeTab !== 3 && this.state.activeQuestion === 6) {
      ProgressButton = (
        <div className="text-right button-container">
          <Row className="storyinput__buttons">
            <Col
              xs="12"
              sm={{ size: 5, offset: 6 }}
              md={{ size: 4, offset: 4 }}
              lg={{ size: 3, offset: 9 }}
            >
              <Button
                id="btn-done"
                className="button__primary"
                onClick={this.moveToNextSection}
              >
                Get Your Conversation
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
    //If I am on the last page, I should see a 'Copy to Clipboard' button.
    else {
      ProgressButton = (
        <div className="button-container">
          <Row className="storyinput__buttons">
            <Col
              xs="12"
              md={{ size: 7, offset: 1 }}
              lg={{ size: 6, offset: 2 }}
            >
              <p className="button__leadin">
                Ready to get the conversation started?
              </p>
            </Col>
            <Col
              xs="12"
              md={{ size: 4, offset: 0 }}
              lg={{ size: 4, offset: 0 }}
            >
              <Clipboard
                className="button__primary button__copy"
                data-clipboard-text={this.state.convoDetails.convo_output}
                onSuccess={this.onSuccess}
              >
                { !this.state.isCopied && <span>Copy to Clipboard</span> }
								{ this.state.isCopied && <span>Copied!</span> }
              </Clipboard>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>
        <Jumbotron
          id="interactive-wrapper"
          className="interactive__component_wrapper"
        >
          {/* First page */}
          <Animated
            isVisible={this.state.activeTab === 1}
            animationIn="slideInLeft"
            animationOut="slideOutLeft"
            className={classnames("interactive__component", {
              hide_other_tab:
                this.state.activeTab === 2 || this.state.activeTab === 3,
              show: this.state.activeTab === 1,
            })}
          >
            <h1>Conversation Starter</h1>
            <br />
            <div className="d-none d-md-block">
              <p className="intro__text">
                This exercise will give you the opportunity to prepare for the
                tough conversation that you want to have. Preparation is key to
                a constructive conversation and productive outcome.
              </p>
              <br />
              <hr className="my-2" />
              <br />
              <p className="intro__text">
                Here are a few things we want you to know before getting
                started:
              </p>
              <br />
              <ul>
                <li>
                  <p className="intro__text">
                    There will be six questions that we have crafted
                    strategically based on a researched and proven model to
                    reduce the anxiety of delivering feedback and also reduce
                    the defensiveness of the recipient.
                  </p>
                </li>
                <br />
                <li>
                  <p className="intro__text">
                    By the end, you’ll have your own personal description of the
                    events that will help you get a conversation started.
                  </p>
                </li>
              </ul>
            </div>
            <div className="d-md-none">
              <p className="intro__text">
                This exercise will give you the opportunity to prepare for the
                tough conversation that you want to have. Preparation is key to
                a constructive conversation and productive outcome.
              </p>
              <br />
              <ul>
                <li>
                  <p className="intro__text">
                    There will be six questions that we have crafted
                    strategically based on a researched and proven model to
                    reduce the anxiety of delivering feedback and also reduce
                    the defensiveness of the recipient.
                  </p>
                </li>
                <br />
                <li>
                  <p className="intro__text">
                    By the end, you’ll have your own personal description of the
                    events that will help you get a conversation started.
                  </p>
                </li>
              </ul>
            </div>
          </Animated>

          {/* Question #1 - What was the situation */}
          <div id="question-1">
            <Animated
              animationIn="slideInRight"
              animationOut="slideOutUp"
              isVisible={
                this.state.activeTab === 2 && this.state.activeQuestion >= 1
              }
              className={classnames("clearfix", "interactive__component", {
                hide_other_tab:
                  this.state.activeTab === 1 || this.state.activeTab === 3,
                show:
                  this.state.activeQuestion >= 1 && this.state.activeTab === 2,
              })}
            >
              <div className="message-container question prompt">
                <span>
                  First describe the situation when the incident occurred. Being
                  specific (date, time, location) helps to avoid confusion and
                  provide documentation in case needed in the future.{" "}
                  <strong>Describe the situation.</strong>
                </span>
              </div>
              <FormGroup className="message-container right">
                <Input
                  className={classnames("question", "answer")}
                  name="situation_details"
                  placeholder="Enter text here..."
                  onChange={(e) => this.handleInputChange(e)}
                  value={this.state.convoDetails.situation_details}
                />
                <FormText color="muted">
                  Ex. “Last Wednesday, while at an after-work happy hour, our
                  Director of Marketing tried to pressure me into agreeing to do
                  something that I felt was unethical. When I wouldn’t agree to
                  it she said that I was just making excuses and that I probably
                  couldn’t do it because I was incompetent.”
                </FormText>
              </FormGroup>
            </Animated>
          </div>

          {/* Question #2 - Who was there? */}
          <div id="question-2">
            <Animated
              animationIn="slideInUp"
              animationOut="slideOutUp"
              isVisible={
                this.state.activeTab === 2 && this.state.activeQuestion >= 2
              }
              className={classnames("clearfix", "interactive__component", {
                hide_other_tab:
                  this.state.activeTab === 1 || this.state.activeTab === 3,
                show:
                  this.state.activeQuestion >= 2 && this.state.activeTab === 2,
              })}
            >
              <div className="message-container question prompt">
                <span>
                  When having a conversation about the incident, people will be
                  curious to know <strong>who else was there.</strong>
                </span>
              </div>
              <FormGroup className="message-container right">
                <Input
                  className={classnames("question", "answer")}
                  name="people_around"
                  placeholder="Enter text here..."
                  onChange={(e) => this.handleInputChange(e)}
                  value={this.state.convoDetails.people_around}
                />
                <FormText color="muted">
                  Ex. “Her whole team was present, as well as one person that I
                  manage.”
                </FormText>
              </FormGroup>
            </Animated>
          </div>

          {/* Question #3 - What was the behavior? */}
          <div id="question-3">
            <Animated
              animationIn="slideInUp"
              animationOut="slideOutUp"
              isVisible={
                this.state.activeTab === 2 && this.state.activeQuestion >= 3
              }
              className={classnames("clearfix", "interactive__component", {
                hide_other_tab:
                  this.state.activeTab === 1 || this.state.activeTab === 3,
                show:
                  this.state.activeQuestion >= 3 && this.state.activeTab === 2,
              })}
            >
              <div className="message-container question prompt">
                <span>
                  Next let’s focus on behavior. Make this part as free of
                  emotion and judgement as possible. It should be clear and
                  direct. <strong>Describe the initiator’s behavior.</strong>
                </span>
              </div>
              <FormGroup className="message-container right">
                <Input
                  className={classnames("question", "answer")}
                  name="initiator_behavior"
                  placeholder="Enter text here..."
                  onChange={(e) => this.handleInputChange(e)}
                  value={this.state.convoDetails.initiator_behavior}
                />
                <FormText color="muted">
                  Ex. “I saw her stand up, move into my personal space and point
                  her finger in my face while talking at me.”
                </FormText>
              </FormGroup>
            </Animated>
          </div>

          {/* Question #4 - How did you feel? */}
          <div id="question-4" className="clearfix">
            <Animated
              animationIn="slideInUp"
              animationOut="slideOutUp"
              isVisible={
                this.state.activeTab === 2 && this.state.activeQuestion >= 4
              }
              className={classnames("clearfix", "interactive__component", {
                hide_other_tab:
                  this.state.activeTab === 1 || this.state.activeTab === 3,
                show:
                  this.state.activeQuestion >= 4 && this.state.activeTab === 2,
              })}
            >
              <div className="message-container question prompt">
                <span>
                  Next, let’s focus on you.{" "}
                  <strong>
                    Can you describe how their behavior made you feel?
                  </strong>
                </span>
              </div>
              <FormGroup className="message-container right">
                <Input
                  className={classnames("question", "answer")}
                  name="feelings"
                  placeholder="Enter text here..."
                  onChange={(e) => this.handleInputChange(e)}
                  value={this.state.convoDetails.feelings}
                />
                <FormText color="muted">
                  Select the feelings that most resonate with you below.
                </FormText>
              </FormGroup>
            </Animated>
            <Animated
              animationIn="slideInUp"
              animationOut="slideOutUp"
              isVisible={
                this.state.activeTab === 2 && this.state.activeQuestion >= 4
              }
              className={classnames("clearfix", "interactive__component", {
                hide_other_tab:
                  this.state.activeTab === 1 || this.state.activeTab === 3,
                show:
                  this.state.activeQuestion >= 4 && this.state.activeTab === 2,
              })}
            >
              <div
                className={classnames(
                  "message-container",
                  "right",
                  "question",
                  "answer",
                  "container__only"
                )}
              >
                <MultiSelectControl
                  id="feelingsFilter"
                  title=""
                  cssClass="interactive__component_filter"
                  options={feelingsMap}
                  selectedOptions={
                    this.state.convoDetails.selectedFeelingsFilters
                  }
                  optionsChangedAction={this.feelingsOptionsChanged}
                />
              </div>
            </Animated>
          </div>

          {/* Question #5 - Why did I feel this way? */}
          <div id="question-5">
            <Animated
              animationIn="slideInUp"
              animationOut="slideOutUp"
              isVisible={
                this.state.activeTab === 2 && this.state.activeQuestion >= 5
              }
              className={classnames("clearfix", "interactive__component", {
                hide_other_tab:
                  this.state.activeTab === 1 || this.state.activeTab === 3,
                show:
                  this.state.activeQuestion >= 5 && this.state.activeTab === 2,
              })}
            >
              <div className="message-container question prompt">
                <span>
                  Staying with those feelings, try to go a level deeper.{" "}
                  <strong>Why did it make you feel this way? </strong>
                  By explaining your true feelings — and not passing judgment —
                  the receiver is more likely to listen and learn.
                </span>
              </div>
              <FormGroup className="message-container right">
                <Input
                  className={classnames("question", "answer")}
                  name="explain_feelings"
                  placeholder="Enter text here..."
                  onChange={(e) => this.handleInputChange(e)}
                  value={this.state.convoDetails.explain_feelings}
                />
                <FormText color="muted">
                  Ex. “Because I was new and I was concerned that it would
                  affect my ability to build relationships if the people that
                  were there believed that I was incompetent.”
                </FormText>
              </FormGroup>
            </Animated>
          </div>

          {/* Question #6 - What do you want going forward? */}
          <div id="question-6">
            <Animated
              animationIn="slideInUp"
              animationOut="slideOutUp"
              isVisible={
                this.state.activeTab === 2 && this.state.activeQuestion >= 6
              }
              className={classnames("clearfix", "interactive__component", {
                hide_other_tab:
                  this.state.activeTab === 1 || this.state.activeTab === 3,
                show:
                  this.state.activeQuestion === 6 && this.state.activeTab === 2,
              })}
            >
              <div className="message-container question prompt">
                <span>
                  Finally, let’s focus on outcomes. Don't be afraid to get
                  specific and communicate what you want.{" "}
                  <strong>Describe what you want going forward.</strong>
                </span>
              </div>
              <FormGroup className="message-container right">
                <Input
                  className={classnames("question", "answer")}
                  name="desired_outcomes"
                  placeholder="Enter text here..."
                  onChange={(e) => this.handleInputChange(e)}
                  value={this.state.convoDetails.desired_outcomes}
                />
                <FormText color="muted">
                  Ex. “I want to understand what she is trying to achieve and
                  figure out a way to communicate about different points of view
                  in a way that isn't personal.”
                </FormText>
              </FormGroup>
            </Animated>
          </div>

          {/* Conversation starter output */}
          <Animated
            isVisible={this.state.activeTab === 3}
            animationIn="slideInRight"
            animationOut="slideOutLeft"
            className={classnames("interactive__component", {
              hide_other_tab:
                this.state.activeTab === 1 || this.state.activeTab === 2,
              show: this.state.activeTab === 3,
            })}
          >
            <h1>Your Conversation Starter</h1>
            <p>
              This is for you – this will not be saved for later. Feel free to
              edit the text, add more description, or copy to clipboard.
            </p>
            <br />
            <Input
              type="textarea"
              className={classnames("question", "output", "starter_output", {
                active: this.state.activeTab === 3,
              })}
              name="convo_output"
              onChange={(e) => this.handleInputChange(e)}
              value={this.state.convoDetails.convo_output}
            />
          </Animated>
        </Jumbotron>
        {ProgressButton}
      </div>
    );
  }
}

export default InteractiveConvoStarter;