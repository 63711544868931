import analyticsTracker  from "../analytics/AnalyticsTracker";

const handleExpiredTokens = (res) => {
  if (res.status == 401) {
    analyticsTracker.trackEvent({ 
      category: "User", 
      action: "Auth Expired" 
    });
 

    let authType = sessionStorage.getItem('mobileAuth');
    window.location.href = authType?"#/auth_phone?expired=true":"#/auth?expired=true";

  }
}



export default  handleExpiredTokens;