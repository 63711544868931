import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import HomeScreenCard from './HomeScreenCard';

import '../Home.css';

class HomeScreenCardList extends Component {

  render() {
    return (
      <Row noGutters>
        <Col xs="12" md="6" className="homescreen__card_item">
          <HomeScreenCard title="Strategize" subtitle="Action plans to best address your situation" iconPath="/icons/home/icon_strategize.svg" />
        </Col>
        <Col xs="12" md="6" className="homescreen__card_item">
          <HomeScreenCard title="Learn" subtitle="Tools for healthy and open communication" iconPath="/icons/home/icon_learn.svg" />
        </Col>
        <Col xs="12" md="6" className="homescreen__card_item">
          <HomeScreenCard title="Read" subtitle="Workplace experiences that may be just like yours" iconPath="/icons/home/icon_read.svg" />
        </Col>
        <Col xs="12" md="6" className="homescreen__card_item">
          <HomeScreenCard title="Talk" subtitle="Appointments with a confidential Ombuds" iconPath="/icons/home/icon_talk.svg" />
        </Col>
      </Row>
    );
  }

}

export default HomeScreenCardList;
