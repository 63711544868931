const feature_flips = require('fflip');

let FlipCriteria = [
  {
    id: 'allowCompanyIDs',
    check: function(user, allowedIDs) {
      if (user.getCompanyName())
        return allowedIDs.indexOf(user.getCompanyName()) > -1;
      else
        return false;
    }
  }
];

let FlipFeatures = [
  {
    id: 'VentNominate', 
    criteria: {allowCompanyIDs: ['Acme Co']}  // acme
  }
]

feature_flips.config({
  criteria: FlipCriteria, 
  features: FlipFeatures  
});

/*// Get all of a user's enabled features...
visitor_profile.features = feature_flips.getFeaturesForUser(visitor_profile);
if(visitor_profile.features.M2 === true) {
  console.log('Welcome to the Milestone 2!');
}

// ... or just check this single feature.
if (feature_flips.isFeatureEnabledForUser('M2', visitor_profile) === true) {
  console.log('Welcome to the Milestone 2!');
}
*/

export default feature_flips;
