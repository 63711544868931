import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SchedulerDateCard from '../components/SchedulerDateCard';
import SchedulerDayNav from '../components/SchedulerDayNav';
import { Col, Row } from 'reactstrap';

import './css/SchedulerList.css';

class SchedulerDayList extends Component {
  constructor(props) {
    super(props);
    this.makeCards = this.makeCards.bind(this);
  }
  
  makeCards(dataArray) {
    return dataArray.map((cardData, index) => {
      return <li key={index.toString()}><SchedulerDateCard {...cardData} /></li>;
    });
  }

  render() {
    const anySpanish = this.props.data.find(d => d.slots.find( s => s.espanol ));
    return (
      <form className="scheduler__list scheduler__list--day" onChange={this.props.changeHandler}>
        <Row>
          <Col md="12" lg="6">
            <h2>Choose a day</h2>
          </Col>
          { anySpanish && <Col md="12" lg="6" className="scheduler__list_language_vailability">
             <span>*English/Español</span>
          </Col> }
        </Row>
        <ul>
          {this.makeCards(this.props.data)}
        </ul>
        <SchedulerDayNav
          nextActive={this.props.showNext}
          nextHandler={this.props.navigationClickHandler}
          previousActive={this.props.showPrevious}
          previousHandler={this.props.navigationClickHandler} />
      </form>
    );
  }
}

SchedulerDayList.defaultProps = {
  data: [{
    start: new Date().getTime(),
    end: new Date().getTime(),
    availableSlots: 0
  }],
  changeHandler: ()=>{},
  navigationClickHandler: ()=>{},
  showNext: true,
  showPrevious: false
};

SchedulerDayList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.string,  // Should be a datetime string
    end: PropTypes.string,    // Should be a datetime string
    availableSlots: PropTypes.number
  })),
  changeHandler: PropTypes.func.isRequired,
  navigationClickHandler: PropTypes.func,
  showNext: PropTypes.bool.isRequired,
  showPrevious: PropTypes.bool
};


export default SchedulerDayList;

