import React, { Component } from 'react';
import {
  Switch, 
  HashRouter, 
  Redirect
} from "react-router-dom";
import 'whatwg-fetch'; 
import { PropsRoute } from 'react-router-with-props';
import PrivateRoute from '../routing/PrivateRoute';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import FAQInteractive from './FAQInteractive';
import OutcomeCategories from './OutcomeCategories';
import RespondCategories from './RespondCategories';
import SolutionHRContacts from './solutions/SolutionHRContacts';
import SolutionPolicies from './solutions/SolutionPolicies';
import SolutionsLearningModule from './solutions/SolutionLearningModule';
import StoryList from './solutions/stories/StoryList';
import StoryPage from './solutions/stories/StoryPage';
import EmailAuth from './authentication/EmailAuth';
import SmsAndEmailAuthVerify from './authentication/SmsAndEmailAuthVerify';
import EmailAuthError from './authentication/EmailAuthError';
import StaticDocViewer from './containers/StaticDocViewer';
import AboutContainer from './containers/AboutContainer';
import withTracker from '../analytics/withTracker';
import ReactGA from 'react-ga';
import './App.css';
import FourOhFour from './FourOhFour';
import StoryInput from './StoryInput';
import COVIDhub from './COVIDhub';
import PreAuth from './authentication/PreAuth';
import CustomResultsHUB from './CustomResultsHub';
import CrossOriginFailover from './authentication/CrossOriginFailover';

import visitor_profile from './VisitorProfile';
import PhoneAuth from './authentication/PhoneAuth';
import ChallengeAuth from './authentication/ChallengeAuth';
import MaintenanceMode from './authentication/MaintenanceMode';
import LearningModulesList from './solutions/learningModules/LearningModulesList';

class App extends Component {

  constructor(props) {
    super(props);

    // We have to initialize GA so there will be a _ga cookie
    // for our middleware to use for tracking uniqueness of visitor
    // and browser info, etc. 
    ReactGA.initialize(process.env.REACT_APP_GA, {debug: false});

    this.state = {
      mobileAuth: visitor_profile.getCompanyName() !== "" ? visitor_profile.getMobileAuth() : false,
      challengeAuth: visitor_profile.getCompanyName() !== "" ? visitor_profile.getChallengeAuth() : false,
      maintenanceMode: false,
      visitorStory: {
        perspective: "",
        department : "",
        office : "",
        level : "",
        relationship : "",
        behavior: "",
        other_behavior: "",
        identities: null,
      },
      hasVisitorStoryData : false
    }

    if (visitor_profile.getCompanyName() === "" && !window.location.hash.includes('access_token')) {
      visitor_profile.fetchCompanyInfoPublic()
        .then((data) => {
          this.setState({ 
            mobileAuth : visitor_profile.getMobileAuth(),
            maintenanceMode: visitor_profile.getMaintenanceMode(),
            challengeAuth: visitor_profile.getChallengeAuth()
          }); 
        });
    }
  }

  // primarily for testing
  publishStateChange(propertyPath, update) {
     this.setState({ [propertyPath]: update });
  }
 
  render() {
    return (
      <div>
        <HashRouter>
          <div className="App">
            <Header />
            <main className="AppContent" id="main" tabIndex="-1">
              <Switch>
                <PropsRoute exact path="/" path_id="homepage" mobile_auth={this.state.mobileAuth} challenge_auth={this.state.challengeAuth} component={Home} />
                <PropsRoute exact path="/auth" path_id={this.state.maintenanceMode ? "maintenance": "auth" } mobile_auth={this.state.mobileAuth} challenge_auth={this.state.challengeAuth} component={this.state.maintenanceMode ? withTracker(MaintenanceMode): withTracker(EmailAuth)} />
                <PropsRoute exact path="/auth_phone" path_id={this.state.maintenanceMode ? "maintenance": "auth_phone" } mobile_auth={this.state.mobileAuth} challenge_auth={this.state.challengeAuth} component={this.state.maintenanceMode ? withTracker(MaintenanceMode): withTracker(PhoneAuth)} />
                <PropsRoute exact path="/auth_challenge" path_id={this.state.maintenanceMode ? "maintenance": "auth_challenge" } challenge_auth={this.state.challengeAuth} mobile_auth={this.state.mobileAuth} component={this.state.maintenanceMode ? withTracker(MaintenanceMode): withTracker(ChallengeAuth)} />
                <PropsRoute exact path="/failover" path_id="failover" component={withTracker(CrossOriginFailover)} />
                <PropsRoute exact path="/verify" path_id="verify" component={withTracker(SmsAndEmailAuthVerify)} /> :
                
                <Redirect from='/access_token*' to={{
                                                    pathname: '/verify',
                                                    state: { hash: window.location.hash }
                                                  }}/>
                <PropsRoute exact path="/preauth/:authkey/:dest" path_id="preauth" component={PreAuth} />
                <PropsRoute from='/error=*' path_id="auth_error"  component={withTracker(EmailAuthError)} />
                <PropsRoute from='/404' path_id='four_oh_four' component={withTracker(FourOhFour)} />
                
                <PrivateRoute exact path="/storyinput" path_id="story_input" storyData={this.state.visitorStory}  hasVisitorStoryData={this.state.hasVisitorStoryData} onStateChange={this.publishStateChange} component={withTracker(StoryInput)} />
                <PrivateRoute path="/outcome" component={OutcomeCategories} />
                <PrivateRoute path="/respond/:category" component={RespondCategories} />
                
                <PropsRoute path="/legal/terms_of_use" name="terms_of_use" path_id="terms_of_use" component={withTracker(StaticDocViewer)} />
                <PropsRoute path="/legal/privacy_policy" name="privacy_policy" path_id="privacy_policy" component={withTracker(StaticDocViewer)}/>
                <PropsRoute path="/app/version" name="version" path_id="version" component={withTracker(StaticDocViewer)}/>

                <PropsRoute exact path="/faq" selected="faq" path_id="faq" component={withTracker(FAQInteractive)} />
                <PropsRoute path="/about" selected="about" path_id="about" component={withTracker(AboutContainer)}/>

                <PrivateRoute exact path="/solutions/storiesSearch" path_id="solution_stories_search" endpoint="/stories" title="Read Stories" subtitle="Explore stories similar to your situation" component={withTracker(StoryList)} />
                <PrivateRoute exact path="/solutions/learningModulesSearch" path_id="solution_learning_modules_search" endpoint="/learningModules" title="Respond" subtitle="Find out how to respond to the situation" component={withTracker(LearningModulesList)} />

                <PrivateRoute exact path="/solutions/hr" path_id="solution_hr" component={withTracker(SolutionHRContacts)} />
                <PrivateRoute exact path="/solutions/policies" path_id="solution_policies" component={withTracker(SolutionPolicies)} />
                <PrivateRoute exact path="/solutions/:slug" path_id="solutions_learning_module" endpoint="/learningModules" component={SolutionsLearningModule} />

                <PrivateRoute exact path="/solutions/storiesSearch/:itemID" path_id="solution_stories_search_story" endpoint="/stories" component={withTracker(StoryPage)} />
                <PrivateRoute exact path="/covid-19" path_id="covid-hub" selected="covid-19" title="COVID-19 Resources" subtitle="Changes in your work environment can feel overwhelming and create tension between coworkers. Here are some resources to help you navigate these challenging times." component={withTracker(COVIDhub)} />
                <PrivateRoute exact path="/custom_results" path_id="custom_results" selected="outcomes" title="What Would You Like to Happen Next?" subtitle="Identify what’s important to you, explore outcomes, and develop an action plan that provides the best resolution for you." component={withTracker(CustomResultsHUB)} />
              </Switch>
            </main> 
            <Footer />
          </div>
        </HashRouter>
      </div>
    );
  }

}

export default App;

