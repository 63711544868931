import visitor_profile from '../views/VisitorProfile';

class AnalyticsTracker  {
  trackPage(page, pathId) {
    visitor_profile.getFeatureFlags().then((featureFlags) => {
      const browserViewport = window.innerWidth + "x" + window.innerHeight; 
      const screenResolution = window.screen.width + "x" + window.screen.height; 
      let v_id = sessionStorage.getItem('v');
      if (v_id) {
        var delimiter = v_id.lastIndexOf('_');
        v_id = v_id.slice(delimiter+1);
     }

      let source = (sessionStorage.getItem('prevPath') === null && page === "/") ? "start" : sessionStorage.getItem('prevPath');
    
      // Send page info to GA 
      let bodyObject = {
        pagePath: page, 
        viewport: browserViewport, 
        screenRes: screenResolution,
        visitor_id: v_id,
        platform: navigator.platform,
        source,
        debugId: pathId,
        '$set_once': { companyName: visitor_profile.getCompanyName() }, // ensure company name get's set
        '$active_feature_flags': featureFlags
      };
    
      fetch('/tracker/page', {
        method: 'POST',
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type' : 'application/json'
        },
        credentials: "same-origin",
        body: JSON.stringify(bodyObject)
      });
    });
  }

  trackEvent(eventInfo) {
    visitor_profile.getFeatureFlags().then((featureFlags) => {
      eventInfo['$current_url'] = eventInfo['$current_url'] || window.location.href;
      // ensure company name get's set
      eventInfo['$set_once'] = { 
        companyName: visitor_profile.getCompanyName()
      };
      eventInfo['$active_feature_flags'] = featureFlags;

      let v_id = sessionStorage.getItem('v');
      if (v_id) {
        var delimiter = v_id.lastIndexOf('_');
        v_id = v_id.slice(delimiter+1);
      }
      eventInfo.visitor_id = v_id;
      // Send page info to GA 
      fetch('/tracker/event', {
        method: 'POST',
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type' : 'application/json'
        },
        credentials: "same-origin",
        body: JSON.stringify(eventInfo)
      });
    });
  }
  
}

const analyticsTracker = new AnalyticsTracker(); 

export default analyticsTracker; 