import React, { Component } from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import PrivateRoute from '../routing/PrivateRoute';
import Options from "./Options";
import SolutionAdvice from './solutions/SolutionAdvice';
import SolutionAdviceTime from './solutions/SolutionAdviceTime';

import AppointmentConfirmation from './solutions/calendar/AppointmentConfirmation';
import withTracker from '../analytics/withTracker';

import './OutcomeCategories.css';

class OutcomeCategories extends Component {
    
	render() {
			return (
				<Switch>
					<PrivateRoute exact path={`${this.props.match.path}/options_validation`} render={(props) => <Options path_id="options_validation" {...props} />} />	
					<PrivateRoute exact path={`${this.props.match.path}/options_response`} render={(props) => <Options path_id="options_response" {...props} />} />
					<PrivateRoute exact path={`${this.props.match.path}/options_managers`} render={(props) => <Options path_id="options_managers" {...props} />} />
					<PrivateRoute exact path={`${this.props.match.path}/schedule`} path_id="solution_advice" isALandingPage={true} component={withTracker(SolutionAdvice)} />
					<PrivateRoute exact path={`${this.props.match.path}/schedule/time`} path_id="solution_advice_time" isALandingPage={true} component={withTracker(SolutionAdviceTime)} />
					<PrivateRoute exact path={`${this.props.match.path}/schedule/confirmation`} path_id="solution_advice_confirmation" isALandingPage={true} component={withTracker(AppointmentConfirmation)} />

					<Route exact path={this.props.match.path} render={props=>{
						return (
								<Options path_id="outcome_categories" {...this.props} />
							);
					}} />
				</Switch>
		);
	}
}

export default OutcomeCategories;

