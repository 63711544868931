/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';
import analyticsTracker from './AnalyticsTracker';
import visitor_profile from '../views/VisitorProfile';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    analyticsTracker.trackPage(page, visitor_profile.getCurrentPathId());
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname;
      const nextPage = this.props.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    componentWillUnmount() {
      const currentPage = this.props.path_id;
      sessionStorage.setItem('prevPath', currentPage);
      analyticsTracker.trackEvent({
        action: '$pageleave',
        '$current_url': window.location.origin + this.props.location.pathname + this.props.location.search,
        pathName: this.props.location.pathname,
        pathId: currentPage,
        search: this.props.location.search
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
