/**
 * FeedbackControl.js
 * Form control for receiving user feedback.
 * @param {string} cssClass - Additional css class names. Optional. 
 * @param {string} question - What to ask the user. This also functions as the form control label.  Required.
 * @param {string} topic - What is this relating to? Valid values are "relevance" or "confidence". Required.
 * @param {array} choices - An array of objects {label: string, value: number}
 * @param {string} id - Value for the `id` attribute. Required.
 * @param {string} name - Value for the `name` attribute. Optional. Will default to controlId
 */ 

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import visitor_profile from '../VisitorProfile';

import "./css/FeedbackControl.css";

class FeedbackControl extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (nextState.selected !== this.state.selected); 
  }

  constructor(props) {
    super(props);

    this.state = {
      selected: false
    }

    this.controlName = this.props.name || this.props.id;
    this.makeInputs = this.makeInputs.bind(this);
    this.showCheckIn = this.showCheckIn.bind(this);
    this.showSubmitted = this.showSubmitted.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.getSelectedInputValue = this.getSelectedInputValue.bind(this);
  }

  changeHandler() {
    this.setState({selected: true});
    
    let selectedValue = this.getSelectedInputValue(this.props.choices);

    if (selectedValue !== -1) {
      if (this.props.topic === 'relevance')
        visitor_profile.recordPathRelevance(selectedValue, window.location);
      else if (this.props.topic === 'confidence')
        visitor_profile.recordPathConfidence(selectedValue, window.location);
    }
  }

  makeInputs(choices) {
    const inputs = choices.map( (choice, index) => {
      const id = `${this.controlName}__${choice.value}`;
      return (
        <p key={index.toString()}>
          <input type="radio" name={this.controlName} id={id} value={choice.value} />
          <label htmlFor={id}>{choice.label}</label>
        </p>
      )
    });
    return inputs;
  }

  getSelectedInputValue()
  {
    for (var i=0; i<this.props.choices.length; i++)
    {
      const id = `${this.controlName}__${this.props.choices[i].value}`;
      let element = document.getElementById(id);
      if (element.checked)
        return element.value;
    }
    return -1; 
  }

  showCheckIn() {
    const cssClasses = classNames({
      'feedback__controls': true,
      'feedback__controls_user_did_answer': this.state.selected
    }, this.props.cssClass);

    //const the_name = this.props.name ? this.props.name : this.props.id;
    const inp = this.makeInputs(this.props.choices);

    
    return (
        <fieldset className={cssClasses} onChange={this.changeHandler}>
          <div>
            <legend>{this.props.question}</legend>
            <div className="feedback__controls_inputs">
              {inp}
            </div>
          </div>
        </fieldset>
    );
  }

  showSubmitted() {
    return <p className="feedback__control__submit_message">Ok, Thanks. That’s good to hear.</p>;
  }
  
  render() {
    const ui = this.props.isSubmitted ? this.showSubmitted() : this.showCheckIn();
    return ui;
  }
}

FeedbackControl.defaultProps = {
  question: '',
  topic: '',
  choices: [
    { label: 'Not At All', value: 0 },
    { label: 'A Little', value: 1 },
    { label: 'Mostly', value: 2 },
    { label: 'Very', value: 3 }
  ],
  id: '',
  isSubmitted: false
};

  

FeedbackControl.propTypes = {
  cssClass: PropTypes.string,
  question: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  isSubmitted: PropTypes.bool
};

export default FeedbackControl;
