import React, { Component } from 'react';
import classNames from 'classnames';
import analyticsTracker from '../../analytics/AnalyticsTracker';

class BackButton extends Component {

	constructor(props) {
		super(props);
		this.goBack = this.goBack.bind(this);
	}
	
	render() { 	
		const cssClasses = classNames('hero__back_img', this.props.className);

	  return (
			<a href="#" onClick={this.goBack} className="hero__back_wrapper">
				<img src="/icons/large/icon_large_back.svg" className={cssClasses} alt="Back" />
			</a>
		);

	}

	goBack(e) {
		e.preventDefault();
		const { history, location } = this.props;
		analyticsTracker.trackEvent({
			action: "Clicked the back button",
			category: "User",
			pathName: location.pathName
		});
		
		// Go back one page 
		history.go(-1);
	}
}

export default BackButton;
