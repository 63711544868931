/**
 * ProductFeedback.js
 * Form group that lets the visitor submit feedback about the product to us
 * See PropTypes at end of this file for more.
 */ 

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form, Label } from 'reactstrap';
import visitor_profile from '../VisitorProfile';
import "./css/ProductFeedback.css";

class ProductFeedback extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      feedback_text : "",
      emailAddress : "",
      emailSent : false
    };

    this.clearAndClose = this.clearAndClose.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
  }

  clearAndClose() {
    // Clear out the old text if there was any 
    this.setState({
      feedback_text : "",
      emailAddress : "",
      emailSent : false
    });

    this.props.onClose();
  }

  submitFeedback(e) {
    if (e)
      e.preventDefault();
    
    // call to backend service that sends feedback email 
    fetch('/feedback', {
                method: 'POST',
                body: JSON.stringify({ company_name : visitor_profile.getCompanyName(), 
                                       feedback : this.state.feedback_text, 
                                       visitor_email : this.state.emailAddress }),
                headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',}
                })
      .then(res => {
        if (res.status !== 200)
        {
          console.log(res.statusText);
        }
        else {
          this.setState({ emailSent : true });
          setTimeout(this.clearAndClose, 2000);
        }
      })
      .catch(err => {
        console.log(err);
        this.clearAndClose();
      });
  }

  handleEmailChange(e) {
    this.setState({emailAddress: e.target.value});
  }

  handleFeedbackChange(e) {
    this.setState({feedback_text: e.target.value});
  }

  render() {
    return (
      <Modal className="custom__modal__dialog" modalClassName="prodfeedback__wrapper" contentClassName="prodfeedback__modal" isOpen={this.props.open} toggle={this.clearAndClose} backdrop={true}>
        <ModalHeader className="prodfeedback__header" toggle={this.clearAndClose}>Product Feedback</ModalHeader>
        <ModalBody className="prodfeedback__body">
          <Form onSubmit={this.submitFeedback}>
            <Label>Have feedback? Anything we can add or improve? Let us know!</Label>
            <Input className="feedback__input"
                    type="textarea" 
                    name="text" 
                    onChange={this.handleFeedbackChange} 
                    value={this.state.feedback_text} 
                    maxLength="2048"
                    />
            <Label>Would you like a response?</Label>
            <Input type="email" name="email" 
                    onChange={this.handleEmailChange} 
                    value={this.state.emailAddress} 
                    maxLength="1024"
                    placeholder="Enter email address (optional)" />
          </Form>
        </ModalBody>
        <ModalFooter className="prodfeedback__footer">
          {this.state.emailSent && 
            <h4 className="prodfeedback__thanks">Thanks for your feedback!
            </h4>}
          <Button className="button__tertiary" onClick={this.clearAndClose}>Cancel</Button>{' '}
          <Button type="submit" className="button__primary" onClick={this.submitFeedback}>Send Feedback</Button>
        </ModalFooter>
      </Modal>
    );
  }
}


ProductFeedback.defaultProps = {
  linkClassName: null,
  open : false,
  onClose : null
};

  

ProductFeedback.propTypes = {
  linkClassName: PropTypes.string,
  open : PropTypes.bool.isRequired,
  onClose : PropTypes.func.isRequired
};

export default ProductFeedback;