import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Form, FormGroup, Button } from 'reactstrap';
import "./FourOhFour.css";

class FourOhFour extends Component {

  componentDidMount() {
    document.getElementsByClassName("Footer")[0].innerHTML = '';
  }

  render() {
    return (
      <div className="oops__outer_container">
      <Jumbotron className="oops__background">
          <Form className="oops__container">
            <FormGroup>
              <Container>
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:7, offset:2}} style={{ textAlign: 'center' }}>
                  <div className="oops__text">OOPS!</div>
                  <div className="oops__inner_container">
                    <div className="oops__info">You have reached an error page.</div>
                    <div className="oops__info_subheader">Contact your HR Team to get the correct web address (URL).</div>
                    <Button className="oops__button button__primary" onClick={() => window.location.href = 'https://tequitable.com'}>LEARN MORE ABOUT TEQUITABLE</Button>
                  </div>
                </Col>
              </Row>
              </Container>
            </FormGroup>
          </Form>
      </Jumbotron>
      </div>
    );
  }
}


export default FourOhFour;

