import React, { Component } from 'react';
import { Row, Collapse, Card, CardBody } from 'reactstrap';
import visitor_profile from './VisitorProfile';
import classNames from 'classnames';
import './FAQInteractiveHP.css';
import analyticsTracker from '../analytics/AnalyticsTracker';

class FAQInteractiveHP extends Component {
  constructor(props) {
    super(props);
    
    this.makeCards = this.makeCards.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {'sections': [
      {
        section_title: "What is tEQuitable and how can it help me?",
        section_text:"<p>Think of tEQuitable as your personal coach and trusted advisor for workplace issues and challenges.</p><p>We allow you to privately unpack issues, acquire new skills to resolve conflict and help guide you towards an action plan &mdash; reviewing different ideas, evaluating pros and cons, and supporting you in achieving a resolution you are confident in.</p>",
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "What types of workplace challenges and issues can tEQuitable help me resolve?",
        section_text:`<p>If you've experienced an inappropriate workplace challenge that made you uneasy and impacted your day-to-day well-being, tEQuitable can help. We support workplace challenges like micro-aggressions and interpersonal conflicts, from disagreements to inappropriate behaviors:</p>
        <p>
          <ul>
            <li>"I got some tough feedback from my boss and I'm feeling a bit lost about what to do next."</li>
            <li>"I received an insulting email, and I'm not sure how to react or reply."</li>
            <li>"I was excluded from an important meeting, and I'm not sure why. I feel left out, and I don't know what to do about it."</li>
          </ul>
        </p>
        <p>We'll help you figure out the outcome you'd like to have happen and coach you toward resolution.</p>`,
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "What is an Ombuds?",
        section_text:`<p>Ombuds are coaches trained professionally to help employees figure out how to handle various workplace challenges. They adhere to the principles of being independent, confidential, impartial, and off-the-record &mdash; enabling you to safely unpack your experience privately.</p><p>tEQuitable is a tech-enabled Ombuds and allows you to speak directly with an Ombuds by phone or utilize a self-serve Ombuds platform &mdash; whichever way you prefer to
        receive advice. Each provides the same support and guidance towards helping you
        achieve a comfortable resolution for your situation.</p>`, 
        open : false,
        iconClassName : "faq__img_closed"
      },
    ]};  
  }

  componentDidMount() {
    if (sessionStorage.getItem('v')) {
      visitor_profile.recordPath(this.props.path_id);
    }
  }

  toggle(e) {
    let sections = this.state.sections; 
    if (!e.target.id) // If they clicked on the span
      e.target = e.target.parentElement;
    const id = e.target.id;
    const index = id.slice(id.lastIndexOf('_')+1);
    sections[index].open = !sections[index].open;

    let toggleOpen = "";
    if (sections[index].open) {
      toggleOpen = "Open";
    } else {
      toggleOpen = "Closed";
    }
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Clicked FAQ ' + toggleOpen,
      order: parseInt(index) + 1,
      title: sections[index].section_title
    });
    this.setState({ sections: sections });
  }

  
  makeCards() {
    return this.state.sections.map((item, index) => {
      const divid = "FAQdiv__" + index.toString();
      const buttonid = "FAQbutton__" + index.toString();
      const imgid = "FAQimg__" + index.toString();
      const collapseid = "FAQcollapse__" + index.toString();

      const cssClassesHeader = classNames({
        'faq__section_header': true,
        'faq__section_header_open': item.open
      });

      const cssClassesIcon = classNames({
        'faq__img_closed': true,
        'faq__img_open': item.open
      });

      return (
        <Row key={divid}>
          <div id={buttonid} className={cssClassesHeader} onClick={this.toggle}>
            <img id={imgid} className={cssClassesIcon} src="/icons/large/icon_large_faq_hp.svg" alt={"Read " + item.section_title} />
            <span>{item.section_title}</span>
          </div>
          <Collapse id={collapseid} isOpen={this.state.sections[index].open}>
            <Card className="faq__content learning__module_section">
              <CardBody dangerouslySetInnerHTML={{__html: item.section_text}}></CardBody>
            </Card>
          </Collapse>
        </Row>
      );
    });
  }
  
  render() {
    return ( 
      <div className='faq__hp' {...this.props} currentsection={this.props.selected}>
        {this.makeCards()}
      </div>
      );
  }
}

export default FAQInteractiveHP;