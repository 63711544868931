/**
 * CheckIn.js
 * Form group that should house FeedbackControl components.
 * See PropTypes at end of this file for more.
 */ 

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import FeedbackControl from './FeedbackControl';

import "./css/CheckIn.css";

class CheckIn extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.moreLinkText !== this.props.moreLinkText); 
  }

  constructor(props) {
    super(props);

    this.clickHandlerMoreLink = this.clickHandlerMoreLink.bind(this);
    this.makeQuestions = this.makeQuestions.bind(this);
    this.makeReadMore = this.makeReadMore.bind(this);
  }

  submitHandler(domEvent) {
    domEvent.preventDefault();
  }

  clickHandlerMoreLink(domEvent) {
    domEvent.persist();
    this.props.moreLinkAction(domEvent);
  }
  
  makeQuestions(questions) {
    const inputs = questions.map( (q, index) => {

      return (
        <FeedbackControl
          key={index.toString()}
          question={q.question}
          topic={q.topic}
          id="the_varsity"
          name={`the_control_name${index}`} />
      )
    });
    return inputs;
  }

  makeReadMore(more_link_text) {
    let more;
    
    if(!!more_link_text) {
      more = (
        <div>
          <Button className="checkin__read_more button__tertiary" onClick={this.clickHandlerMoreLink}>{more_link_text}</Button>
        </div>
      );
    } else {
      more = '';
    }   
    return more;
  }

  render() {
    return (
      <section className="checkin__">
        <h2>Checking In</h2>
        <form method="POST" action="/" onSubmit={this.submitHandler}>
          {this.makeQuestions(this.props.questions)}
        </form>
        {this.makeReadMore(this.props.moreLinkText)}
      </section>
    );
  }
}

CheckIn.defaultProps = {
  questions: [],
  moreLinkText: '',
  moreLinkAction: null
};

  

CheckIn.propTypes = {
  questions: PropTypes.array,
  moreLinkText: PropTypes.string,
  moreLinkAction: PropTypes.func
};

export default CheckIn;
