/**
SelectMenu.js
A reusable select menu component.
*/

import React from 'react';
import Select from 'react-select';

class SelectMenu extends React.Component {

  render() {

    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: 'transparent',
        background: '#FAFAFA',
        boxShadow:  '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)',
        borderRadius: 2,
        minHeight: 48,
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'justify',
        marginBottom: '1rem',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '20px',
        fontSize: '15px',
        '&:hover': {
          borderColor: 'transparent'
        }
      }),
      menu: (base, state) => ({
        ...base,
        borderRadius: 2,
        marginTop: '-0.25rem',
        background: '#FAFAFA',
        marginBottom: '2rem',
        boxShadow: state.isFocused ? '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)' : '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 0px rgba(0, 0, 0, 0.12)',
      }),
      multiValueRemove: (base) => ({
        ...base,
        ':hover': {
          backgroundColor: '#CACACA'
        },
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? '#EEEEEE' : '',
        borderColor: 'red',
        zIndex: 1,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '20px',
        fontSize: '15px',
        color: '#0d2a33',
        outline: state.isFocused ? '3px solid #2a8b92' : ''
      }),
      placeholder: (base) => ({
        ...base,
        color: '#0d2a33',
        opacity: 0.8
      }),
      indicatorSeparator: (base) => ({
        ...base,
        paddingLeft: '1rem',
        backgroundColor: 'transparent',
        borderLeft: '1px solid #CCCCCC'
      }),
      dropdownIndicator: (state) => ({
        color: 'transparent',
        background: `url('/icons/select-arrow-closed.svg') no-repeat right 4px center`
      })

    }

    return (
      <div hidden={this.props.hidden}>
        <Select
          id={this.props.id}
          className={this.props.className}
          name={this.props.name}
          aria-label={this.props.label}
          placeholder={this.props.label}
          isClearable={!this.props.clearable ? this.props.clearable : true }
          isSearchable={false}
          styles={customStyles}
          onChange={this.props.changeHandler}
          options={this.props.options}
          value={this.props.value}
          isMulti={this.props.isMulti}
        />
      </div>
    );
  }
}

export default SelectMenu;
