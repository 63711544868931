import React from 'react';
import Solution from './Solution';
import { replaceOrgType } from '../solutions/SolutionsList';
import visitor_profile from '../VisitorProfile';
import { Row, Col } from 'reactstrap';
import HeroListContainer from '../containers/HeroListContainer';
import ContentContainer from '../containers/ContentContainer';
import SelectMenu from '../components/SelectMenu'
import CheckIn from '../components/CheckIn';
import DoMoreButton from '../components/DoMoreButton';
import RelatedActionsGroup from '../containers/RelatedActionsGroup';

import "./Solutions.css";
import analyticsTracker from '../../analytics/AnalyticsTracker';

class SolutionHRContacts extends Solution {

  
  constructor(props) {
    super(props);
    
    this.state = { hr_contacts : [], 
      department : null, 
      hr_process : "", 
      has_hr : true, 
      pointofcontact : "", 
      subtitle: "" };

    this.links = [ "solution_policies" ];


    this.selectChange = this.selectChange.bind(this);
    this.makeOptions = this.makeOptions.bind(this);

    replaceOrgType();
  }

  componentDidMount() {
    super.componentDidMount();

    visitor_profile.fetchCompanyInfo("?fields=hr")
      .then((data) => { 
        let contact = "your HR Partner";
        if (data.has_hr === false) 
          contact = "someone to talk to";

        let subtitle = "Understand " + visitor_profile.getCompanyName() + "'s formal reporting process";

        this.setState({hr_contacts : data.hr_contacts, 
                        hr_process : data.hr_process, 
                        has_hr : data.has_hr, 
                        pointofcontact : contact, 
                        subtitle : subtitle }); });
  }

  selectChange(selectedOption) {
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'HR Contact Selected'
    });
    this.setState({department : selectedOption});
  }

  /*
    Takes the department list, retrieves the departments and creates an array of
    {label: '', value: ''} objects
  */

  makeOptions() {
    let options = [];
    
    if(this.state.hr_contacts && this.state.hr_contacts.length) {
      options = this.state.hr_contacts.reduce((depts, contact) => {
        if (contact.department in depts) {
          depts[contact.department].value.push(contact);
        } else {
          depts[contact.department] = { label: contact.department, value: [contact] };
        }
        return depts;
      }, {});
    }
        
    return Object.values(options);
  }
  
  render() {
    const departments = this.makeOptions();

    let menulabel = "Select department...";
    if (this.state.has_hr === false) {
      menulabel = "Select ...";
    }

    return (
      <HeroListContainer {...this.props} contentCssClass="short__background_content learning__module_border" title="Make a Report" subtitle={this.state.subtitle} currentsection="outcomes">
        <Row className="short__background_content_resources">
          <Col xs="12" sm={{ size: 10, offset: 1 }} >
            <ContentContainer cssClass="learning__module">
            <Row>
              <Col xs="12" sm={{size:10, offset: 1}}>
                <div className="learning__module_section">
                  <h2>Understand the process</h2>
                  <div dangerouslySetInnerHTML={{__html: this.state.hr_process}}></div>

                  <h2>Identify {this.state.pointofcontact}</h2>
                  <Row>
                    <Col xs="12" md={{size:6, offset: 0}}>
                      <div>Select to view contact information for people at your {visitor_profile.getOrgType()} who can provide more guidance.</div>
                      <SelectMenu  changeHandler={this.selectChange}
                        id="departments"
                        name="departments"
                        className={`hr__select ${this.state.department ? 'hr__select_contact_post' : ''}`}
                        label={menulabel}
                        options={departments}
                        value={this.state.department}
                        clearable={false}
                      />
                    </Col>
                    <Col xs="12" md={{size:6, offset: 0}} lg={{size:5, offset: 1}}>
                      {this.state.department ? this.state.department.value.map( c => {
                        return <div className={"hr__contact_info " + (this.state.department ? 'active' : '')}>
                          <div><span style={{'margin-right':'4px'}}>Name</span> <span className="hr__contact">{c.name}</span></div>
                          <div><span style={{'margin-right':'5px'}}>Email</span>  <span className="hr__contact">{c.email}</span></div>
                          {c.phone ? <div><span>Phone</span> <span className="hr__contact">{c.phone}</span></div> : ""}
                          {c.slack ? <div><span style={{'margin-right':'5px'}}>Slack</span> <span className="hr__contact">{c.slack}</span></div> : ""}
                      </div>
                      }): 
                      <div className="hr__contact_info">
                        <div>Name</div>
                        <div>Email</div>
                        <div>Phone</div>
                      </div>}
                      
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs="12" sm={{size:10, offset: 1}} lg={{size:9, offset: 1}} xl={{size:7, offset: 1}}>
                <CheckIn
                  questions={[{question:'Was this helpful for you?', topic:'relevance'}, {question:'How confident are you in your next step to resolve the incident (including taking no action)?', topic:'confidence'}]}
                  moreLinkText="" />
              </Col>
            </Row>
            </ContentContainer>

            <RelatedActionsGroup title="Want to learn more?" links={this.links} />

            <DoMoreButton route="/outcome" {...this.props} />

          </Col>
        </Row>
      </HeroListContainer>
    );
  }
}

export default SolutionHRContacts;

