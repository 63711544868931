/**
IconCardsContainer.js
Container element base class for icon card layouts
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import IconCard from '../components/IconCard';

class IconCardsContainer extends React.Component {
  constructor(props) {
    super(props);
    
    this.filterActiveCards = this.filterActiveCards.bind(this);
    this.makeCards = this.makeCards.bind(this);

    /* 
      'links' is the list of cards to display, elements in the array should have properties: 
      path_id: string,
      to: url segment,
      solution_title: string,
      solution_text: string,
      icon_src: path to svg,
      featureEnabled: boolean 
    */
    this.state = { 'links': this.props.links || [] }; 
  }
  
  /* 
    Filters array of cards and only returns those that are enabled
    in the feature flip.
  */
  filterActiveCards(item, index) {
    return item.slug? true : item.featureEnabled  === true;
  }
  
  makeCards() {
    const cards = this.state.links.filter(this.filterActiveCards);
    
    return cards.map((item, index) => {
      return (
        <Col className="card__list_col" key={index.toString()} xs="12" sm="12" md={{ size: 10, offset: 1 }} lg={{size:5, offset: index%2 ? 0 : 1}} >
          {item.component ? <IconCard tag="div" className="outcome-icon outcome-icon-loader">{item.component}</IconCard> :
            <IconCard
              tag="a"
              href={item.slug ? `#/solutions/${item.slug}`: item.to}
              heading={item.slug ? item.title : item.solution_title}
              key={index.toString()}
              text={item.slug ? item.subtitle : item.solution_text}
              pathid={item.slug ? `solution_${item.slug}` : item.path_id}
              topics={item.slug ? item.category : item.topics}>
              { (item.icon_src || item.icon_url) ? <img src={item.slug ? item.icon_url : item.icon_src} className="outcome-icon" alt={item.slug ? item.title : item.solution_title} /> : '' }
            </IconCard>
          }
        </Col>
      );
    });
  }
  
  render() {
    return <Row className="card__list">{this.makeCards()}</Row>;
  }
}

IconCardsContainer.defaultProps = {
  path_id: ''
};

IconCardsContainer.propTypes = {
  path_id: PropTypes.string.isRequired,
};

export default IconCardsContainer;

