import React from 'react';
import PropTypes from 'prop-types';

import './css/SchedulerConfirmButton.css';

class SchedulerConfirmButton extends React.Component {
  render() {
    return (
      <button
        className="scheduler__confirm"
        disabled={this.props.disabled}
        name={this.props.name}
        id={this.props.id}
        type="submit">

        <svg pointerEvents="none" width="32" height="32" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>Confirm</title>
          <g transform="translate(-12121 1301)">
            <use xlinkHref="#path18_stroke" transform="matrix(6.12323e-17 1 -1 6.12323e-17 12141.4 -1293.23)" fill="#FF5E2B"/>
            <use xlinkHref="#path19_stroke" transform="matrix(6.12323e-17 1 -1 6.12323e-17 12152 -1300)" fill="#FF5E2B"/>
          </g>
          <defs>
            <path id="path18_stroke" d="M 8.22607 0L 8.93318 -0.707107C 8.74564 -0.894643 8.49129 -1 8.22607 -1C 7.96086 -1 7.7065 -0.894643 7.51896 -0.707107L 8.22607 0ZM 0.707107 8.93318L 8.93318 0.707107L 7.51896 -0.707107L -0.707107 7.51897L 0.707107 8.93318ZM 7.51896 0.707107L 15.745 8.93318L 17.1593 7.51897L 8.93318 -0.707107L 7.51896 0.707107Z"/>
            <path id="path19_stroke" d="M 29 15C 29 22.732 22.732 29 15 29L 15 31C 23.8366 31 31 23.8366 31 15L 29 15ZM 15 29C 7.26801 29 1 22.732 1 15L -1 15C -1 23.8366 6.16344 31 15 31L 15 29ZM 1 15C 1 7.26801 7.26801 1 15 1L 15 -1C 6.16344 -1 -1 6.16344 -1 15L 1 15ZM 15 1C 22.732 1 29 7.26801 29 15L 31 15C 31 6.16344 23.8366 -1 15 -1L 15 1Z"/>
          </defs>
        </svg>
      </button>
    );
  }
}

SchedulerConfirmButton.defaultProps = {
  disabled: false,
  type: 'submit',
  text: 'Change default text',
  cssClass: null,
  value: ''
};

SchedulerConfirmButton.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  value: PropTypes.string
};

export default SchedulerConfirmButton;
