import React, { Component } from 'react';
import classNames from 'classnames';
import visitor_profile from './VisitorProfile';
import './Header.css';

class Header extends Component {

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }

 	render () {
    const cssClasses = classNames(this.props.cssClass, {
      'Header': true
    });

    return (
			<div className={cssClasses} {...this.props}>
        <a className="skip-main" href="#main">Skip to main content</a>
        {visitor_profile.getIsAuthenticated() && <img src="/icons/small/icon_back.svg" className="Header__back" onClick={this.goBack} alt="Back" /> }
        <a href="https://www.tequitable.com" className="Header-logo-link" target="_blank" rel="noreferrer noopener"><img src="/tEQ_logo_white.svg" className="Header-logo-img" alt="tEQuitable" /></a>
      </div>
		);
	}

  goBack(e) {
    e.preventDefault(); 

    window.history.go(-1);
  }
}

export default Header;
