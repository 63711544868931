/*
Displays time slots for a particular day.

TODO: Move this to components/ directory.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SchedulerTimeCard from '../components/SchedulerTimeCard';
import moment from 'moment-timezone';

import './css/SchedulerList.css';

class SchedulerTimeList extends Component {
  constructor(props) {
    super(props);
    this.makeCards = this.makeCards.bind(this);
  }

  makeCards(dataArray) {
     return dataArray.map((cardData, index) => {
      return <li key={index.toString()}><SchedulerTimeCard {...cardData} /></li>;
    });
  }

  render() {
    const anySpanish = this.props.data.find(d => d.espanol);
    const guessTZ = moment.tz.guess();
    const showTZ = guessTZ.replace(/_/g, " ");
    const zoneAbbr = moment().tz(guessTZ).zoneAbbr();

    return (
      <form className="scheduler__list" onChange={this.props.changeHandler} onSubmit={this.props.submitHandler}>
      <p>Your appointment will be scheduled in your local timezone:<br />
        <span>({zoneAbbr})&nbsp;{showTZ}</span></p>
        { anySpanish && <div className="scheduler__list_language_vailability">
          <span>*English/Español</span> 
        </div> }
        <ul>
          {this.makeCards(this.props.data)}
        </ul>
      </form>
    );
  }
}

SchedulerTimeList.defaultProps = {
  data: [{
    start: Date.now(),
    end: new Date(Date.now() + 3600),
    isSelected: false
  }],
  changeHandler: ()=>{},
  submitHandler: ()=>{}
};

SchedulerTimeList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.string.isRequired, // Should be a time stamp
    end: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired
  })).isRequired,
  changeHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired
};

export default SchedulerTimeList;

