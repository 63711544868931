import React, { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import visitor_profile from './VisitorProfile';
import HomeScreen from './HomeScreen';
import './Home.css';
import analyticsTracker from '../analytics/AnalyticsTracker';

class Home extends Component {

  componentDidMount() {
    // populate the company id and name
    // then create visitor
    visitor_profile.fetchCompanyInfoPublic()
      .then(() => { 
        visitor_profile.createVisitor()
          .then(() => {
            visitor_profile.recordPath(this.props.path_id);
            analyticsTracker.trackPage(this.props.location.pathname, visitor_profile.getCurrentPathId());
          });
      })
      .catch(err => {
        window.location.href= '/#/404';
      });
  }

  render() {
    return (
      <Jumbotron className="home__container" fluid>
          <HomeScreen {...this.props} />
      </Jumbotron>
    );
  }

  componentWillUnmount() {
    analyticsTracker.trackEvent({
      action: '$pageleave',
      '$current_url': window.location.origin + this.props.location.pathname + this.props.location.search,
      pathName: this.props.location.pathname,
      pathId: 'start',
      search: this.props.location.search
    });
  }

}


export default Home;

