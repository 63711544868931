import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";

import visitor_profile from '../views/VisitorProfile';

const PrivateRoute = ({ component: Component, render, ...rest }) => (
  render ? (
      <Route
      {...rest}
      render={props=>
        visitor_profile.getIsAuthenticated() 
          && !visitor_profile.getMaintenanceMode() ? (
          render(props) 
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
      />  
  ) : (  
      <Route
      {...rest}
      render={props =>
        visitor_profile.getIsAuthenticated() 
          && !visitor_profile.getMaintenanceMode() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
      />  
  )
);

export default PrivateRoute;