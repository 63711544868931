import React, {Component} from 'react';
import classNames from 'classnames';
import './css/ContentContainer.css';

class ContentContainer extends Component {

  render() {
    const cssClasses = classNames('content__container', this.props.cssClass);

    return (
      <div className={cssClasses} >
          {this.props.children}
      </div>
    );
  }
}

export default ContentContainer; 

