import React, { Component } from 'react';
import visitor_profile from '../VisitorProfile';
import PropTypes from 'prop-types';
import analyticsTracker from '../../analytics/AnalyticsTracker';
import { Jumbotron, Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import 'react-intl-tel-input/dist/main.css';
import "./EmailAuth.css";
import "./ChallengeAuth.css";

import AuthLogic from './AuthLogic';

class ChallengeAuth extends AuthLogic {

  constructor(props) {
    super(props);
    this.state = {
      response : "",   
      acceptedTerms : false,
      challengStr: "",
      notice : "",
      mobileChallengeStr:"",
      challengeQuestion: "",
      challengeId: null,
      failedChallenge: false,
      maxTriesReached: false

    };

    this.propTypes = {
      history: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired
    }

    this.verifyResponse = this.verifyResponse.bind(this);
    this.handleChallengeResponse = this.handleChallengeResponse.bind(this);

  }

  componentDidMount() {
    visitor_profile.recordPath(this.props.path_id);
    //fetch the initial challenge and set the  challenge img state? assign values 
    this.getChallenge();

  }

  verifyResponse(e){
    e.preventDefault();
    const { history } = this.props;

    visitor_profile.preauthedChallengeVisitor(this.state.challengeId, this.state.response.toLowerCase())
    .then(validation_data => {

        if(validation_data?.maxAttemptsReached){
            this.setState({
                maxTriesReached: true
            })

            analyticsTracker.trackEvent({
                category: 'User',
                action: 'Auth Failed',
                method: 'Challenge Auth',
                attempt_count:3,
                challenge_id: this.state.challengeId
              });

        }

        if(!validation_data?.correctResponse && !this.state.maxTriesReached){
            this.setState({
                failedChallenge:true,
                notice: "Incorrect. Please try again."
            })

            analyticsTracker.trackEvent({
                category: 'User',
                action: 'Auth Failed',
                method: 'Challenge Auth',
                attempt_count:validation_data.attemptCount?validation_data.attemptCount:"" ,
                challenge_id: this.state.challengeId
              });


        }

        if(validation_data?.correctResponse){
              history.replace({ pathname:'/storyinput' })
              analyticsTracker.trackEvent({
                category: 'User',
                action: 'Auths with code',
                method: 'Challenge Auth'
              });
              analyticsTracker.trackEvent({
                category: 'User',
                action: 'Successful Auth',
                method: 'Challenge Auth'
              });

        }

    });

  }


  getChallenge(){
    fetch(`visitor/challenge`,
    {
      method: 'GET',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      }
    })
    .then(res => res.json())
    .then(data=>{
        this.setState({
            challengStr: data.challenge,
            mobileChallengeStr: data.mobile_challenge,
            challengeQuestion: data.challenge_question,
            challengeId: data.id
        });

  })
}


  handleChallengeResponse(e){
    this.setState({
        response: e.target.value
    })
  }



  render () {
    return (
      <div className="page__container">
      <Jumbotron className="big__background_image">
          <Form className="authentication__container">
            <FormGroup>
              <Container>
              <Row className="challenge_auth_title">
                <Col xs={{size:10, offset:1}} md={{size:11, offset:1}} lg={{size:8, offset:1}}>
                {window.location.hostname.includes('-demo') ? <h1>This is a Demo</h1> : ''}
                <h1>{!(this.props.location.search === "?expired=true") && !this.state.maxTriesReached ? "We protect your identity" : this.state.maxTriesReached ? "Limit Reached" : this.props.location.search === "?expired=true" ? "Your session has expired due to inactivity" : "Please enter your verification code" }</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:8, offset:1}}>
                  <p className="challenge_authentication_info">{!this.state.maxTriesReached ? "Please solve the challenge below to enter."  :(
            <span>
                You've made too many incorrect attempts.
                <a href="/"target="_self"> Please start again.</a>
            </span>
          )}</p>
                  {!this.state.maxTriesReached && 
                  <>
                     <div className='desktop-svg' dangerouslySetInnerHTML={{ __html: this.state.challengStr }} />
                  <div className='mobile-svg' dangerouslySetInnerHTML={{ __html: this.state.mobileChallengeStr }}/>
                  <div><p></p></div>
                  <Input autoFocus type="challengetext" name="response" invalid={this.state.failedChallenge!==false} onChange={this.handleChallengeResponse} value={this.state.response} placeholder={this.state.challengeQuestion} />
                  <div className="authentication__error"> 
                      {this.state.notice}
                      <img src="/icons/warning.svg" hidden={!this.state.notice} alt="invalid verification code" align="right" />
                  </div>
                  
                  
                <Row>
                    <Col>
                        <div className="terms__link">
                        <Input type="checkbox" id="terms" onChange={this.termsAccepted} checked={this.state.acceptedTerms} /><label htmlFor="terms">I agree to the <a href="/#/legal/terms_of_use" target="_blank">Terms</a> and <a href="/#/legal/privacy_policy" target="_blank">Privacy Policy</a></label>
                    </div>
                    <Button type="submit" className="button__primary" onClick={this.verifyResponse} disabled={!this.state.acceptedTerms || this.state.response === ""}>CONTINUE</Button>
                    </Col>
                </Row>
                </>
  }
                  
                </Col>
              </Row>
              </Container>
            </FormGroup>
          </Form>
      </Jumbotron>
      </div>
    );
  }
}

export default ChallengeAuth;