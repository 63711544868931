import React, { Component } from 'react';

import visitor_profile from '../VisitorProfile';

import { Jumbotron, Container } from 'reactstrap';
import analyticsTracker from '../../analytics/AnalyticsTracker';

class PreAuth extends Component {

  componentWillMount() {
    const { history } = this.props;
    const { authkey,  dest } = this.props.match.params;

    if (!visitor_profile.getIsAuthenticated()) {
      visitor_profile.preauthenticatedVisitor(authkey, dest).then(() => {
        history.push({
          pathname: '/'+decodeURIComponent(dest)
        });
      })
      .catch(err => {
        analyticsTracker.trackEvent({
          action: "Preauth failed",
          category: 'User',
          destination: dest,
          err
        });
        history.push({ pathname: '/' });
      });
    }
  }

  render () {
    return (
      <div className="page__container">
      <Jumbotron className="big__background_image">
          <Container className="authentication__container">
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </Container>
      </Jumbotron>
      </div>
    );
  }
}

export default PreAuth;