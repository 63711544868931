import React, { Component } from 'react';
import PropTypes from 'prop-types';
import visitor_profile from '../VisitorProfile';
import analyticsTracker from '../../analytics/AnalyticsTracker';
import validator from 'validator';
import { Jumbotron, Container, Row, Col, Form, FormGroup, Input, 
  Button } from 'reactstrap';
import "./EmailAuth.css";
import webAuth, { AUTH0_CLIENT_ID, REDIRECT_URL, AUTH0_SCOPE, 
  AUTH0_RESPONSETYPE, AUTH0_AUDIENCE } from './tenants';
import AuthLogic from './AuthLogic';

class EmailAuth extends AuthLogic {

  constructor(props) {
    super(props);

    this.state = {
      emailAddress : "", 
      domains : [], 
      notice : "", 
      validEmail : false, 
      matchesDomain : true, 
      preEmail : true, 
      acceptedTerms : false,
      verificationCode: '',
      invalidCode: false
    };
  
    this.propTypes = {
      history: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired
    }

    this.login = this.login.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    this.verifyCode = this.verifyCode.bind(this);
    this.reset = this.reset.bind(this);
    this.goToAlternateAuth = this.goToAlternateAuth.bind(this);
  }

  componentDidMount() {  
    if (visitor_profile.getCompanyName() !== "")
      this.setState({ domains: visitor_profile.getEmailDomains() });
    else {
      // populate the company id and name
      visitor_profile.fetchCompanyInfoPublic()
        .then((data) => {
          this.setState({ domains : visitor_profile.getEmailDomains() }); 
        });
    }
    visitor_profile.recordPath(this.props.path_id);
  }

  login(e) { 
    e.preventDefault();

    if (this.state.emailAddress !== "")
    {      
      
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Sends one-time auth code',
        method: 'email'
      });

      webAuth.passwordlessStart({
          connection: 'email',
          send: 'code',
          email: this.state.emailAddress
        }, (err,res) => {
          if (err) {
            this.setState({
              notice: REDIRECT_URL + " Authentication failed: " + JSON.stringify(res),
              phoneNumber: ''
            })
          } else {
            visitor_profile.rememberVisitor("email|" + res.Id, 
              this.getQueryVariable('redirect_to'));
            this.setState({ 
              preEmail : false, 
              invalidCode: false
            });
          }
        }
      );
    }
  }

  handleEmailChange(e) {
    this.setState({emailAddress: e.target.value});

    let matches = false;
    for (let i=0; i<this.state.domains.length; i++)
    {
      // Don't mark it as invalid until they at least type an @ sign
      if (!e.target.value.includes('@'))
        matches = true;
      // otherwise don't mark it as valid until it matches a company domain
      else {
        // get the domain, the part after @
        let domain = e.target.value.toLowerCase().slice(e.target.value.search('@') + 1, e.target.value.length);
        let hasDot = e.target.value.indexOf('.') >= 0 ? true : false;
        // normalize company domain to all lowercase for the sake of comparison
        let compDomain = this.state.domains[i].toLowerCase();
        // It starts with the domain they seem to be typing
        let index = compDomain.search(domain);
        if (!hasDot && index === 0)
          matches = true; 
        else if (hasDot && compDomain === domain)
          matches = true;
      }
    }

    this.setState({ matchesDomain : matches });
    this.setState({ validEmail : validator.isEmail(e.target.value)});
    //this.setState({ acceptedTerms : document.getElementById('terms').checked });

    if (!matches)
    {
      this.setState({ notice : "Email address does not match your employer's. Please enter your work email address."});
    }
    else 
    {
      this.setState({ notice : ""});
    }
  }

  verifyCode(e) {
    e.preventDefault();
    const { history } = this.props;
    const self = this;

    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Auths with code',
      method: 'email'
    });
    webAuth.passwordlessLogin({
      connection: 'email',
      email: this.state.emailAddress,
      verificationCode: this.state.verificationCode
    }, function (err,authResult) {
      // NOTE: This code never runs as far as I can tell
      // See EmailVerify.js for the real handler
        if (!err) {
          
          visitor_profile.setIsAuthenticated(authResult.accessToken);
          visitor_profile.setJwt(authResult.accessToken);

          visitor_profile.linkVisitor(authResult.idTokenPayload.email)
            .then( success => {
              // If we are able to match the hash we will send them to 
              // the first step of data entry. This is a replace instead 
              // of a push so that a visitor can never go "back" to auth verify
              analyticsTracker.trackEvent({
                category: 'User',
                action: 'Successful Auth',
                success
              });
              history.replace({ pathname: success ? success : '/storyinput'});
            }, error => {
              // If we are not able to match the hash we need to push 
              // the user back to the home page because we won't have 
              // properly recorded consent 
              analyticsTracker.trackEvent({
                category: 'User',
                action: 'Auth Failed',
                error
              });
              history.push({ pathname: '/'});
            });
        } else {
          analyticsTracker.trackEvent({
            category: 'User',
            action: 'Auth Error - Invalid Code',
            err
          });
          self.setState({verificationCode: '', invalidCode: true});
        }
      }
    );
  }

  reset(e) {
    e.preventDefault();
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Clicked to resend code',
    });
    this.setState({
      preEmail: true,
      verificationCode: '',
      notice: ''
    });
  }

  render () {
    return (
      <div className="page__container">
      <Jumbotron className="big__background_image">
          <Form className="authentication__container">
            <FormGroup>
              <Container>
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:11, offset:1}} lg={{size:8, offset:1}}>
                {window.location.hostname.includes('-demo') ? <h1>This is a Demo</h1> : ''}
                <h1>{this.state.invalidCode ? "Oops! Your verification code didn’t work" : this.state.preEmail && !(this.props.location.search === "?expired=true") ? "We protect your identity" : this.props.location.search === "?expired=true" ? "Your session has expired due to inactivity" : "Please enter your verification code" } </h1>
                {this.state.preEmail && 
                  <ul className="authentication__info">
                    {this.props.location.search === "?expired=true" && <li>Please generate a new verification code to access the platform</li>}
                    <li>We ask for your work email to ensure you are a current {visitor_profile.getCompanyName()} employee</li>
                    <li>We send you a verification code to access the app</li>
                    <li>We don't keep your email address or create a user account for you</li>
                  </ul>
                }
                </Col>
              </Row>
              {!this.state.preEmail && !this.state.invalidCode && 
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:8, offset:1}}>
                  <p className="authentication__info">Use the code sent to your {visitor_profile.getCompanyName()} email.</p>
                  <Input type="text" name="code" className="form-control" onChange={this.handleCodeChange} value={this.state.verificationCode} placeholder="Enter verification code" />
                  <div className="authentication__error"> 
                      {this.state.notice}
                      <img src="/icons/warning.svg" hidden={!this.state.notice} alt="invalid verification code" align="right" />
                  </div>
                  <div className="terms__link">
                    <Input type="checkbox" id="terms" onChange={this.termsAccepted} checked={this.state.acceptedTerms} /><label htmlFor="terms">I agree to the <a href="/#/legal/terms_of_use" target="_blank">Terms</a> and <a href="/#/legal/privacy_policy" target="_blank">Privacy Policy</a></label>
                  </div>
                  <Button type="submit" className="button__primary" onClick={this.verifyCode} disabled={this.state.verificationCode.length < 6 || !this.state.acceptedTerms}>CONTINUE</Button>
                  <p className="authentication__info"><a href="/#/auth" onClick={this.reset}>Click here</a> if you need a new code.</p>
                </Col>
              </Row>
              }
              {this.state.preEmail && 
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:8, offset:1}}>
                  <Input type="email" name="email" data-1p-ignore valid={this.state.matchesDomain!==false} invalid={this.state.matchesDomain!==true} onChange={this.handleEmailChange} value={this.state.emailAddress} placeholder="Enter work email address" />
                  <div className="authentication__error"> 
                      {this.state.notice}
                      <img src="/icons/warning.svg"  hidden={this.state.matchesDomain !== false} alt="invalid email address" align="right" />
                  </div>
                  <Button type="submit" className="button__primary" onClick={this.login}  disabled={!this.state.validEmail || !this.state.matchesDomain}>SEND MY VERIFICATION CODE</Button>
                  { (visitor_profile.getActive() === false) && 
                      <Button id="passwordBtn" className="button__tertiary" onClick={this.usePassword}>...</Button>
                  }
                  <Row><Button type="submit" className="button__link d-none d-lg-block" onClick={this.goToAlternateAuth}>I prefer to verify via SMS. Text me the code instead.</Button>
                  <Button type="submit" className="button__link d-block d-lg-none" onClick={this.goToAlternateAuth}>I prefer to verify via SMS. <br/> Text me the code instead.</Button>
                  </Row>

                  <p className="authentication__confidence">
                    Note: We don’t retain your email address or any other personal information.
                  </p>
                </Col>
              </Row>
              }
              {this.state.invalidCode && 
              <Row>
                <Col xs={{size:10, offset:1}} md={{size:10, offset:1}} lg={{size:8, offset:1}}>
                  <p className="authentication__info">The verification code you entered is either incorrect, expired or has already been used once.</p>
                  <p className="authentication__info">Please enter your {visitor_profile.getCompanyName()} email to receive a new code.</p>
                  <Input type="email" name="email" valid={this.state.matchesDomain!==false} invalid={this.state.matchesDomain!==true} onChange={this.handleEmailChange} value={this.state.emailAddress} placeholder="Enter work email address" />
                  <div className="authentication__error"> 
                      {this.state.notice}
                      <img src="/icons/warning.svg"  hidden={this.state.matchesDomain !== false} alt="invalid email address" align="right" />
                  </div>
                  <Button type="submit" className="button__primary" onClick={this.login} disabled={!this.state.validEmail || !this.state.matchesDomain}>SEND MY NEW VERIFICATION CODE</Button>
                  <Row><Button type="submit" className="button__link d-none d-lg-block" onClick={this.goToAlternateAuth}>I prefer to verify via SMS. Text me the code instead.</Button>
                  <Button type="submit" className="button__link d-block d-lg-none" onClick={this.goToAlternateAuth}>I prefer to verify via SMS. <br/> Text me the code instead.</Button>
                  </Row>
                  <p className="authentication__confidence">
                    Note: We don’t retain your email address or any other personal information.
                  </p>
                </Col>
              </Row>
              }
              </Container>
            </FormGroup>
          </Form>
      </Jumbotron>
      </div>
    );
  }
}

export default EmailAuth;