import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Form, FormGroup } from 'reactstrap';
import "./MaintenanceMode.css";

class MaintenanceMode extends Component {

  componentDidMount() {
    document.getElementsByClassName("Footer")[0].innerHTML = '';
  }

  render() {
    return (
      <div className="maintenance__outer_container">
      <Jumbotron className="maintenance__background">
          <Form className="maintenance__container">
            <FormGroup>
              <Container>
              <Row>
                <Col xs={{size:12}} md={{size:10, offset:1}} lg={{size:7, offset:2}} style={{ textAlign: 'center' }}>
                  <div className="maintenance__text">Temporarily down for<br/><b>maintenance</b></div>
                  <div className="maintenance__info">We will be back online soon</div>
                </Col>
              </Row>
              </Container>
            </FormGroup>
          </Form>
      </Jumbotron>
      </div>
    );
  }
}


export default MaintenanceMode;

