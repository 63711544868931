/**
 * MultiSelectControl.js
 * Form control for showing and selecting multiple options
 * @param {string} cssClass - Additional css class names. Optional. 
 * @param {string} title - What to ask the user. This also functions as the form control label.
 * @param {array} options - An array of objects {label: string, value: number}
 * @param {string} id - Value for the `id` attribute. Required.
 * @param {string} name - Value for the `name` attribute. Optional. Will default to controlId
 * @param {func} optionsChangedAction - Callback for when options change 
 */ 

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col,Row } from 'reactstrap';
import classnames from 'classnames';

import "./css/FilterControl.css";

class MultiSelectControl extends Component {

  constructor(props) {
    super(props);

    this.controlName = this.props.name || this.props.id;
    this.makeOptions = this.makeOptions.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.getSelectedInputValues = this.getSelectedInputValues.bind(this);
    this.setAllCheck = this.setAllCheck.bind(this);
    this.isSelected = this.isSelected.bind(this);

  }

  changeHandler(domEvent) {    
    domEvent.persist();

    let selectedValues = [];

    selectedValues = this.getSelectedInputValues();

    this.props.optionsChangedAction(domEvent, selectedValues);
  }

  makeOptions(options) {
    const inputs = options.map( (option, index) => {
      const id = `${this.controlName}__${option.value}`;
      return (
        <p key={index.toString()}>
          <input type="checkbox" name={this.controlName} id={id} value={option.value} />
          <label htmlFor={id}>{option.label}</label>
        </p>
      )
    });
    return inputs;
  }

  getSelectedInputValues()
  {
    let selectedItems = [];
    for (var i=0; i<this.props.options.length; i++)
    {
      const id = `${this.controlName}__${this.props.options[i].value}`;
      let element = document.getElementById(id);
      if (element.checked && this.props.options[i].value !== "")
        selectedItems.push(this.props.options[i].value);
    }
    return selectedItems; 
  }

  isSelected(value) {
    if (value === '' && this.props.selectedOptions === '?topics=')
      return true;
    else if (value !== '' && this.props.selectedOptions.search(value) >= 0)
      return true; 
    else
      return false;
  }

  setAllCheck(checked)
  {
    const id = `${this.controlName}__`;
    let element = document.getElementById(id);
    if (element)
      element.checked = checked;
  }

  render() {
    const inp = this.makeOptions(this.props.options);
    const cssClasses = classnames('filter__controls', this.props.cssClass);

    return (
      <fieldset className={cssClasses} onChange={this.changeHandler}>
        <Row>
        <Col xs="12" sm={{size:10, offset:1}}>
          <legend>{this.props.title}</legend>
          <div className="filter__controls_inputs">
            {inp}
          </div>
        </Col>
        </Row>
      </fieldset>
    );
  }

}

MultiSelectControl.defaultProps = {
  title: '',
  options: [],
  selectedOptions: '',
  id: '',
  optionsChangedAction: null
};

  

MultiSelectControl.propTypes = {
  cssClass: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  selectedOptions: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  optionsChangedAction: PropTypes.func
};

export default MultiSelectControl;
