import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import '../Home.css';

class HomeScreenCard extends Component {

  render() {
    return (
      <Row className="homescreen__card_container" noGutters>
        <Col xs={{size: 2, offset: 1}} >
          <div className="homescreen__card__icon">
            <img src={this.props.iconPath} alt={this.props.title} />
          </div>
        </Col>
        <Col xs={{size: 7, offset: 1}} >
          <h2>{this.props.title}</h2>
          <p>{this.props.subtitle}</p>
        </Col>
      </Row>
    );
  }

}

HomeScreenCard.defaultProps = {
  title: '',
  subtitle: '',
  icon: '',
};

HomeScreenCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
};

export default HomeScreenCard;

/* 
      <div className="homescreen__card_container">
        <div className="homescreen__card__icon" aria-hidden="true">
          <img src={this.props.iconPath} className="outcome-icon" alt={this.props.title} />
        </div>
        <div className="homescreen__card_textcontainer">
          <h2>{this.props.title}</h2>
          <p>{this.props.subtitle}</p>
        </div>
      </div>
*/