import React, { Component } from 'react';
import visitor_profile from '../VisitorProfile';
import { Jumbotron, Container } from 'reactstrap';
import analyticsTracker from '../../analytics/AnalyticsTracker';

import webAuth from './tenants';
import posthog from 'posthog-js';

class SmsAndEmailAuthVerify extends Component {

  constructor(props) {
    super(props);

    const { history } = this.props;

    if (this.props.location.state && this.props.location.state.hash) {
      let hash = this.props.location.state.hash;
      let state = hash.substring(hash.search('&state=')+7, hash.search('&id_token='));

      webAuth.parseHash({ state: state, hash: hash }, (error, authResult) => {
        if (error) {
          console.log(error);
          // TODO: Should we log this in the backend log to see how often this happens? 
          analyticsTracker.trackEvent({
            category: 'User',
            action: 'Auth Error - Missing State',
            error
          });
          // Navigate to the error page so we can tell them what happened and 
          // have them try again 
          history.push({
            pathname: '/error=missing_state'
          });

          return;
        }

        if (!this.isJwtToken(authResult.accessToken)) {
          // this should only happen if we've switched from the 
          // old auth (no JWTs, just opaque string) to the new auth (JWTs)
          console.log('Error: Not a valid JWT token');

          analyticsTracker.trackEvent({
            category: 'User',
            action: 'Auth Error - Invalid JWT token'
          });
          // Navigate to the error page so we can tell them what happened and 
          // have them try again 
          history.push({
            pathname: '/error=unauthorized'
          });

          return;
        }


        visitor_profile.setIsAuthenticated(authResult.accessToken);
        visitor_profile.setJwt(authResult.accessToken);


        visitor_profile.linkVisitor(authResult.idTokenPayload.sub)
          .then( success => {
            // If we are able to match the hash we will send them to 
            // the first step of data entry. This is a replace instead 
            // of a push so that a visitor can never go "back" to auth verify
            analyticsTracker.trackEvent({
              category: 'User',
              action: 'Successful Auth',
              success
            });

            if (authResult.idTokenPayload.email && authResult.idTokenPayload.email.includes('tequitable.com')) {
              posthog.identify(visitor_profile.getId() +'', { email: authResult.idTokenPayload.email });
            }
            history.replace({ pathname: success ? success : '/storyinput'});
          }, error => {
            // If we are not able to match the hash we need to push 
            // the user back to the home page because we won't have 
            // properly recorded consent 
            analyticsTracker.trackEvent({
              category: 'User',
              action: 'Auth Failed',
              error
            });
            history.push({ pathname: '/'});
          });
      });
    } else {
      // Navigate to the error page so we can tell them what happened and 
      // have them try again
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Auth Error - Missing State'
      });

      history.push({
        pathname: '/error=missing_state'
      });
    }

  }

  isJwtToken(jwtToken) {
    if (!jwtToken) return false
    if (typeof jwtToken !== 'string') return false

    const jwtTokenParts = jwtToken.split('.')
    if (jwtTokenParts.length !== 3) return false

    try {
      JSON.parse(window.atob(jwtTokenParts[0]))
      JSON.parse(window.atob(jwtTokenParts[1]))
      return true
    } catch (err) {
      return false
    }
  }

  render () {
    return (
      <div className="page__container">
      <Jumbotron className="big__background_image">
          <Container className="authentication__container">
            <h1>&nbsp;</h1>
            <h1>&nbsp;</h1>
          </Container>
      </Jumbotron>
      </div>
    );
  }
}

export default SmsAndEmailAuthVerify;