import React, { Component } from 'react';
import { Jumbotron, Row, Col, Button } from 'reactstrap';
import classNames from 'classnames';
import analyticsTracker from '../analytics/AnalyticsTracker';

import HomeScreenCardList from './components/HomeScreenCardList';
import HomeScreenAnimation from './components/HomeScreenAnimation';
import FAQInteractiveHP from './FAQInteractiveHP';

import './Home.css';
import './components/NewHomepage/NewHomepage.scss'
import visitor_profile from './VisitorProfile';

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.goToAuth = this.goToAuth.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.state = { messagePart1: '', messagePart2: '', enableButton: true, visitorCount: 0, defaultNum:'34,527', showCount:false };
    
    visitor_profile.ifFeatureEnabled('PRD-465-variant-1').then(this.setMessage);

  }

  componentDidMount(){
    this.fetchHomepageFeaturedContent();

  }

  fetchHomepageFeaturedContent(){
    fetch(`/homepageContent/newHpContent`,
    {
      method: 'GET',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      }
    })
    .then(res => {
      if (!res.ok){
        throw Error(res.statusText);
      } else{
        return res;
      }
    })
    .then(res => res.json())
    .then(data=>{
      this.setState({ visitorCount: data.visitorCount });

    })
}

  setMessage(altMessage) {
    if (altMessage) {
      this.setState({ messagePart1: 'A safe place to get advice when you are', messagePart2: 'facing a challenging situation at work' });
    } else {
      this.setState({ messagePart1: 'A safe place to get advice when', messagePart2: 'you’re feeling uncomfortable' });
    }
  }

  getStartedClick(location) {
    analyticsTracker.trackEvent({
      category: "User",
      action: "Clicked Get Started Button",
      buttonLocation: location,
    });
  }

  goToAuth(e, location) {
    e.preventDefault();

    const { history } = this.props;

    this.getStartedClick(location);

    if (this.props.mobile_auth) {
      history.push({
        pathname: "/auth_phone",
      });
    } else if(this.props.challenge_auth){
      history.push({
        pathname: "/auth_challenge",
      });
    }else {
      history.push({
        pathname: "/auth",
      });
    }
  }

  render() {
    const cssClasses = classNames(
      "homescreen__background",
      this.props.cssClass
    );

    return (
      <Jumbotron className={cssClasses} fluid>
        <Row className="homescreen__content_stretch" noGutters>
          <Col xs="12">
            <HomeScreenAnimation />
          </Col>
          <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <Row className="homescreen__tagline_container" noGutters>
              <Col xs={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
                
                <h1 className="homescreen__tagline">
                  {this.state.messagePart1}
                  <br className="d-none d-sm-block" />
                  {this.state.messagePart2}
                </h1>
              </Col>
              <Col
                xs={{ size: 10, offset: 1 }}
                sm={{ size: 8, offset: 2 }}
                md={{ size: 6, offset: 3 }}
              >
                <Button
                  id="getstarted"
                  className="button__primary homescreen__getstarted"
                  onClick={(e) => this.goToAuth(e, "hero")}
                  disabled={!this.state.enableButton}
                >
                  Get Started
                </Button>
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="12">
                <div className="homescreen__ioa_tenets">
                  <span className="homescreen__ioa_tenets_teq">
                    tEQuitable is:
                  </span>
                  <ul>
                    <li>Independent</li>
                    <li>Confidential</li>
                    <li>Impartial</li>
                    <li>Off-the-Record</li>
                  </ul>
                </div>
              </Col>

              <Col xs={{size: 12 }}>
                <div className='counter_container'>
                <div className="homepage__visitor_count_banner visible">
                  <p className='counter_content_container'><p className={"homepage__visitor_count_banner_text"}>Number of visitors helped</p><p className="homepage__visitor_count_banner_number">{this.state.visitorCount > 0 ? this.state.visitorCount.toLocaleString():this.state.defaultNum}</p></p>
                  </div>
                </div>
              </Col>
            </Row>
            
          </Col>
          <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <HomeScreenCardList />
          </Col>
          <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <Row
              className="homescreen__principles_container homescreen__faq"
              noGutters
            >
              <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                <h2>Getting Started With tEQuitable</h2>
                <p>
                  Learn how we can help and what you can expect from the process
                </p>
                <FAQInteractiveHP />
                <Button
                  className="button__primary homescreen__getstarted"
                  onClick={(e) => this.goToAuth(e, "faq")}
                  disabled={!this.state.enableButton}
                >
                  Get Started
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <Row className="homescreen__principles_container" noGutters>
              <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                <h2>Your Privacy is our Priority</h2>
                <ul>
                  <li>
                    Whatever you share is confidential and can't be tracked back
                    to you.
                  </li>
                  <li>
                    This is not a formal report. It doesn’t go to management.
                  </li>
                  <li>
                    Your workplace will only receive recommendations for
                    improvement, never any personal information.
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Jumbotron>
    );
  }
}


export default HomeScreen;
