/**
TransparentSelectMenu.js
A reusable select menu component.
*/

import React from 'react';
import Select from 'react-select';

class TransparentSelectMenu extends React.Component {

  render() {

    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? '#4b6068' : '',
        boxShadow: state.isFocused ? '0 0 0 1px #009688' : '',
        border: 0,
        borderBottom: '2px solid #aaa',
        background: 'rgba(0,0,0,0.035)',
        borderRadius: 2,
        minHeight: 40,
        color: '#4b6068',
        textAlign: 'justify',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '20px',
        ':hover': {
          borderColor: state.isFocused ? '#009688' : '#4b6068',
        },
      }),
      menu: (base, state) => ({
        ...base,
        borderRadius: 2,
        marginTop: '-0.25rem',
        background: '#FAFAFA',
        marginBottom: '2rem',
        zIndex: '3',
        boxShadow: state.isFocused ? '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)' : '0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 0px rgba(0, 0, 0, 0.12)',
      }),
      menuList: (base, state) => ({
        ...base,
        maxHeight: '15rem',
      }),
      multiValue: (base) => ({
        ...base,
        textAlign: 'center',
        fontSize: '16px',
        border: '1px solid #4b6068',
        marginBottom: '1rem',
        color: '#4b6068',
      }),
      multiValueRemove: (base) => ({
        ...base,
        ':hover': {
          backgroundColor: '#CACACA'
        },
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? '#EEEEEE' : '',
        borderColor: 'red',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '20px',
        fontSize: '15px',
        color: '#4b6068',
        outline: state.isFocused ? '3px solid #2a8b92' : ''
      }),
      placeholder: (base) => ({
        ...base,
        color: '#4b6068',
        opacity: 0.5
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: '#aaa',
      }),
      singleValue: (base) => ({
        ...base,
        color: '#4b6068',
        width: '100%',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '20px'
      }),
      valueContainer: (base) => ({
        ...base,
        marginBottom: '-13px',
      }),
    }

    return (
      <div hidden={this.props.hidden} onClick={this.props.onClick} id={`storyinput_${this.props.id}`}>
        <Select
          id={this.props.id}
          className={this.props.className}
          name={this.props.name}
          aria-label={this.props.ariaLabel}
          placeholder={this.props.placeholder}
          isClearable={false}
          isSearchable={true}
          styles={customStyles}
          onChange={this.props.changeHandler}
          options={this.props.options}
          value={this.props.value}
          isMulti={this.props.isMulti}
        />
      </div>
    );
  }
}

export default TransparentSelectMenu;
