import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import {Animated} from "react-animated-css";
import classNames from 'classnames';

import '../Home.css';

class HomeScreenAnimation extends Component {

  state = { indexIn : 0, 
            indexOut: 0,
            ready: false, 
            phrases : [
              {phrase: "He told me I’d be prettier if I smiled more", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "Am I a part of the problem?", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "We don’t talk enough about allyship in my office", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I’m Asian-American but people always ask me where I’m from", style:"homescreen__phrase_4", isVisible: false},
              {phrase: "I’m never invited to the meeting after the meeting", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "My co-worker is gossipy and it affects team dynamic", style:"homescreen__phrase_5", isVisible: false},
              {phrase: "All the work parties assume hetero partners", style:"homescreen__phrase_4", isVisible: false},
              {phrase: "Our engagement survey didn’t allow me to identify as non-binary", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "Maternity leave set my career back", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "I was told that I should be “softer” in my responses", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I’m always mistaken for another person of my race", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "You’re being too aggressive", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "They always assume I’m the secretary", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "There is a cabal that I’m not a part of, and they get all the good work", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "My co-workers refuse to learn how to pronounce my name", style:"homescreen__phrase_4", isVisible: false},
              {phrase: "At work I’m always “other”", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "My co-worker asked me out", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "She assumed my parents are uneducated because I’m an immigrant", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I don’t feel comfortable talking about my religion/spirituality at work", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "I fell into the trap of always doing office housework", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "He always interrupts me", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "It was “obvious” because we were black that we weren’t engineers", style:"homescreen__phrase_5", isVisible: false},
            ]
          };

  constructor(props) {
    super(props);
    this.makeAnimatedPhrases = this.makeAnimatedPhrases.bind(this);
    this.animateNextIn = this.animateNextIn.bind(this);
    this.animateNextOut = this.animateNextOut.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth >= 576)
      setTimeout(this.animateNextIn, 1000);
  }

  animateNextIn() {
    if (!this.state.ready)
      this.setState({ready:true});

    if (this.state.indexIn < this.state.phrases.length) {
      let nextPhrase = this.state.phrases[this.state.indexIn]; 
      nextPhrase.isVisible = true;
    }
  
    this.setState({ indexIn : this.state.indexIn + 1 }); 
    
    if (this.state.indexOut < this.state.phrases.length) {
      setTimeout(this.animateNextOut, 2000);
    }
  }

  animateNextOut() {
    if (this.state.indexOut >= 0 && this.state.indexOut < this.state.phrases.length) {
      let lastPhrase = this.state.phrases[this.state.indexOut];
      lastPhrase.isVisible = false; 
    }

    this.setState({ indexOut : this.state.indexOut + 1 }); 
    
    if (this.state.indexIn < this.state.phrases.length) {
      setTimeout(this.animateNextIn, 250);
    }
  }



  makeAnimatedPhrases() {
    return this.state.phrases.map((item, index) => {
      let positionStyle = "homescreen__animation_pos_" + (index % 4).toString();

      const cssClasses = classNames('homescreen__phrase', positionStyle, item.style);

      return(
        <Animated key={index.toString()} animationIn="fadeIn" animationInDuration={1000} animationOut="fadeOut" animationOutDuration={750} isVisible={item.isVisible}>
          <span className={cssClasses}>{item.phrase}</span>
        </Animated>
      );

    });
  }

  render() {
    const cssClasses = classNames({
      'homescreen__animation_container': true,
      'homescreen__animation_container_initial': !this.state.ready
    });
    return (
      <Row noGutters>
        <Col xs="12">
          <div className={cssClasses}>
            {this.makeAnimatedPhrases()}
          </div>
        </Col>
      </Row>
    );
  }

}

export default HomeScreenAnimation;
