import solutions from '../solutions/SolutionsList';

const respondCategories = {
  outcomes_respond_equity: {
    path: "equity",
    path_id: "respond_equity",
    to: "#/respond/equity",
    solution_title: "Cultivate Equity",
    solution_text: "Build an intentional workplace",
    icon_src: "/icons/large/icon_lg_equity.svg",
    featureEnabled: true,
    solutions: [
      solutions['solution_build-equitable-hybrid-teams'],
      solutions.solution_better_ally,
      solutions.solution_show_compassion,
      solutions.solution_bystander,
      solutions.solution_offensive_comment,
      solutions.solution_find_allies,
      solutions.solution_inclusive_meeting,
      solutions.solution_distribute_work,
      solutions.solution_stories_search,
      solutions.solution_advice
    ]
  },
  outcomes_respond_communication: {
    path:"communication",
    path_id: "respond_communication",
    to: "#/respond/communication",
    solution_title: "Communicate Effectively",
    solution_text: "Improve interpersonal relationships",
    icon_src: "/icons/large/icon_lg_communication.svg",
    featureEnabled: true,
    solutions: [
      solutions.solution_toughconvos, 
      solutions.solution_empathy,
      solutions.solution_show_compassion,
      solutions.solution_offensive_comment,
      solutions.solution_letter,
      solutions.solution_stories_search,
      solutions.solution_advice
    ]
  },
  outcomes_respond_management: {
    path: "management",
    path_id: "respond_management",
    to: "#/respond/management",
    solution_title: "Lead Inclusively",
    solution_text: "Nurture collaborative teamwork",
    icon_src: "/icons/large/icon_lg_management.svg",
    featureEnabled: true,
    solutions: [
      solutions['solution_build-equitable-hybrid-teams'],
      solutions.solution_inclusive_meeting,
      solutions.solution_distribute_work,
      solutions.solution_toughconvos,
      solutions.solution_stories_search,
      solutions.solution_advice
    ]
  }
}

export default respondCategories;