import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron } from 'reactstrap';
import MenuNavBar from '../MenuNavBar';
import ContentContainer from './ContentContainer';
import ProductFeedback from '../components/ProductFeedback';
import visitor_profile from '../VisitorProfile';
import moment from 'moment';

import './css/AboutContainer.css';

class AboutContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openFeedback: false
    };

    this.toggleFeedback = this.toggleFeedback.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem('v')) {
      visitor_profile.recordPath(this.props.path_id);
    }
  }

  toggleFeedback(e) {
    if (e) 
      e.preventDefault();
    this.setState({openFeedback : !this.state.openFeedback });
  }

  render() {
    return (
      <div className="page__container">
        <MenuNavBar className="page__navbar" />
        <Jumbotron className="big__background_image" >
          <ContentContainer cssClass="about__container">
            <div>
              <Link className="moremenu__link" to="/legal/terms_of_use">Terms of Use</Link>
            </div>
            <div>
              <Link className="moremenu__link" to="/legal/privacy_policy">Privacy Policy</Link>
            </div>
            <div>
              <Link className="moremenu__link" to="/faq">FAQ</Link>
            </div>
            <div>
              <a className="moremenu__link" href="https://www.tequitable.com" target="_blank" rel="noreferrer noopener">tequitable.com</a>
            </div>
            <div>
            <a className="moremenu__link" href="#"  onClick={this.toggleFeedback}>Feedback?</a>
              <ProductFeedback open={this.state.openFeedback} onClose={this.toggleFeedback} />
            </div>
            <div className="attributions__text">
              <br />
              Copyright &copy; {moment().format('YYYY')} tEQuitable, Inc. 
              <br/>
              All rights reserved. 
              <br/>
              <br/>
              Icons from the Noun Project<br/>
              CC Images courtesy of #WOCinTech Chat <br/>
            </div> 
          </ContentContainer>
        </Jumbotron>
      </div>
    );
  }
}

export default AboutContainer; 

