import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import HeroListContainer from "../containers/HeroListContainer";
import ContentContainer from "../containers/ContentContainer";
import CheckIn from "../components/CheckIn";
import DoMoreButton from "../components/DoMoreButton";
import InteractiveConvoStarter from "./InteractiveConvoStarter";

import analyticsTracker from "../../analytics/AnalyticsTracker";
import visitor_profile from "../VisitorProfile";
import handleExpiredTokens from "../../utils/jwtErrorHandler";
class SolutionLearningModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subtitle: "",
      content: "<div></div>",
      conversation_starter: false,
      loaded: false,
    };
  }

  loadContent() {
    const lmSlug = this.props.match.params.slug;
    const currentPage = "solution_" + lmSlug;
    return fetch(this.props.endpoint + "/" + lmSlug, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + visitor_profile.getJwt(),
        "tEQ-preauthorized": visitor_profile.getPreauthorized(),
      },
    })
      .then((res) => {
        if (!res.ok) {
          handleExpiredTokens(res);
          throw Error(res.statusText);
        } else return res;
      })
      .then((res) => res.json())
      .then((data) => {
        data.loaded = true;
        this.setState(data);
        window.scrollTo({ top: 0, behavior: "smooth" });
        visitor_profile.recordPath(currentPage);
        analyticsTracker.trackPage("/solutions/" + lmSlug, visitor_profile.getCurrentPathId());
        analyticsTracker.trackEvent({
          action: "Reached Leaf Node",
          category: "User",
          pathId: currentPage,
        });
        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Viewed an Action Module',
          pathId: currentPage,
          slug: lmSlug
        });
      })
      .catch((err) => {
        this.setState({
          title: "Oops! We were unable to load this learning module",
          content: "<p>Please try again or explore other content</p>",
          loaded: false,
        });
        analyticsTracker.trackEvent({
          category: "User",
          action: "Learning module failed to load",
          pathId: this.props.path_id,
          lmSlug,
          err,
        });
      });
  }

  componentDidMount() {
    this.loadContent();
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname;
    const nextPage = this.props.location.pathname;

    if (currentPage !== nextPage) {
      this.trackPageLeave(prevProps.match.params.slug);
      this.loadContent();
    }
  }

  trackPageLeave(lmSlug) {
    const currentPage = "solution_" + lmSlug;
    sessionStorage.setItem("prevPath", currentPage);
    analyticsTracker.trackEvent({
      action: "$pageleave",
      $current_url:
        window.location.origin +
        this.props.location.pathname +
        this.props.location.search,
      pathName: this.props.location.pathname,
      pathId: currentPage,
      search: this.props.location.search,
    });
  }

  componentWillUnmount() {
    this.trackPageLeave(this.props.match.params.slug);
  }

  render() {
    return (
      <HeroListContainer
        {...this.props}
        contentCssClass="short__background_content learning__module_border"
        title={this.state.title}
        subtitle={this.state.subtitle}
        currentsection="outcomes"
      >
        <Row className="short__background_content_resources">
          <Col xs="12" sm={{ size: 10, offset: 1 }}>
            <ContentContainer
              cssClass="learning__module"
              xs="12"
              sm={{ size: 10, offset: 1 }}
            >
              <Row>
                <Col
                  xs="12"
                  sm={{ size: 10, offset: 1 }}
                  className="content_loading_filler"
                >
                  <div
                    className="learning__module_section"
                    dangerouslySetInnerHTML={{ __html: this.state.content }}
                  ></div>
                  {this.state.loaded && <hr />}
                  <br />
                </Col>
                {this.state.loaded && this.state.conversation_starter && (
                  <Col xs="12" sm={{ size: 10, offset: 1 }}>
                    <h2>
                      <span className="fa fa-pencil-square-o"></span> ACTIVITY
                    </h2>
                    <p>
                      What tough conversation do you need to have? Write out
                      your thoughts and feelings using our interactive
                      conversation starter below. Using this tool will help you
                      walk into your next tough conversation feeling prepared
                      and confident.
                    </p>
                    <br />
                    <InteractiveConvoStarter />
                  </Col>
                )}
                {this.state.loaded && (
                  <Col
                    xs="12"
                    sm={{ size: 10, offset: 1 }}
                    lg={{ size: 9, offset: 1 }}
                    xl={{ size: 7, offset: 1 }}
                  >
                    <CheckIn
                      questions={[
                        {
                          question: "Was this content helpful?",
                          topic: "relevance",
                        },
                        {
                          question:
                            "How confident are you in your next step to resolve the incident (including taking no action)?",
                          topic: "confidence",
                        },
                      ]}
                      moreLinkText=""
                    />
                  </Col>
                )}
              </Row>
            </ContentContainer>

            {this.state.loaded && (
              <DoMoreButton route='/solutions/learningModulesSearch?category=' {...this.props} />
            )}
          </Col>
        </Row>
      </HeroListContainer>
    );
  }
}

export default SolutionLearningModule;
